import { DialogContent, styled } from "@mui/material";

const AppDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "0rem 2.75rem 2.25rem",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  overflowY: "unset",
  paddingTop: "0.5rem !important",
}));

export default AppDialogContent;
