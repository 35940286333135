import {
  Box,
  ListItem,
  ListItemButton,
  ListItemButtonProps,
  Typography,
  useTheme,
} from "@mui/material";
import PointIcon from "assets/image-components/shared/point";
import { InfoCircle } from "iconsax-react";
import { ReactNode } from "react";

type propType = {
  selected?: boolean;
  hasInfo?: boolean;
  onClickInfo?: any;
  activeInfo?: boolean;
  title: string | ReactNode;
} & Omit<ListItemButtonProps, "title">;

const PointedListItem = ({
  id,
  selected,
  title,
  hasInfo,
  onClickInfo,
  activeInfo,
  ...props
}: propType) => {
  const theme = useTheme();
  return (
    <ListItem
      sx={{
        p: 0,
        cursor: "pointer",
        bgcolor: activeInfo
          ? `${theme.palette.primary[10]} !important`
          : "unset",
        "&:hover": {
          bgcolor: hasInfo
            ? `${theme.palette.primary[10]} !important`
            : "unset",
        },
      }}
    >
      <ListItemButton
        {...props}
        sx={{
          position: "relative",
          cursor: "pointer",
          "&:hover": hasInfo
            ? {
                bgcolor: "unset",
              }
            : {},
          ...props.sx,
        }}
      >
        {selected && (
          <Box position={"absolute"} top={"0.2rem"} left={"1.5rem"}>
            <PointIcon />
          </Box>
        )}
        <Typography
          variant="s13w500"
          ml={1}
          color={selected ? "primary" : "space-cadet"}
        >
          {title}
        </Typography>
      </ListItemButton>
      {hasInfo && (
        <Box
          sx={{
            p: "0.25rem",
            mr: 2,
            display: "flex",
            "&:hover": {
              bgcolor: "#e1e1e1 !important",
              borderRadius: 1,
            },
          }}
          onClick={(e) => onClickInfo(e, id)}
        >
          <InfoCircle
            color={
              activeInfo
                ? theme.palette.primary.main
                : theme.palette["cool-grey"]
            }
            size={12}
          />
        </Box>
      )}
    </ListItem>
  );
};

export default PointedListItem;
