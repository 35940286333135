import {
  createSlice,
} from "@reduxjs/toolkit";

const initialState = {
  loginHistory: [],
};

const workpace = createSlice({
  name: "workspace",
  initialState,
  reducers: {
    insertLoginHistory(state, action) {
      let workspaceID = action.payload.workspaceID;
      let loginHistory = state.loginHistory;
      let index = loginHistory.findIndex((e) => e.workspaceID === workspaceID);
      let found = index > -1 ? loginHistory[index] : { workspaceID };
      found.lastLogin = new Date().toString();
      loginHistory[index > -1 ? index : 0] = found;
      state.loginHistory = [...loginHistory];
    },
  },
});



export const { insertLoginHistory, } = workpace.actions;

export default workpace.reducer;
