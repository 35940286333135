// third-party
import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// project import
import reducers from "./reducers";
import logger from "redux-logger";
// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const isDevelopment = process.env.NODE_ENV === "development";

const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [
          "payload.config.adapter",
          "payload.config.transformRequest.0",
        ],
      },
    });
    return isDevelopment ? [...middleware, logger] : middleware;
  },
});

let persistor = persistStore(store);

const { dispatch } = store;

export { store, dispatch, persistor };
