import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getWorkspaceMembers,
  oldUserJoinToWorkspace,
  newUserJoinToWorkspace,
  getUserMembership,
  getCompanies,
  getContactList,
  createContact,
  updateContact,
  deleteContact,
  createCompany,
  updateCompany,
  deleteCompany,
  getUserWorkspaces,
  createRole,
  updateRole,
  getMembers,
  createMember,
  updateMember,
  getRoles,
  getPermissions,
  updateWorkspace,
  getWorkspace,
  getGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  getGroupMembers,
  deleteRole,
} from "./workspace.api";
import {
  CompanyCreate,
  ContactCreate,
  CreateMember,
  CreateRole,
  OldUserJoinWorkspaceData,
  HasPermission,
  JoinWorkspaceData,
  PermissionAccessLevel,
  UpdateMember,
  WorkspaceUpdate,
  WorkspaceLoginHistory,
  PermissionAccessLevelEnum,
  GroupCreate,
} from "./workspace.types";
import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import { IMutationCreate, IMutationUpdate, IMutationDelete } from "api/types";
import { UserInfoResponse, useQueryUserInfo } from "api/auth";
import { useSelector } from "react-redux";
import { isActiveConfirmedMemeberShip } from "./utils";

//#region workspace and permissions
export const useQueryUserMembership = (workspaceID: string | undefined) => {
  return useQuery(
    [workspaceID, "user-membership"],
    () => getUserMembership(workspaceID),
    {
      enabled: !!workspaceID,
      staleTime: Infinity,
    }
  );
};

export const useHasPermissions = (
  requiredPermissions: HasPermission[],
  workspaceID: string | undefined,
  checkHasTeamAccess = false
) => {
  const { data: membership, isLoading } = useQueryUserMembership(workspaceID);
  const hasPermission = useMemo(() => {
    if (membership) {
      if (!membership.is_active) {
        return false;
      }
      if (membership.is_owner || membership.is_admin) return true;
      if (checkHasTeamAccess && membership.is_team_member) return true;
      return requiredPermissions.every((item) =>
        membership.role_details?.permissions_details.some(
          (permission) =>
            permission.permission_details.resource_name ===
              item.resource_name &&
            (permission.access_level === PermissionAccessLevelEnum.ReadWrite ||
              permission.access_level === item.access_level)
        )
      );
    }
  }, [membership, requiredPermissions, checkHasTeamAccess]);
  return {
    hasPermission,
    isLoading,
  };
};

export type PermissionCheckParams = {
  requiredPermissions: {
    resource_name: string;
    access_level: PermissionAccessLevel;
  }[];
  workspaceID: string | undefined;
  teamAccess?: boolean;
};

export const useHasPermissionOrObjectOwner = (
  params: Omit<PermissionCheckParams, "teamAccess"> & {
    resourceOwner?: UserInfoResponse;
  }
) => {
  const { data: userData } = useQueryUserInfo();
  const { hasPermission, isLoading } = useHasPermissions(
    params.requiredPermissions,
    params.workspaceID,
    true
  );
  const isOwner = useMemo(
    () =>
      params?.resourceOwner
        ? userData?.email === params.resourceOwner.email
        : true,
    [userData, params.resourceOwner]
  );
  const canAccess = useMemo(
    () => hasPermission || isOwner,
    [isOwner, hasPermission]
  );

  return {
    hasPermission,
    isLoading,
    isOwner,
    canAccess,
  };
};

export const useHasWorkspaceActiveMembership = (
  workspaceID: string | undefined
): { isLoading: boolean; hasMembership: boolean | undefined } => {
  const { data: membership, isLoading } = useQueryUserMembership(workspaceID);

  let hasMembership = useMemo(() => {
    if (membership) {
      return isActiveConfirmedMemeberShip(membership);
    }
  }, [membership]);

  return {
    isLoading,
    hasMembership,
  };
};

export const useQueryMembers = (workspaceID: string | undefined) => {
  return useQuery(
    ["members", workspaceID],
    () => getWorkspaceMembers(workspaceID),
    {
      enabled: !!workspaceID,
    }
  );
};

export const useMutationJoinOldUserToWorkspace = (
  options: UseMutationOptions<typeof oldUserJoinToWorkspace> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: OldUserJoinWorkspaceData) =>
      oldUserJoinToWorkspace(data.workspace, data),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["members", variables.workspace],
      });
    },
  });
};

export const useMutationJoinNewUserToWorkspace = (
  options: UseMutationOptions<typeof newUserJoinToWorkspace> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Required<JoinWorkspaceData>) =>
      newUserJoinToWorkspace(data.workspace, data),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["members", variables.workspace],
      });
    },
  });
};

export const useQueryWorkspaces = (config?: AxiosRequestConfig) => {
  return useQuery(["workspaces", config], () => getUserWorkspaces(config));
};

export const useQueryWorkspace = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  return useQuery(["workspace", workspaceID, config], () =>
    getWorkspace(workspaceID, config)
  );
};

export const useLastLoggedInWorkspaceID = (): {
  workspaceID: string | null;
  isLoaing: boolean;
} => {
  const { data: workspaces, isLoading } = useQueryWorkspaces();
  let logins: WorkspaceLoginHistory[] = useSelector(
    (state: any) => state.workspace.loginHistory
  );
  if (isLoading) {
    return { workspaceID: null, isLoaing: true };
  }

  let actives = workspaces
    ?.filter((e) => isActiveConfirmedMemeberShip(e.membership_details))
    .sort(
      (e1, e2) =>
        new Date(e2.membership_details.created_at).getTime() -
        new Date(e1.membership_details.created_at).getTime()
    );

  if (!actives?.length) {
    return { workspaceID: null, isLoaing: false };
  }

  let loginsFiltered = logins
    .filter((e) => actives?.some((a) => a.id === e.workspaceID))
    .sort(
      (e1, e2) =>
        new Date(e2.lastLogin ?? "").getTime() -
        new Date(e1.lastLogin ?? "").getTime()
    );

  if (loginsFiltered.length) {
    return { workspaceID: loginsFiltered[0].workspaceID, isLoaing: false };
  }

  return { workspaceID: actives[0].id, isLoaing: false };
};
//#endregion workspace and permissions

//#region Company
export const useQueryCompanies = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  return useQuery(
    ["companies", workspaceID, config],
    () => getCompanies(workspaceID, config),
    {
      enabled: !!workspaceID,
      keepPreviousData: true,
    }
  );
};

export const useMutationCreateCompany = (
  options: UseMutationOptions<typeof createCompany> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      workspaceID,
      data,
      config,
    }: IMutationCreate<CompanyCreate>) =>
      createCompany(workspaceID, data, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["companies", variables.workspaceID],
      });
    },
  });
};

export const useMutationUpdateCompany = (
  options: UseMutationOptions<typeof updateCompany> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      workspaceID,
      data,
      id,
      config,
    }: IMutationUpdate<CompanyCreate>) =>
      updateCompany(workspaceID, id, data, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["companies", variables.workspaceID],
      });
    },
  });
};

export const useMutationDeleteCompany = (
  options: UseMutationOptions<typeof deleteCompany> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ workspaceID, id, config }: IMutationDelete) =>
      deleteCompany(workspaceID, id, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["companies", variables.workspaceID],
      });
    },
  });
};

//#end region Company

//#region Contact

export const useQueryContacts = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  return useQuery(
    ["contacts", workspaceID, config],
    () => getContactList(workspaceID, config),
    {
      enabled: !!workspaceID,
      keepPreviousData: true,
    }
  );
};

export const useMutationCreateContact = (
  options: UseMutationOptions<typeof createContact> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      workspaceID,
      data,
      config,
    }: IMutationCreate<ContactCreate>) =>
      createContact(workspaceID, data, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["contacts", variables.workspaceID],
      });
    },
  });
};

export const useMutationUpdateContact = (
  options: UseMutationOptions<typeof updateContact> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      workspaceID,
      data,
      id,
      config,
    }: IMutationUpdate<ContactCreate>) =>
      updateContact(workspaceID, id, data, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["contacts", variables.workspaceID],
      });
    },
  });
};

export const useMutationDeleteContact = (
  options: UseMutationOptions<typeof deleteContact> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ workspaceID, id, config }: IMutationDelete) =>
      deleteContact(workspaceID, id, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["contacts", variables.workspaceID],
      });
    },
  });
};
//# end region Contact

//#region Group
export const useQueryGroups = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  return useQuery(
    ["groups", workspaceID, config],
    () => getGroups(workspaceID, config),
    {
      enabled: !!workspaceID,
      keepPreviousData: true,
    }
  );
};
export const useQueryGroupMembers = (
  workspaceID: string | undefined,
  groupSlug: string,
  config?: AxiosRequestConfig
) => {
  return useQuery(
    ["groups", groupSlug, workspaceID, config],
    () => getGroupMembers(workspaceID, groupSlug, config),
    {
      enabled: !!workspaceID,
      keepPreviousData: false,
    }
  );
};
export const useMutationCreateGroup = (
  options: UseMutationOptions<typeof createGroup> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ workspaceID, data, config }: IMutationCreate<GroupCreate>) =>
      createGroup(workspaceID, data, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["groups", variables.workspaceID],
      });
    },
  });
};

export const useMutationUpdateGroup = (
  options: UseMutationOptions<typeof updateGroup> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      workspaceID,
      data,
      id,
      config,
    }: IMutationUpdate<GroupCreate>) =>
      updateGroup(workspaceID, id, data, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["groups", variables.workspaceID],
      });
    },
  });
};

export const useMutationDeleteGroup = (
  options: UseMutationOptions<typeof deleteGroup> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ workspaceID, id, config }: IMutationDelete) =>
      deleteGroup(workspaceID, id, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["groups", variables.workspaceID],
      });
    },
  });
};

//#end region Group

export const useUpdateWorkspace = (
  options: UseMutationOptions<typeof updateWorkspace> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { workspaceID: string; workspace: WorkspaceUpdate }) =>
      updateWorkspace(data.workspaceID, data.workspace),
    onSuccess: ({ id }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["workspace", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["workspaces"],
      });
    },
  });
};

export const usePermissions = () => {
  return useQuery(["permissions"], () => getPermissions());
};

export const useRoles = (workspaceID: string | undefined) => {
  return useQuery(["roles", workspaceID], () => getRoles(workspaceID));
};

export const useCreateRole = (
  options: UseMutationOptions<typeof createRole> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { workspaceID: string; role: CreateRole }) =>
      createRole(data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["roles"] });
    },
  });
};

export const useUpdateRole = (
  options: UseMutationOptions<typeof updateRole> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { workspaceID: string; id: number; role: CreateRole }) =>
      updateRole(data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["roles"] });
    },
  });
};

export const useMutationDeleteRole = (
  options: UseMutationOptions<typeof deleteRole> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ workspaceID, id, config }: IMutationDelete) =>
      deleteRole(workspaceID, id, config),
    onSuccess: (data, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["roles", variables.workspaceID],
      });
    },
  });
};

export const useMembers = (workspaceID: string | undefined) => {
  return useQuery(["members", workspaceID], () => getMembers(workspaceID));
};

export const useCreateMember = (
  options: UseMutationOptions<typeof createMember> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: { workspaceID: string; member: CreateMember }) =>
      createMember(data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};

export const useUpdateMember = (
  options: UseMutationOptions<typeof updateMember> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      workspaceID: string;
      id: number;
      member: UpdateMember;
    }) => updateMember(data),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
};
