import { Stack, Typography } from "@mui/material";
import ProfileAvatar from "./ProfileAvatar";
import { useQueryUserInfo } from "api/auth";

const ProfileEmailAvatarListItem = () => {
  const { data: user } = useQueryUserInfo();

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      sx={{ textOverflow: "ellipsis", paddingY: 2, width: "100%" }}
    >
      <ProfileAvatar size="lg" user={user} />
      <Stack sx={{ minWidth: "0" }}>
        <Typography variant="s13w700" mb={1} noWrap>
          {user?.first_name || user?.last_name
            ? `${user?.first_name ?? ""} ${user?.last_name ?? ""}`
            : "User"}
        </Typography>
        <Typography variant="s13w400" color="dim-grey" noWrap>
          {user?.email}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ProfileEmailAvatarListItem;
