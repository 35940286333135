import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  SelectProps,
  Typography,
} from "@mui/material";
import React from "react";
import { useField } from "formik";
import AppSelect from "./AppSelect";

type propType = {
  name: string;
  label?: string;
  topLabel?: string;
  readOnly?: boolean;
} & SelectProps;

const FormikSelectField = ({
  name,
  label,
  topLabel,
  readOnly,
  ...props
}: propType) => {
  const [field, meta, helpers] = useField(name);
  return (
    <FormControl error={meta.touched && !!meta.error} fullWidth>
      {topLabel && (
        <Box textAlign={"left"} width={"100%"} mb={"0.25rem"} ml={1}>
          <Typography variant="s13w500">{topLabel}</Typography>
        </Box>
      )}
      {label && <InputLabel id={name + "-label"}>{label}</InputLabel>}
      <AppSelect
        labelId={name + "-label"}
        id={name}
        label={label}
        {...field}
        value={field.value ?? ""}
        onChange={(e) => helpers.setValue(e.target.value)}
        readOnly={readOnly}
        {...props}
      >
        {props.children}
      </AppSelect>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelectField;
