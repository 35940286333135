import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Box,
  Button,
  Switch,
  Stack,
  useTheme,
  Typography,
} from "@mui/material";

import AddMemberForm from "components/Forms/AddMemberForm";
import UpdateMemberRole from "components/Forms/UpdateMemberRole";
import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import {
  Member,
  PERMISSION_MODEL_NAMES,
  PermissionAccessLevelEnum,
  useHasPermissions,
  useMembers,
  useUpdateMember,
} from "api/workspace";
import { useQueryUserInfo } from "api/auth";
import DialogAlertConfirm from "components/Dialog/DialogAlertConfirm";
import useAlert from "hooks/useAlert";
import { WorkspacePermissions } from "api/types";
import AppDialog from "components/Dialog/AppDialog";
import AppDialogTitle from "components/Dialog/AppDialogTitle";
import AppDialogContent from "components/Dialog/AppDialogContent";
import HookPaginatedTable, {
  TableFilters,
  defaultFilters,
} from "components/Table/HookPaginatedTable";
import SuccessTickIcon from "assets/image-components/shared/successTick";
import SmallCloseIcon from "assets/image-components/shared/smallClose";

type MemberType = Member & { isChangingStatus?: boolean };

function Members({ renderTabs }: { renderTabs: () => React.ReactNode }) {
  const theme = useTheme();

  const { workspaceID } = useParams();
  const { data: userData } = useQueryUserInfo();
  const { data: members } = useMembers(workspaceID);

  const [filters, setFilters] = useState<TableFilters>({
    ...defaultFilters,
    search: "",
  });
  const [open, setOpen] = useState(false);
  const [instance, setInstance] = useState<Member>();

  const { mutate: updateUserMutateFn } = useUpdateMember({});
  const [openDeactivateUser, setOpenDeactivateUser] = useState(false);
  const [tableData, setTableData] = useState<MemberType[] | undefined>(
    members?.results
  );
  const { hasPermission: hasMemberReadWritePermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteMembers],
    workspaceID
  );
  const { hasPermission: hasRoleReadPermission } = useHasPermissions(
    [WorkspacePermissions.ReadRole],
    workspaceID
  );

  const { showAlert } = useAlert();

  const toggleIsChangingStatus = (
    member: MemberType,
    toggleStatus?: boolean
  ) => {
    if (!tableData) {
      return;
    }

    let sl = tableData.slice();
    let i = sl.findIndex((e) => e.id === member.id);
    if (toggleStatus) {
      member.is_active = !member.is_active;
    }
    sl[i].isChangingStatus = !member.isChangingStatus ?? true;
    setTableData(sl);
  };

  const updateUserActiveStatus = (member: MemberType) => {
    if (!workspaceID) {
      return;
    }

    toggleIsChangingStatus(member);

    updateUserMutateFn(
      {
        workspaceID,
        id: member.id,
        member: {
          role: member?.role,
          is_active: !member.is_active,
          is_admin: member?.is_admin,
          is_owner: member.is_owner,
          is_team_member: member.is_team_member,
        },
      },
      {
        onSuccess: () => {
          showAlert({
            message: "Member updated successfully",
            severity: "success",
          });
          toggleIsChangingStatus(member, true);
        },
        onError: (error: any) => {
          showAlert({ message: error, severity: "error" });
        },
      }
    );
  };
  useEffect(() => {
    setTableData(members?.results);
    setInstance(undefined);
  }, [members]);

  const handleDeactivateUserClose = (result: boolean) => {
    if (!result || !instance) {
      setOpenDeactivateUser(false);
      return;
    }
    updateUserActiveStatus(instance);
    setOpenDeactivateUser(false);
  };

  const handleChangeUserActiveStatus = (member: MemberType) => {
    setInstance(member);
    if (member.is_active) {
      setOpenDeactivateUser(true);
    } else {
      updateUserActiveStatus(member);
    }
  };

  const isCurrentLoggedInUserOrOwner = (member: Member) => {
    return (
      member.is_owner ||
      (member?.user_details?.email !== undefined &&
        member?.user_details.email === userData?.email)
    );
  };

  return (
    <PermissionCheck
      requiredPermissions={[
        {
          resource_name: PERMISSION_MODEL_NAMES.MEMBERSHIP,
          access_level: PermissionAccessLevelEnum.Read,
        },
      ]}
      showError={true}
    >
      <Box>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          {renderTabs()}
          <PermissionCheck
            requiredPermissions={[
              {
                resource_name: PERMISSION_MODEL_NAMES.MEMBERSHIP,
                access_level: PermissionAccessLevelEnum.ReadWrite,
              },
            ]}
          >
            <Button
              onClick={() => {
                setInstance(undefined);
                setOpen(true);
              }}
              variant="contained"
              sx={{ width: "10.25rem" }}
            >
              <Typography variant="s13w600">New Member</Typography>
            </Button>
          </PermissionCheck>
        </Stack>
        <HookPaginatedTable
          filters={filters}
          changeFilters={setFilters}
          data={{
            results: tableData ?? [],
            count: members?.count ?? 0,
          }}
          caption="Member"
          columns={[
            { label: "ID", name: "id", render: (item) => item.id },
            {
              label: "Full Name",
              name: "full_name",
              render: (item) =>
                `${item.user_details.first_name} ${item.user_details.last_name}`.trim()
                  .length > 0
                  ? `${item.user_details.first_name} ${item.user_details.last_name}`
                  : "N/A",
            },
            {
              label: "Email",
              name: "email",
              render: (item) => item.user_details.email,
            },
            {
              label: "Confirmed",
              name: "is_confirmed",
              render: (item) =>
                item.is_confirmed ? (
                  <Box
                    bgcolor={theme.palette.secondary[20]}
                    width={"1.5rem"}
                    height={"1.5rem"}
                    borderRadius={"50%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <SuccessTickIcon />
                  </Box>
                ) : (
                  <Box
                    bgcolor={theme.palette.error[20]}
                    width={"1.5rem"}
                    height={"1.5rem"}
                    borderRadius={"50%"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <SmallCloseIcon />
                  </Box>
                ),
            },
            {
              label: "Role",
              name: "role",
              render: (item) => (
                <UpdateMemberRole
                  key={item.id}
                  instance={item}
                  disabled={
                    !hasMemberReadWritePermission ||
                    !hasRoleReadPermission ||
                    isCurrentLoggedInUserOrOwner(item)
                  }
                  onSuccess={() => {
                    showAlert({
                      message: "Member role updated successfully",
                      severity: "success",
                    });
                  }}
                />
              ),
            },

            {
              label: "Active",
              name: "is_active",
              render: (item) => (
                <Switch
                  disabled={
                    isCurrentLoggedInUserOrOwner(item) ||
                    item.isChangingStatus ||
                    !hasMemberReadWritePermission
                  }
                  checked={item.is_active}
                  onClick={(e) => handleChangeUserActiveStatus(item)}
                />
              ),
            },
          ]}
        />
        <DialogAlertConfirm
          text={"Are you sure to deactivate the user?"}
          color="primary"
          open={openDeactivateUser}
          handleClose={handleDeactivateUserClose}
        />
        <AppDialog open={open} onClose={(r, e) => setOpen(false)}>
          <AppDialogTitle
            title="New Member"
            onClose={(r, e) => setOpen(false)}
          />
          <AppDialogContent>
            <AddMemberForm
              onSuccess={() => {
                setOpen(false);
                showAlert({
                  message: "Member Added successfully",
                  severity: "success",
                });
              }}
            />
          </AppDialogContent>
        </AppDialog>
      </Box>
    </PermissionCheck>
  );
}

export default Members;
