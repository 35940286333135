// project import
import analyze from "./analyze";
import email from "./email";
import inquiries from "./inquiries";

const menuItems = {
  items: [inquiries, analyze, email],
};

export default menuItems;
