import useAlert from "hooks/useAlert";
import {
  useHasWorkspaceActiveMembership,
  useLastLoggedInWorkspaceID,
  useQueryWorkspaces,
} from "api/workspace";
import { ReactNode, useEffect, useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import PanelDisabledDialog from "components/PermissionCheck/PanelDiabledDialogContent";
import { dispatch } from "store";
import { insertLoginHistory } from "store/reducers/workspace";
import { heapAddUserProperties } from "utils/heap-utils";

type props = {
  children?: ReactNode;
};

const WorkspaceNavigator = ({ children }: props) => {
  const { workspaceID } = useParams();
  const isLogin = useSelector((state: any) => state.auth.isLogin);
  const { isLoading, hasMembership } =
    useHasWorkspaceActiveMembership(workspaceID);
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const [openPanelDisableDialog, setOpenPanelDisableDialog] = useState(false);
  const { workspaceID: lastLoggedInWorkspaceId, isLoaing: isLoadingLastLogin } =
    useLastLoggedInWorkspaceID();

  const { data: workspaces } = useQueryWorkspaces();

  const showError = useCallback(
    () =>
      showAlert({
        message: `You don't have permision to access the workspace`,
      }),
    [showAlert]
  );

  const insertHistory = (
    workspaceID: string | undefined,
    navigateTo?: boolean
  ) => {
    let workspace = workspaces?.find((e) => e.id === workspaceID);
    heapAddUserProperties({
      lastLoggedInWorkspaceId: workspaceID,
      lastLoggedInWorkspaceName: workspace?.name,
    });
    dispatch(insertLoginHistory({ workspaceID }));
    if (navigateTo) {
      navigate(`/workspace/${lastLoggedInWorkspaceId}/`);
    }
  };

  useEffect(() => {
    if (!isLogin) {
      navigate("/login");
    }

    if (!isLogin || (workspaceID && isLoading) || isLoadingLastLogin) {
      return;
    }

    if (!workspaceID && !lastLoggedInWorkspaceId) {
      setOpenPanelDisableDialog(true);
    } else if (!workspaceID && lastLoggedInWorkspaceId) {
      insertHistory(lastLoggedInWorkspaceId, true);
    } else if (workspaceID && hasMembership) {
      insertHistory(workspaceID);
      // dispatch(insertHistory({ workspaceID }));
    } else if (workspaceID && !hasMembership && !lastLoggedInWorkspaceId) {
      setOpenPanelDisableDialog(true);
    } else if (workspaceID && !hasMembership && lastLoggedInWorkspaceId) {
      insertHistory(lastLoggedInWorkspaceId, true);
      showError();
    }
  }, [
    workspaceID,
    lastLoggedInWorkspaceId,
    hasMembership,
    isLogin,
    isLoading,
    isLoadingLastLogin,
  ]);

  return (
    <>
      <PanelDisabledDialog open={openPanelDisableDialog} />
      {children}
    </>
  );
};

export default WorkspaceNavigator;
