// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

import { PalettesProps } from "@ant-design/colors";
import { colorStructure, paletteColors } from "themes/types";

declare module "@mui/material/styles" {
  interface Palette {
    black: colorStructure;
    bittersweet: colorStructure;
    orange: colorStructure;
    "dark-gunmetal": string;
    "space-cadet": string;
    "dim-grey": string;
    "cool-grey": string;
    "light-grey": string;
    "ghost-grey": string;
    "sea-salt": string;
    platinum: string;
    white: string;
  }
  interface PaletteColor {
    main: string;
    hovered: string;
    disabled: string;
    5: string;
    10: string;
    20: string;
    25: string;
    contrastText: string;
    disabledText: string;
  }

  export interface PaletteColorOptions {
    main: string;
    hovered?: string;
    disabled?: string;
    5?: string;
    10?: string;
    20?: string;
    25?: string;
    contrastText?: string;
    disabledText?: string;
  }
}
const Theme = (colors: PalettesProps) => {
  const resultPalette: paletteColors = {
    "p-blue": {
      main: "#303FC7",
      hovered: "#3039A2",
      disabled: "#303FC766",
      5: "#303FC70D",
      10: "#303FC71A",
      25: "#303FC740",
      contrastText: "#FFFFFF",
      disabledText: "#FFFFFF",
    },
    aqua: {
      main: "#00F4B8",
      hovered: "#44CF80",
      disabled: "#99fbe3",
      10: "#00DBA51A",
      20: "#00DBA533",
      25: "#00DBA540",
      contrastText: "#2D3044",
      disabledText: "#6eaaa3",
    },
    bittersweet: {
      main: "#FA5959",
      10: "#FA59591A",
      20: "#FA595933",
      25: "#FA595940",
    },
    black: {
      main: "#000000",
      10: "#0000001A",
    },
    orange: {
      main: "#F0A202",
      10: "#F0A2021A",
    },

    "dark-gunmetal": "#1D1F2B",
    "space-cadet": "#2D3044",
    "dim-grey": "#676977",
    "cool-grey": "#91929D",
    "light-grey": "#D0D0D5",
    platinum: "#E1E1EA",
    "ghost-grey": "#EAEAEC",
    "sea-salt": "#FAFAFA",
    white: "#FFFFFF",
  };

  return resultPalette;
};

export default Theme;
