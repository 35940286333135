import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SvgIconProps } from "@mui/material";

const CloseIcon = (props: SvgIconProps) => (
  <CloseRoundedIcon
    {...props}
    sx={{
      color: (theme) => theme.palette.error.main,
      width: 24,
      height: 24,
      ...props.sx,
    }}
  />
);

export default CloseIcon;
