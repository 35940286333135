import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const DrawerHeaderStyled = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingLeft: theme.spacing(0),
}));

export default DrawerHeaderStyled;
