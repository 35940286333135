import { ReactNode, createContext } from "react";

export interface PopoverContextType {
  handleClose: () => void;
}

const PopoverContext = createContext<PopoverContextType>({
  handleClose: () => {},
});

const PopoverProvider = ({
  children,
  handleClose,
}: {
  children: ReactNode;
  handleClose: () => void;
}) => {
  return <PopoverContext.Provider value={{handleClose}}>{children}</PopoverContext.Provider>;
};


export {PopoverContext, PopoverProvider};
