import React, { useState } from "react";
import { useParams } from "react-router";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import RoleForm from "components/Forms/RoleForm/RoleForm";

import PermissionCheck from "components/PermissionCheck/PermissionCheck";
import {
  DEFAULT_ROLES,
  PERMISSION_MODEL_NAMES,
  PermissionAccessLevelEnum,
  Role,
  StaticRole,
  useMutationDeleteRole,
  useQueryUserMembership,
  useRoles,
} from "api/workspace";
import HookPaginatedTable, {
  TableFilters,
  defaultFilters,
} from "components/Table/HookPaginatedTable";
import AppDialog from "components/Dialog/AppDialog";
import AppDialogTitle from "components/Dialog/AppDialogTitle";
import AppDialogContent from "components/Dialog/AppDialogContent";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RoleActions from "./RoleActions";
import AppPopover from "components/Popover/AppPopover";
import RoleDetailsPopover from "./RoleDetailsPopover";
import useAlert from "hooks/useAlert";
import { getPermissionRenderData } from "../helpers";

const MySwal = withReactContent(Swal);

export type RoleType = StaticRole &
  Role & {
    isDefaultRole?: boolean | undefined;
  };

function Roles({ renderTabs }: { renderTabs: () => React.ReactNode }) {
  const { workspaceID } = useParams();
  const { data: roles } = useRoles(workspaceID);
  const { data: userMembershipInfo } = useQueryUserMembership(workspaceID);
  const [open, setOpen] = useState(false);
  const [instance, setInstance] = useState<Role>();
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [selectedRole, setSelectedRole] =
    useState<{ name: string; accessLevelDisplayName: string }[]>();
  const [filters, setFilters] = useState<TableFilters>({
    ...defaultFilters,
    search: "",
  });
  const popoverOpen = Boolean(anchorEl);

  const { showAlert } = useAlert();

  const { mutate: deleteRole } = useMutationDeleteRole();

  const onEditRoleClicked = (role: Role) => {
    setInstance(role);
    setOpen(true);
  };
  const onDeleteRoleClicked = (role: Role) => {
    MySwal.fire({
      title: "Are you sure to delete the role?",
      text: "Your members will be safe and their role set to null",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "red",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRole(
          { workspaceID, id: role.id },
          {
            onSuccess: () =>
              showAlert({
                message: "Role deleted successfully!",
                severity: "success",
              }),
            onError: (error: any) => {
              showAlert({
                message: error,
                severity: "error",
              });
            },
          }
        );
      }
    });
  };

  return (
    <PermissionCheck
      requiredPermissions={[
        {
          resource_name: PERMISSION_MODEL_NAMES.ROLE,
          access_level: PermissionAccessLevelEnum.Read,
        },
      ]}
      showError
    >
      <Box>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          {renderTabs()}
          <PermissionCheck
            requiredPermissions={[
              {
                resource_name: PERMISSION_MODEL_NAMES.ROLE,
                access_level: PermissionAccessLevelEnum.ReadWrite,
              },
            ]}
          >
            <Button
              onClick={() => {
                setInstance(undefined);
                setOpen(true);
              }}
              variant="contained"
              sx={{ width: "10.25rem" }}
            >
              <Typography variant="s13w600">New Role</Typography>
            </Button>
          </PermissionCheck>
        </Stack>
        {roles && (
          <HookPaginatedTable
            filters={filters}
            changeFilters={setFilters}
            data={{
              results: [
                ...DEFAULT_ROLES.map((role) => {
                  return { ...role, isDefaultRole: true };
                }),
                ...roles,
              ] as Array<StaticRole & Role & { isDefaultRole?: boolean }>,
              count: roles?.length,
            }}
            caption="Member"
            columns={[
              {
                label: "ID",
                name: "id",
                // check role is default or not to apply index as id
                render: (item, index) => (index ?? 0) + 1,
              },
              {
                label: "Role",
                name: "role",
                render: (item) => item.name,
              },
              {
                label: "Permissions",
                name: "permissions",
                render: (item) => (
                  <Box display={"flex"}>
                    <Tooltip
                      title={
                        item?.isDefaultRole &&
                        (item.KEY === "ADMIN" || item.KEY === "PERSONAL") &&
                        item.description
                      }
                      placement="top"
                    >
                      <Box
                        maxWidth={"25rem"}
                        whiteSpace={"nowrap"}
                        textOverflow={"ellipsis"}
                        overflow={"hidden"}
                      >
                        {item.isDefaultRole
                          ? item.description
                          : getPermissionRenderData(item)?.map(
                              (p, index, array) =>
                                `${p.name} (${p.accessLevelDisplayName})${
                                  index !== array.length - 1 ? ", " : ""
                                }`
                            )}
                      </Box>
                    </Tooltip>
                    {!item?.isDefaultRole && (
                      <Box ml={1}>
                        <Typography
                          variant="s13w600"
                          color="primary"
                          noWrap
                          sx={{ cursor: "pointer" }}
                          onClick={(e) => {
                            setSelectedRole(getPermissionRenderData(item));
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          See More
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ),
              },
            ]}
            renderAction={(row) =>
              !row.isDefaultRole ? (
                <PermissionCheck
                  requiredPermissions={[
                    {
                      resource_name: PERMISSION_MODEL_NAMES.ROLE,
                      access_level: PermissionAccessLevelEnum.ReadWrite,
                    },
                  ]}
                  showError={false}
                >
                  <RoleActions
                    disabled={
                      !row?.isDefaultRole && row.id === userMembershipInfo?.role
                    }
                    onEditClicked={onEditRoleClicked}
                    onDeleteClicked={onDeleteRoleClicked}
                    role={row}
                  />
                </PermissionCheck>
              ) : (
                <></>
              )
            }
          />
        )}

        <AppDialog
          open={open}
          onClose={(r, e) => setOpen(false)}
          paperMaxWidth={"36rem"}
        >
          <AppDialogTitle
            title={instance ? "Edit Role" : "New Role"}
            onClose={(r, e) => setOpen(false)}
          />
          <AppDialogContent>
            <RoleForm
              instance={instance}
              onSuccess={() => {
                setOpen(false);
              }}
            />
          </AppDialogContent>
        </AppDialog>
        {anchorEl && selectedRole && (
          <AppPopover
            open={popoverOpen}
            handleClose={() => setAnchorEl(null)}
            setAnchorEl={setAnchorEl}
            anchorEl={anchorEl}
            popoverProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              sx: {
                marginTop: "-0.5rem",
              },
            }}
          >
            <RoleDetailsPopover role={selectedRole} />
          </AppPopover>
        )}
      </Box>
    </PermissionCheck>
  );
}

export default Roles;
