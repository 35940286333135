export interface WorkspaceResponse {
  id: string;
  name: string;
  website: string;
  membership_details: MembershipDetails;
}
export type WorkspaceUpdate = Omit<
  WorkspaceResponse,
  "id" | "membership_details"
>;
export interface ContactResponse {
  slug: string;
  full_name: string;
  email: string;
  mobile: string | null;
  company: string;
  company_name: string | null;
  workspace: string;
}
export type ContactCreate = Omit<ContactResponse, "slug" | "company_name">;

export type DEFAULT_ROLE_KEY = "ADMIN" | "OWNER" | "TEAM_MEMBER" | "PERSONAL";

export type StaticRole = {
  KEY: DEFAULT_ROLE_KEY;
  name: string;
  description: string;
  isSelectable: boolean;
};

export const DEFAULT_ROLES: StaticRole[] = [
  {
    KEY: "OWNER",
    name: "Workspace Owner",
    description: "Full access to all application sections",
    isSelectable: false,
  },
  {
    KEY: "ADMIN",
    name: "Admin",
    description:
      "Full access to all application sections, except owner membership modification",
    isSelectable: true,
  },
  {
    KEY: "TEAM_MEMBER",
    name: "Member",
    description: "Access to team inquiries, quotes and shipments",
    isSelectable: true,
  },
  {
    KEY: "PERSONAL",
    name: "Personal",
    description:
      "Access just to inquiries, quotes and shipments that user owns plus companies and contacts",
    isSelectable: true,
  },
];

export type JoinWorkspaceData = {
  user: string;
  password: string;
  token: string;
  workspace: string;
  first_name?: string;
  last_name?: string;
};

export type OldUserJoinWorkspaceData = Pick<
  JoinWorkspaceData,
  "token" | "workspace"
>;

export interface JoinWorkspaceResponse {
  access: string;
  refresh: string;
}
export interface UserDetails {
  first_name: string;
  last_name: string;
  email: string;
}

export type PermissionAccessLevel = "R" | "RW";
export enum PermissionAccessLevelEnum {
  Read = "R",
  ReadWrite = "RW",
}

export interface PermissionDetails {
  permission_details: Permission;
  access_level: PermissionAccessLevel;
}

export interface HasPermission {
  resource_name: string;
  access_level: PermissionAccessLevel;
}

export interface RoleDetails {
  id: number;
  name: string;
  workspace: string;
  permissions_details: PermissionDetails[];
}

export interface MembershipDetails {
  id: number;
  role: number;
  role_details: RoleDetails | null;
  is_owner: boolean;
  is_admin: boolean;
  is_team_member: boolean;
  is_active: boolean;
  is_confirmed: boolean;
  user: number;
  workspace: string;
  user_details: UserDetails;
  created_at: Date;
  updated_at: Date;
}

export type CompanyResponse = {
  slug: string;
  name: string;
  website?: string;
};
export type CompanyCreate = Omit<CompanyResponse, "slug">;

export type GroupResponse = {
  slug: string;
  name: string;
  company_details: {
    id: number;
    name: string;
    slug: string;
    website: string;
  };
  to_details: ContactResponse[];
  cc_details: ContactResponse[];
};
export type GroupCreate = {
  name: string;
  company: string;
  to: string[];
  cc?: string[];
};

export type CarrierType = {
  id: number;
  name: string;
};

export type Permission = {
  id: Readonly<number>;
  resource_name: PERMISSION_MODEL_NAMES;
  display_name: string;
};

export enum PERMISSION_MODEL_NAMES {
  WORKSPACE = "workspace",
  INQUIRY = "inquiry",
  SHIPMENT = "shipment",
  QUOTE = "quote",
  MEMBERSHIP = "membership",
  ROLE = "role",
}

export type Role = {
  id: Readonly<number>;
  name: string;
  permissions_details: Readonly<PermissionDetails[]>;
  workspace: string;
};

export type Member = {
  id: Readonly<number>;
  role_details: Readonly<Role>;
  role: number;
  is_owner: boolean;
  is_admin: boolean;
  is_team_member: boolean;
  is_active: boolean;
  is_confirmed: boolean;
  user: number;
  workspace: string;
  user_details: {
    first_name: string;
    last_name: string;
    email: string;
  };
};

export type CreateRolePermission = {
  permission: number;
  access_level: PermissionAccessLevel;
};

export type CreateRole = {
  name: string;
  permissions: {};
};

export type CreateMember = {
  role: number | string | null;
  email: string;
  is_admin: boolean;
  is_owner: boolean;
  is_team_member: boolean;
};
export type UpdateMember = {
  role: number | string | null;
  is_active: boolean;
  is_admin: boolean;
  is_owner: boolean;
  is_team_member: boolean;
};

export interface EmailTemplateResponse {
  id: number;
  name: string;
  html: string;
  workspace: string;
  user: number;
}

export interface WorkspaceLoginHistory {
  workspaceID: string;
  lastLogin: string;
}

export type EmailTemplateCreate = Omit<EmailTemplateResponse, "id">;

export function filterPermissionsInRoleByModelName(
  role: Role,
  modelName: PERMISSION_MODEL_NAMES
): PermissionDetails | undefined {
  return role?.permissions_details.find(
    (e) => e.permission_details.resource_name === modelName
  );
}

export function filterPermissionByModel(
  permissions: Permission[],
  modelName: PERMISSION_MODEL_NAMES
): Permission | undefined {
  return permissions?.find((e) => e.resource_name === modelName);
}
