import { UserInfoResponse } from "api/auth";

declare const heap: any;

export function heapIdentifyUser(userInfo: UserInfoResponse | undefined) {
  if (!userInfo || typeof heap === "undefined") {
    return;
  }

  heap?.identify(userInfo.email);
  heapSetUserName(userInfo.first_name, userInfo.last_name);
}

export function heapAddUserProperties(properties: object) {
  if (typeof heap === "undefined") {
    return;
  }
  heap?.addUserProperties(properties);
}

export function heapSetUserName(
  firstName: string | undefined,
  lastName: string | undefined
) {
  heapAddUserProperties({
    firstName,
    lastName,
  });
}
