// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  isChatListActive: false,
  isShipmentStepsActive: false,
  isShipmentCategoriesActive: false,
  isChatListExpanded: false,
  activeChat: null,
};

const sidebar = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    activeChatList(state, action) {
      state.isChatListActive = action.payload;
    },
    activeShipmentSteps(state, action) {
      state.isShipmentStepsActive = action.payload;
    },
    activeShipmentCategories(state, action) {
      state.isShipmentCategoriesActive = action.payload;
    },
    expandChatList(state, action) {
      state.isChatListExpanded = action.payload;
    },
    setActiveChat(state, action) {
      state.activeChat = action.payload;
    },
  },
});

export default sidebar.reducer;

export const {
  expandChatList,
  activeChatList,
  activeShipmentSteps,
  activeShipmentCategories,
  setActiveChat,
} = sidebar.actions;
