import TableActionButton from "components/Table/TableActionButton";
import TableActionContainer from "components/Table/TableActionContainer";
import { RoleType } from "./Roles";
import { useTheme } from "@mui/material";

type propType = {
  role: RoleType;
  disabled?: boolean;
  onEditClicked: (role: RoleType) => void;
  onDeleteClicked: (role: RoleType) => void;
};

const RoleActions = ({
  role,
  disabled,
  onEditClicked,
  onDeleteClicked,
}: propType) => {
  const theme = useTheme();
  return (
    <TableActionContainer flexWrap="nowrap">
      <TableActionButton
        color="primary"
        caption="Edit"
        disabled={disabled}
        onClick={() => onEditClicked(role)}
        sx={{
          "&.Mui-disabled": {
            bgcolor: theme.palette.primary[5],
            color: theme.palette.primary[25],
          },
        }}
      />
      <TableActionButton
        color="error"
        caption="Delete"
        disabled={disabled}
        onClick={() => onDeleteClicked(role)}
        sx={{
          "&.Mui-disabled": {
            bgcolor: theme.palette.error[5],
            color: theme.palette.error[25],
          },
        }}
      />
    </TableActionContainer>
  );
};

export default RoleActions;
