import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/material";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import Logo from "components/Logo";

// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = () => {
  const theme = useTheme();

  return (
    // only available in paid version
    <DrawerHeaderStyled
      sx={{
        padding: 0,
        ml: 0,
        mb: "0.5rem",
        minHeight: "unset",
      }}
      theme={theme}
    >
      <Stack direction="row" alignItems="center">
        <Logo
          sx={{
            textAlign: "left",
            justifyContent: "start",
          }}
          place={"drawer"}
        />
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
