import { Stack, StackProps } from "@mui/system";

const TableActionContainer = (props: StackProps) => {
  return (
    <Stack
      rowGap={1}
      columnGap={1}
      direction="row"
      alignItems="center"
      justifyContent="end"
      flexWrap="wrap"
      {...props}
    >
      {props.children}
    </Stack>
  );
};

export default TableActionContainer;
