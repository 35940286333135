import { Theme } from "@mui/material";

export default function MenuItem(theme: Theme) {
  return {
    MuiMenuItem: {
      styleOverrides: {
        borderRadius: theme.customRadius,
        padding: 10,
        root: {
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary[10],
          },
          "&:hover": {
            backgroundColor: theme.palette.primary[5],
          },
        },
      },
    },
  };
}
