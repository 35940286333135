import { Theme } from "@mui/material";

export default function Menu(theme: Theme) {
    return {
        MuiMenu: {
            styleOverrides: {
                borderRadius: theme.customRadius,
                marginTop: theme.spacing(10),
                root: {
                    '& .MuiPaper-root': {
                        backgroundColor: "white",
                        boxShadow: theme.customShadows.menu,
                        borderRadius: theme.customRadius,
                        marginTop: 2
                    }
                }
            }
        }
    }
};
