import { lazy } from "react";
import { Link, Navigate } from "react-router-dom";
// project import
import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import WorkspaceNavigator from "routes/WorkspaceNavigator";
import ManageMembers from "pages/workspace/ManageMembers";
import ManageRoles from "pages/workspace/ManageRoles";
import { InquiryStatus } from "api/inquiry";

const InquiryList = Loadable(
  lazy(() => import("pages/inquiries/MainShipment"))
);

const CreateInquiry = Loadable(
  lazy(() => import("pages/inquiries/CreateInquiry"))
);
const MainInquiry = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/index"))
);
const MainQuotes = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/quotes/MainQuotes"))
);
const InquiryDetails = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/InquiryDetails"))
);
const InquiryQuotes = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/QuoteList"))
);
const Communication = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/quotes/QuoteCommunication"))
);
const Documents = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/quotes/Documents"))
);
const Tracking = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/quotes/Tracking"))
);

const GetRate = Loadable(lazy(() => import("pages/inquiries/GetRate")));
const UpdateQuote = Loadable(
  lazy(() => import("pages/inquiries/[inquiryID]/quotes/QuoteDetails"))
);
const ContactList = Loadable(lazy(() => import("pages/contacts/ContactList")));
const CompaniesList = Loadable(
  lazy(() => import("pages/companies/CompaniesList"))
);
const GroupsList = Loadable(lazy(() => import("pages/groups/GroupsList")));

const Analytics = Loadable(lazy(() => import("pages/analytics/MainAnalytics")));

const Chats = Loadable(lazy(() => import("pages/chats/MainChats")));

const Profile = Loadable(lazy(() => import("pages/Profile/Profile")));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
  path: "workspace/:workspaceID",
  element: (
    <WorkspaceNavigator>
      <MainLayout />
    </WorkspaceNavigator>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="inquiries" replace />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <InquiryList />,
        },
      ],
    },
    {
      path: "profile",
      element: <Profile />,
    },
    {
      path: "inquiries",
      children: [
        {
          index: true,
          element: <InquiryList category={null} />,
        },
        {
          path: "all",
          element: <InquiryList category={null} />,
        },
        {
          path: "creating",
          element: <InquiryList category={InquiryStatus.CREATING} />,
        },
        {
          path: "requesting",
          element: <InquiryList category={InquiryStatus.REQUESTING} />,
        },
        {
          path: "tracking",
          element: <InquiryList category={InquiryStatus.TRACKING} />,
        },
        {
          path: "completed",
          element: <InquiryList category={InquiryStatus.COMPLETED} />,
        },
        {
          path: "create",
          element: <CreateInquiry />,
        },
        {
          path: ":inquiryID",
          element: <MainInquiry />,
          handle: {
            crumb: (params: any, path: any) => (
              <Link
                to={`/workspace/${params.workspaceID}/inquiries/${params.inquiryID}/inquiry-details`}
              >
                inquiry {params.inquiryID}
              </Link>
            ),
          },
          children: [
            {
              path: "inquiry-details",
              element: <InquiryDetails />,
              handle: {
                crumb: (params: any, path: any) => (
                  <span>{"inquiry details"}</span>
                ),
              },
            },
            {
              path: "request-quote",
              element: <GetRate />,
              handle: {
                crumb: (params: any, path: any) => {
                  return <span>Get Rate</span>;
                },
              },
            },
            {
              path: "quotes",
              exact: true,
              element: <InquiryQuotes />,
              handle: {
                crumb: (params: any, path: any) => <span>{"Quote List"}</span>,
              },
            },
          ],
        },
        {
          path: ":inquiryID",
          handle: {
            crumb: (params: any, path: any) => {
              return (
                <Link
                  to={`/workspace/${params.workspaceID}/inquiries/${params.inquiryID}/inquiry-details`}
                >
                  inquiry {params.inquiryID}
                </Link>
              );
            },
          },
          children: [
            {
              path: "quotes",
              handle: {
                crumb: (params: any, path: any) => {
                  return (
                    <Link
                      to={`/workspace/${params.workspaceID}/inquiries/${params.inquiryID}/quotes`}
                    >
                      Quote list
                    </Link>
                  );
                },
              },
              children: [
                {
                  path: ":quoteSlug",
                  element: <MainQuotes />,
                  handle: {
                    crumb: (params: any, path: any) => {
                      return (
                        <Link
                          to={`/workspace/${params.workspaceID}/inquiries/${params.inquiryID}/quotes/${params.quoteSlug}/quote-details`}
                        >
                          {"Quote " + params.quoteSlug}
                        </Link>
                      );
                    },
                  },
                  children: [
                    {
                      path: "quote-details",
                      element: <UpdateQuote />,
                      handle: {
                        crumb: (params: any, path: any) => (
                          <span>{"Quote Details"}</span>
                        ),
                      },
                    },
                    {
                      path: "inquiry-details",
                      element: <InquiryDetails />,
                      handle: {
                        crumb: (params: any, path: any) => (
                          <span>{"Inquiry Details"}</span>
                        ),
                      },
                    },
                    {
                      path: "communication",
                      element: <Communication />,
                      handle: {
                        crumb: (params: any, path: any) => (
                          <span>{"Communication"}</span>
                        ),
                      },
                    },
                    {
                      path: "documents",
                      element: <Documents />,
                      handle: {
                        crumb: (params: any, path: any) => (
                          <span>{"Documents"}</span>
                        ),
                      },
                    },
                    {
                      path: "tracking",
                      element: <Tracking />,
                      handle: {
                        crumb: (params: any, path: any) => (
                          <span>{"Tracking"}</span>
                        ),
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "companies",
      element: <CompaniesList />,
    },
    {
      path: "companies/:companyID",
      element: <ContactList />,
    },
    {
      path: "manage-members",
      element: <ManageMembers />,
    },
    {
      path: "manage-roles",
      element: <ManageRoles />,
    },
    {
      path: "contacts",
      element: <ContactList />,
      exact: true,
    },
    {
      path: "analytics",
      element: <Analytics />,
      exact: true,
    },
    {
      path: "chats",
      element: <Chats />,
      children: [
        {
          path: ":chatId",
          element: <Chats />,
        },
      ],
    },
    {
      path: "groups",
      element: <GroupsList />,
    },
  ],
};

export default MainRoutes;
