import { Theme } from "@mui/material";

export default function TextField(theme: Theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          // "& .MuiFormHelperText-root.Mui-error": {
          //   position: "absolute",
          //   bottom: "-1.25rem",
          // },
        },
      },
    },
  };
}
