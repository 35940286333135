import { useNavigate, useParams } from "react-router";
import AppTabs from "components/Tabs/AppTabs";
import { useHasPermissions } from "api/workspace";
import { WorkspacePermissions } from "api/types";
import Members from "./Members/Members";

function ManageMembers() {
  const navigate = useNavigate();

  const { workspaceID } = useParams();
  const { hasPermission: hasRolesPermission } = useHasPermissions(
    [WorkspacePermissions.ReadRole],
    workspaceID
  );
  const { hasPermission: hasMembersPermission } = useHasPermissions(
    [WorkspacePermissions.ReadMembers],
    workspaceID
  );

  const tabs = [
    { label: "Members", value: 0, disabled: !hasMembersPermission },
    ...(hasRolesPermission
      ? [{ label: "Roles", value: 1, disabled: !hasRolesPermission }]
      : []),
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/workspace/${workspaceID}/manage-roles`);
  };

  const renderTabs = () => (
    <AppTabs
      tabs={tabs}
      value={0}
      handleChange={handleChangeTab}
      sx={{
        // tab
        "& .MuiTab-root": {
          p: "1.1rem 2.5rem",
        },
      }}
    />
  );

  if (hasMembersPermission) {
    return <Members renderTabs={renderTabs} />;
  }
  return <>You don't have permission to view this section</>;
}

export default ManageMembers;
