import {
  Box,
  MenuItem,
  Pagination,
  PaginationItem,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import AppSelect from "components/FormFields/AppSelect";

const NextPreviousButton = styled(
  "button",
  {}
)(({ theme }) => ({
  backgroundColor: "transparent",
  borderWidth: 0,
}));

type propType = {
  count: number;
  page: number;
  onPageChange: (event: any, newPage: number) => void;
  onRowsPerPageChange: (newPageSize: number) => void;
  rowsPerPage: number;
  rowsPerPageOptions: number[];
};

const AppPagination = ({
  count,
  rowsPerPageOptions,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
}: propType) => {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        position="relative"
        sx={{ textAlign: "center", marginY: 4 }}
      >
        <Box sx={{ marginX: "auto" }}>
          <Pagination
            count={Math.ceil(count / rowsPerPage)}
            onChange={(e, page) => onPageChange(e, page - 1)}
            shape="rounded"
            page={page + 1}
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: (props) => (
                    <NextPreviousButton {...props}>
                      <Typography
                        variant="s13w600"
                        alignItems="center"
                        display="flex"
                        color="primary"
                      >
                        <NavigateBeforeIcon />
                        Prev
                      </Typography>
                    </NextPreviousButton>
                  ),
                  next: (props) => (
                    <NextPreviousButton {...props}>
                      <Typography
                        variant="s13w600"
                        alignItems="center"
                        display="flex"
                        color="primary"
                      >
                        Next
                        <NavigateNextIcon />
                      </Typography>
                    </NextPreviousButton>
                  ),
                }}
                {...item}
                sx={{
                  marginX:
                    item.type === "next" || item.type === "previous"
                      ? 4
                      : "auto",
                }}
              />
            )}
          />
        </Box>

        <AppSelect<number>
          value={rowsPerPage}
          onChange={(e) => onRowsPerPageChange(+e.target.value)}
          sx={{ position: "absolute", right: "0" }}
          renderValue={(value) => (
            <Typography variant="s13w500">{value} / Page</Typography>
          )}
        >
          {rowsPerPageOptions.map((row, i) => (
            <MenuItem key={i} value={row}>
              <Typography variant="s13w500">{row}</Typography>
            </MenuItem>
          ))}
        </AppSelect>
      </Stack>
    </>
  );
};

export default AppPagination;
