import { AxiosRequestConfig } from "axios";
import api from "../axios";
import { IPaginateResponse } from "../types";
import {
  CompanyCreate,
  CompanyResponse,
  ContactCreate,
  ContactResponse,
  CreateMember,
  CreateRole,
  EmailTemplateResponse,
  GroupCreate,
  GroupResponse,
  JoinWorkspaceData,
  JoinWorkspaceResponse,
  Member,
  MembershipDetails,
  OldUserJoinWorkspaceData,
  Permission,
  Role,
  UpdateMember,
  WorkspaceResponse,
  WorkspaceUpdate,
} from "./workspace.types";

export const getUserWorkspaces = async (config?: AxiosRequestConfig) => {
  const res = await api.get<WorkspaceResponse[]>("/workspaces/", config);
  return res.data;
};
export const getWorkspace = async (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const res = await api.get<WorkspaceResponse>(
    `/workspaces/${workspaceID}/`,
    config
  );
  return res.data;
};
export const getWorkspaceMembers = async (workspaceID: string | undefined) => {
  const res = await api.get<Member[]>(
    `/workspaces/${workspaceID}/memberships/`
  );
  return res.data;
};

export const oldUserJoinToWorkspace = async (
  workspaceID: string | undefined,
  data: OldUserJoinWorkspaceData,
  config?: AxiosRequestConfig
) => {
  const res = await api.post<OldUserJoinWorkspaceData>(
    `/workspaces/${workspaceID}/memberships/join_old_user/`,
    data,
    config
  );
  return res.data;
};

export const newUserJoinToWorkspace = async (
  workspaceID: string | undefined,
  data: Required<JoinWorkspaceData>,
  config?: AxiosRequestConfig
) => {
  const res = await api.post<JoinWorkspaceResponse>(
    `/workspaces/${workspaceID}/memberships/join_new_user/`,
    data,
    config
  );
  return res.data;
};

export const getUserMembership = async (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const res = await api.get<MembershipDetails>(
    `/workspaces/${workspaceID}/user_membership/`,
    config
  );
  return res.data;
};

//#region Companies
export const getCompanies = async (
  workspaceID: string | undefined,

  config?: AxiosRequestConfig
) => {
  const res = await api.get<IPaginateResponse<CompanyResponse>>(
    `/workspaces/${workspaceID}/companies/`,
    config
  );
  return res.data;
};

export const createCompany = async (
  workspaceID: string | undefined,
  data: CompanyCreate,
  config?: AxiosRequestConfig
) => {
  const res = await api.post<CompanyResponse>(
    `/workspaces/${workspaceID}/companies/`,
    data,
    config
  );
  return res.data;
};
export const updateCompany = async (
  workspaceID: string | undefined,
  id: string | number,
  data: CompanyCreate,
  config?: AxiosRequestConfig
) => {
  const res = await api.put<CompanyResponse>(
    `/workspaces/${workspaceID}/companies/${id}/`,
    data,
    config
  );
  return res.data;
};

export const deleteCompany = async (
  workspaceID: string | undefined,
  id: string | number,
  config?: AxiosRequestConfig
) => {
  const res = await api.delete(
    `/workspaces/${workspaceID}/companies/${id}/`,
    config
  );
  return res;
};

//#endregion Companies

//#region contacts
export const getContactList = async (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const res = await api.get<IPaginateResponse<ContactResponse>>(
    `/workspaces/${workspaceID}/contacts/`,
    config
  );
  return res.data;
};

export const getContact = async (
  workspaceID: string | undefined,
  slug: string,
  config?: AxiosRequestConfig
) => {
  const res = await api.get<ContactResponse>(
    `/workspaces/${workspaceID}/contacts/${slug}/`,
    config
  );
  return res.data;
};

export const createContact = async (
  workspaceID: string | undefined,
  data: ContactCreate,
  config?: AxiosRequestConfig
) => {
  const res = await api.post<ContactResponse>(
    `/workspaces/${workspaceID}/contacts/`,
    data,
    config
  );
  return res.data;
};

export const updateContact = async (
  workspaceID: string | undefined,
  id: number | string,
  data: ContactCreate,
  config?: AxiosRequestConfig
) => {
  const res = await api.put<ContactResponse>(
    `/workspaces/${workspaceID}/contacts/${id}/`,
    data,
    config
  );
  return res.data;
};

export const deleteContact = async (
  workspaceID: string | undefined,
  id: string | number,
  config?: AxiosRequestConfig
) => {
  const res = await api.delete(
    `/workspaces/${workspaceID}/contacts/${id}/`,
    config
  );
  return res.data;
};
//#end region contacts

//#region Group
export const getGroups = async (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const res = await api.get<IPaginateResponse<GroupResponse>>(
    `/workspaces/${workspaceID}/contact-group/`,
    config
  );
  return res.data;
};

export const getGroupMembers = async (
  workspaceID: string | undefined,
  groupSlug: string,
  config?: AxiosRequestConfig
) => {
  const res = await api.get<Pick<GroupResponse, "cc_details" | "to_details">>(
    `/workspaces/${workspaceID}/contact-group/${groupSlug}/members/`,
    config
  );
  return res.data;
};

export const createGroup = async (
  workspaceID: string | undefined,
  data: GroupCreate,
  config?: AxiosRequestConfig
) => {
  const res = await api.post<GroupResponse>(
    `/workspaces/${workspaceID}/contact-group/`,
    data,
    config
  );
  return res.data;
};
export const updateGroup = async (
  workspaceID: string | undefined,
  slug: string | number,
  data: GroupCreate,
  config?: AxiosRequestConfig
) => {
  const res = await api.put<GroupResponse>(
    `/workspaces/${workspaceID}/contact-group/${slug}/`,
    data,
    config
  );
  return res.data;
};

export const deleteGroup = async (
  workspaceID: string | undefined,
  slug: string | number,
  config?: AxiosRequestConfig
) => {
  const res = await api.delete(
    `/workspaces/${workspaceID}/contact-group/${slug}/`,
    config
  );
  return res;
};

//#end region Group

//#region EmailTemplate
export const getEmailTemplateList = async (
  workspaceID: string | undefined,
  queryString?: string | undefined,
  config?: AxiosRequestConfig<any>
) => {
  const res = await api.get<EmailTemplateResponse[]>(
    `/workspaces/${workspaceID}/email-templates/${queryString}`,
    config
  );
  return res.data;
};

export const getEmailTemplate = async (workspaceID: string, id: string) => {
  const res = await api.get<EmailTemplateResponse>(
    `/workspaces/${workspaceID}/email-templates/${id}/`
  );
  return res.data;
};

export const createEmailTemplate = async (
  workspaceID: string,
  data: Omit<EmailTemplateResponse, "id">
) => {
  const res = await api.post<EmailTemplateResponse>(
    `/workspaces/${workspaceID}/email-templates/`,
    data
  );
  return res.data;
};

export const updateWorkspace = async (
  workspaceID: string | undefined,
  data: WorkspaceUpdate
) => {
  return (await api.put<WorkspaceResponse>(`/workspaces/${workspaceID}/`, data))
    .data;
};

export const updateEmailTemplate = async (
  workspaceID: string,
  templateId: string,
  data: Omit<EmailTemplateResponse, "id">
) => {
  const res = await api.put<EmailTemplateResponse>(
    `/workspaces/${workspaceID}/email-templates/${templateId}/`,
    data
  );
  return res.data;
};
export const getPermissions = async () => {
  return (await api.get<Permission[]>(`/workspaces/permissions/`)).data;
};
export const deleteEmailTemplate = async (
  workspaceID: string,
  templateId: string | number
) => {
  const res = await api.delete(
    `/workspaces/${workspaceID}/email-templates/${templateId}/`
  );
  return res.data;
};

//#endregion EmailTemplate

export const getRoles = async (workspaceID: string | undefined) => {
  return (await api.get<Role[]>(`/workspaces/${workspaceID}/roles/`)).data;
};

export const createRole = async (data: {
  workspaceID: string;
  role: CreateRole;
}) => {
  return (
    await api.post<Role>(`/workspaces/${data.workspaceID}/roles/`, data.role)
  ).data;
};

export const updateRole = async (data: {
  workspaceID: string;
  id: number;
  role: CreateRole;
}) => {
  return (
    await api.put<Role>(
      `/workspaces/${data.workspaceID}/roles/${data.id}/`,
      data.role
    )
  ).data;
};

export const deleteRole = async (
  workspaceID: string | undefined,
  id: string | number,
  config?: AxiosRequestConfig
) => {
  const res = await api.delete(
    `/workspaces/${workspaceID}/roles/${id}/`,
    config
  );
  return res;
};

export const createMember = async (data: {
  workspaceID: string;
  member: CreateMember;
}) => {
  const res = await api.post<Member>(
    `/workspaces/${data.workspaceID}/memberships/`,
    data.member
  );
  return res.data;
};
export const updateMember = async (data: {
  workspaceID: string;
  id: number;
  member: UpdateMember;
}) => {
  return (
    await api.put<Member>(
      `/workspaces/${data.workspaceID}/memberships/${data.id}/`,
      data.member
    )
  ).data;
};

export const getMembers = async (workspaceID: string | undefined) => {
  try {
    const res = await api.get<IPaginateResponse<Member>>(
      `/workspaces/${workspaceID}/memberships/`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
