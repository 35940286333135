import { useMemo } from "react";
import PropTypes from "prop-types";

// material-ui
import { Box } from "@mui/material";

// project import
import { drawerWidth } from "config";
import DrawerHeader from "./DrawerHeader";
import DrawerContent from "./DrawerContent";
import MiniDrawerStyled from "./MiniDrawerStyled";

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const MainDrawer = () => {
  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader />, []);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 9999 }}
      aria-label="mailbox folders"
    >
      <MiniDrawerStyled
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#1D1F2B",
            padding: "2.6rem 0 0",
          },
        }}
        variant="permanent"
      >
        {drawerHeader}
        {drawerContent}
      </MiniDrawerStyled>
    </Box>
  );
};

MainDrawer.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default MainDrawer;
