import {
  DEFAULT_ROLE_KEY,
  PERMISSION_MODEL_NAMES,
  PermissionAccessLevelEnum,
  Role,
  filterPermissionsInRoleByModelName,
} from "api/workspace";
import { uniq } from "lodash";

export const getPermissionRenderData = (
  role: Role | undefined
): { name: string; accessLevelDisplayName: string }[] | undefined => {
  if (!role) {
    return undefined;
  }
  let data: { name: string; accessLevelDisplayName: string }[] = [];
  let models = role.permissions_details.map(
    (e) => e.permission_details.resource_name
  );
  models = uniq(models);
  models.forEach((e) => {
    const model = e as PERMISSION_MODEL_NAMES;
    const name =
      filterPermissionsInRoleByModelName(role, model)?.permission_details
        .display_name ?? e;
    const accessLevel = filterPermissionsInRoleByModelName(
      role,
      model
    )?.access_level;
    const accessLevelDisplayName =
      accessLevel === PermissionAccessLevelEnum.ReadWrite
        ? "R+W"
        : accessLevel === PermissionAccessLevelEnum.Read
        ? "R"
        : "";
    data.push({ name, accessLevelDisplayName });
  });

  let defaultPermissions = [
    "Own Inquiries",
    "Own Shipments",
    "Companies",
    "Contacts",
  ].map((e) => ({ name: e, accessLevelDisplayName: "R+W" }));
  data.push(...defaultPermissions);
  return data;
};

export const getDefaultRolesPermissionRenderData = (
  role: DEFAULT_ROLE_KEY | undefined
): { name: string; accessLevelDisplayName: string }[] | null => {
  if (!role) {
    return null;
  }
  let data: { name: string; accessLevelDisplayName: string }[] = [];
  switch (role) {
    case "ADMIN":
      [
        "Team Inquiry",
        "Team Quotes & Shipment",
        "Member Management",
        "Role Management",
      ].forEach((e) => data.push({ name: e, accessLevelDisplayName: "R+W" }));
      break;
    case "TEAM_MEMBER":
      ["Team Inquiry", "Team Quotes & Shipment"].forEach((e) =>
        data.push({
          name: e,
          accessLevelDisplayName: "R+W",
        })
      );
      break;
  }

  let defaultPermissions = [
    "Own Inquiries",
    "Own Quotes & Shipment",
    "Companies",
    "Contacts",
  ].map((e) => ({ name: e, accessLevelDisplayName: "R+W" }));
  data.push(...defaultPermissions);
  return data;
};
