function ChatsIcon(isActive = true) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 5.62484C17.9323 5.62484 18.9583 4.59882 18.9583 3.33317C18.9583 2.06752 17.9323 1.0415 16.6667 1.0415C15.401 1.0415 14.375 2.06752 14.375 3.33317C14.375 4.59882 15.401 5.62484 16.6667 5.62484Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        opacity="0.4"
        d="M16.666 6.6665C14.8243 6.6665 13.3327 5.17484 13.3327 3.33317C13.3327 2.72484 13.5077 2.15817 13.7993 1.6665H5.83268C3.53268 1.6665 1.66602 3.52484 1.66602 5.8165V10.7998V11.6332C1.66602 13.9248 3.53268 15.7832 5.83268 15.7832H7.08268C7.30768 15.7832 7.60768 15.9332 7.74935 16.1165L8.99935 17.7748C9.54935 18.5082 10.4493 18.5082 10.9993 17.7748L12.2493 16.1165C12.4077 15.9082 12.6577 15.7832 12.916 15.7832H14.166C16.466 15.7832 18.3327 13.9248 18.3327 11.6332V6.19984C17.841 6.4915 17.2743 6.6665 16.666 6.6665Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M9.99935 10.0002C9.53268 10.0002 9.16602 9.62516 9.16602 9.16683C9.16602 8.7085 9.54102 8.3335 9.99935 8.3335C10.4577 8.3335 10.8327 8.7085 10.8327 9.16683C10.8327 9.62516 10.466 10.0002 9.99935 10.0002Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M13.3333 10.0002C12.8667 10.0002 12.5 9.62516 12.5 9.16683C12.5 8.7085 12.875 8.3335 13.3333 8.3335C13.7917 8.3335 14.1667 8.7085 14.1667 9.16683C14.1667 9.62516 13.8 10.0002 13.3333 10.0002Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M6.66732 10.0002C6.20065 10.0002 5.83398 9.62516 5.83398 9.16683C5.83398 8.7085 6.20898 8.3335 6.66732 8.3335C7.12565 8.3335 7.50065 8.7085 7.50065 9.16683C7.50065 9.62516 7.13398 10.0002 6.66732 10.0002Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
    </svg>
  );
}

export default ChatsIcon;
