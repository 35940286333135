import PropTypes from "prop-types";

// material-ui
import { Box, List } from "@mui/material";

// project import
import NavItem from "./NavItem";

const NavGroup = ({ item, open, activeItem, setActiveItem }) => {
  const navCollapse = item.children?.map((menuItem, index) => (
    <Box key={menuItem.id}>
      <NavItem
        key={menuItem.id}
        item={menuItem}
        level={1}
        activeItem={activeItem}
        setActiveItem={setActiveItem}
      />
    </Box>
  ));

  return <List sx={{ mb: 0, py: 0, zIndex: 0 }}>{navCollapse}</List>;
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
