import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { updateUserPassword, getUserInfo, updateUserInfo } from "./auth.api";
import { PasswordChangeData, UserInfoUpdate } from "./auth.type";

export const useQueryUserInfo = (config?: AxiosRequestConfig) => {
  return useQuery(["user-info"], () => getUserInfo(config));
};

export const useMutationUserInfo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { userInfo: UserInfoUpdate }) =>
      updateUserInfo(data.userInfo),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["user-info"],
      });
    },
  });
};

export const useMutationUserPassword = () => {
  return useMutation({
    mutationFn: (data: { passwordData: PasswordChangeData }) =>
      updateUserPassword(data.passwordData),
  });
};
