import {
  Avatar,
  Box,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import UnreadNumberBadge from "components/Badges/UnreadNumberBadge";
import { ArrowRight2 } from "iconsax-react";
import { timeConvert } from "utils/date";

const avatarColors = [
  "#0078D7",
  "#FFB900",
  "#008272",
  "#a11491",
  "#00CED1",
  "#7B68EE",
  "#FFCC0F",
  "#107C10",
  "#40E0D0",
  "#B4009E",
  "#1068D7",
  "#3CB371",
  "#9A32CD",
  "#FF6B6B",
  "#50B4FF",
  "#D83B01",
  "#32CD32",
];

function ChatPreview({
  avatar,
  id,
  title,
  originCountryCode,
  destinationCountryCode,
  rfqDate,
  time,
  subtitle,
  unseenNumber,
  senderName,
  isActive = false,
  isExpanded,
  handleClick,
}: {
  avatar: string;
  id: string;
  title: string;
  originCountryCode: string | null;
  destinationCountryCode: string | null;
  rfqDate: string | null;
  time: string | null;
  subtitle: string;
  unseenNumber: number;
  senderName: string | null;
  isActive?: boolean;
  isExpanded?: boolean;
  handleClick: (id: string) => void;
}) {
  const theme = useTheme();

  function getStringAsciiValue(str: string) {
    let total = 0;
    for (let i = 0; i < str.length; i++) {
      total = total + str.charCodeAt(i);
    }
    return total;
  }

  const convertedTime = timeConvert(time);

  return (
    <Box
      position={"relative"}
      display={"flex"}
      justifyContent={"space-between"}
      width={isExpanded ? "16.7rem" : "11.4rem"}
      maxHeight={"6rem"}
      p={"0.75rem"}
      pr={2}
      borderRadius={"1.5rem"}
      onClick={() => {
        handleClick(id);
      }}
      sx={{
        cursor: "pointer",
        background: isActive ? theme.gradient.primary.light : "unset",
        transition: "ease width 0.3s",
      }}
    >
      <Box display={"flex"} alignItems={"start"} width={"100%"}>
        <Box width={"fit-content"} mr={"10px"} position={"relative"}>
          {!!unseenNumber && (
            <Box position={"absolute"} zIndex={99} right={"-4px"} top={"-4px"}>
              <UnreadNumberBadge number={unseenNumber} />
            </Box>
          )}
          <Avatar
            sx={{
              bgcolor:
                avatarColors[getStringAsciiValue(title) % avatarColors.length],
              width: "2.25rem",
              height: "2.25rem",
            }}
          >
            {title.charAt(0).toUpperCase()}
          </Avatar>
        </Box>
        <Stack width={"100%"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Tooltip
              title={title.length > 14 ? title : null}
              placement="top"
              PopperProps={{ style: { zIndex: 2000 } }}
            >
              <Typography variant="s12w500" maxWidth={"6rem"} noWrap>
                {title}
              </Typography>
            </Tooltip>
            <Box
              display={"flex"}
              alignItems={"end"}
              justifyContent={"space-between"}
              maxWidth={"100%"}
              width={isExpanded ? "auto" : 0}
              overflow={"hidden"}
              sx={{
                transition: "ease width 0.3s",
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                {originCountryCode && (
                  <img
                    loading="lazy"
                    width="20"
                    height="14"
                    src={`https://flagcdn.com/w20/${originCountryCode.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${originCountryCode.toLowerCase()}.png 2x`}
                    alt=""
                    style={{ borderRadius: "4px" }}
                  />
                )}
                <ArrowRight2 size={12} color={theme.palette.primary.main} />
                {destinationCountryCode && (
                  <img
                    loading="lazy"
                    width="20"
                    height="14"
                    src={`https://flagcdn.com/w20/${destinationCountryCode.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${destinationCountryCode.toLowerCase()}.png 2x`}
                    alt=""
                    style={{ borderRadius: "4px" }}
                  />
                )}
              </Box>
            </Box>
          </Box>

          {senderName && (
            <Box display={"flex"} alignItems={"center"} gap={"6px"}>
              <Box display={"flex"} alignItems={"center"}>
                <Box
                  maxWidth={"3rem"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                >
                  <Tooltip
                    title={senderName.length > 5 ? senderName : null}
                    placement="top"
                    PopperProps={{ style: { zIndex: 2000 } }}
                  >
                    <Typography
                      variant="s12w400"
                      color="dim-grey"
                      maxHeight={"1rem"}
                      noWrap
                      sx={{
                        "& p, div": {
                          mt: 0,
                        },
                      }}
                    >
                      {senderName}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
              {time && (
                <Typography
                  variant="s11w400"
                  color="cool-grey"
                  minWidth={"fit-content"}
                  noWrap
                >
                  {convertedTime}
                </Typography>
              )}
            </Box>
          )}
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={"0.75rem"}
          >
            <Typography variant="s11w500" color="primary">
              #{subtitle.toUpperCase()}
            </Typography>
            <Typography
              variant="s11w400"
              color="dim-grey"
              width={isExpanded ? "auto" : 0}
              height={isExpanded ? "auto" : 0}
              overflow={"hidden"}
              alignItems={"end"}
              sx={{
                transition: "ease width 0.3s",
              }}
            >
              {rfqDate && ` RFQ: ${timeConvert(rfqDate)}`}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}

export default ChatPreview;
