import { Box, List, Typography, useTheme } from "@mui/material";
import AppPopover from "components/Popover/AppPopover";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import PointedListItem from "./PointedListItem";

function PointedSelect({
  handleClick,
  handleClose,
  handleSelect,
  anchorEl,
  open,
  setAnchorEl,
  items,
  selectedItem,
  selectedLabel,
  hasInfo,
  activeInfoItem,
  onClickInfo,
  disabled,
  loading,
  popoverPosition,
  backdropColor,
}: {
  handleClick: any;
  handleClose: any;
  handleSelect: any;
  anchorEl: any;
  open: any;
  setAnchorEl: any;
  items: any;
  selectedItem: any;
  selectedLabel: string | null;
  activeInfoItem?: any;
  hasInfo?: boolean;
  onClickInfo?: any;
  disabled?: boolean;
  loading?: boolean;
  popoverPosition?: "right" | "left";
  backdropColor?: string;
}) {
  const theme = useTheme();
  return (
    <>
      <Box
        onClick={disabled || loading ? () => {} : handleClick}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={1}
        border={"1px solid"}
        borderColor={"platinum"}
        borderRadius={theme.customRadius}
        p={"0.65rem 1rem"}
        sx={{
          cursor: disabled ? "not-allowed" : loading ? "progress" : "pointer",
        }}
      >
        {selectedLabel && (
          <Typography
            variant="s13w500"
            color={disabled || loading ? "cool-grey" : "space-cadet"}
          >
            {selectedLabel}
          </Typography>
        )}
        {anchorEl ? (
          <ArrowUp2 size={12} color={theme.palette["space-cadet"]} />
        ) : (
          <ArrowDown2 size={12} color={theme.palette["space-cadet"]} />
        )}
      </Box>
      <AppPopover
        handleClose={handleClose}
        open={open}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        backdropColor={backdropColor}
        popoverProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: popoverPosition || "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: popoverPosition || "right",
          },
          sx: { "& .MuiPaper-root": { minWidth: "14rem" }, marginTop: 1 },
        }}
      >
        <List>
          {items.map((item: any) => (
            <PointedListItem
              id={item.key}
              title={item.label}
              selected={selectedItem.key === item.key}
              activeInfo={activeInfoItem === item.key}
              key={item.key}
              onClick={() => handleSelect(item)}
              hasInfo={hasInfo}
              onClickInfo={onClickInfo}
            />
          ))}
        </List>
      </AppPopover>
    </>
  );
}

export default PointedSelect;
