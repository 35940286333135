import { AxiosProxyConfig } from "axios";
import { PermissionAccessLevelEnum } from "api/workspace";

export interface IPaginateResponse<T> {
  count: number;
  results: Array<T>;
}

export interface IMutationCreate<T> {
  workspaceID: string | undefined;
  data: T;
  config?: AxiosProxyConfig;
}
export interface IMutationUpdate<T> {
  workspaceID: string | undefined;
  data: T;
  config?: AxiosProxyConfig;
  id: number | string;
}

export interface IMutationDelete {
  workspaceID: string | undefined;
  config?: AxiosProxyConfig;
  id: number | string;
}

export const WorkspacePermissions = {
  ReadWorkspace: {
    resource_name: "workspace",
    access_level: PermissionAccessLevelEnum.Read,
  },
  ReadWriteWorkspace: {
    resource_name: "workspace",
    access_level: PermissionAccessLevelEnum.ReadWrite,
  },
  ReadMembers: {
    resource_name: "membership",
    access_level: PermissionAccessLevelEnum.Read,
  },
  ReadWriteMembers: {
    resource_name: "membership",
    access_level: PermissionAccessLevelEnum.ReadWrite,
  },
  ReadQuote: {
    resource_name: "quote",
    access_level: PermissionAccessLevelEnum.Read,
  },
  ReadWriteQuote: {
    resource_name: "quote",
    access_level: PermissionAccessLevelEnum.ReadWrite,
  },
  ReadShipment: {
    resource_name: "shipment",
    access_level: PermissionAccessLevelEnum.Read,
  },
  ReadWriteShipment: {
    resource_name: "shipment",
    access_level: PermissionAccessLevelEnum.ReadWrite,
  },
  ReadDocument: {
    resource_name: "document",
    access_level: PermissionAccessLevelEnum.Read,
  },
  ReadWriteDocument: {
    resource_name: "document",
    access_level: PermissionAccessLevelEnum.ReadWrite,
  },
  ReadRole: {
    resource_name: "role",
    access_level: PermissionAccessLevelEnum.Read,
  },
  ReadWriteRole: {
    resource_name: "role",
    access_level: PermissionAccessLevelEnum.ReadWrite,
  },
  ReadInquiry: {
    resource_name: "inquiry",
    access_level: PermissionAccessLevelEnum.Read,
  },
  ReadWriteInquiry: {
    resource_name: "inquiry",
    access_level: PermissionAccessLevelEnum.ReadWrite,
  },
};

export type ServerError<T extends object> = {
  [K in keyof T]: string[];
} & { non_field_errors?: string[] } & { details: string[] | string };
