import { Theme } from "@mui/material";

export default function ListItemButton(theme: Theme) {
  return {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingRight: "1.75rem",
          paddingLeft: "1.75rem",
          "&:hover": {
            backgroundColor: theme.palette.primary[5],
          },
        },
      },
    },
  };
}
