import { AxiosRequestConfig } from "axios";
import api from "../axios";
import { IPaginateResponse } from "../types";
import {
  AISuggestionField,
  Carrier,
  ChangeQuoteStatus,
  QuoteAISuggestionsItem,
  QuoteCreate,
  QuoteResponse,
  SailingInfoCreate,
  TotalUnseenResponse,
} from "./shipment.type";
import { AttachmentDetail, EmailResponse } from "api/email/email.type";

export const getCarrierList = async (config?: AxiosRequestConfig) => {
  const res = await api.get<IPaginateResponse<Carrier>>(
    "/shipments/carriers/",
    config
  );
  return res.data;
};

export const getCarrier = async (id: number, config?: AxiosRequestConfig) => {
  const res = await api.get<Carrier>(`/shipments/carriers/${id}/`, config);
  return res.data;
};

export const uploadDocument = async (
  workspaceID: string | undefined,
  data: any,
  config?: AxiosRequestConfig
) => {
  const res: any = await api.post(
    `shipments/${workspaceID}/documents/`,
    data,
    config
  );
  return res.data;
};

export const updateShipmentSailingInfo = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  data: SailingInfoCreate,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const res = await api.post<QuoteResponse>(
    `/shipments/${workspaceID}/quotes/${slug}/update_sailing_info/`,
    data,
    config
  );
  return res.data;
};

//#region Quote
export const getForwarderQuoteDetails = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  config?: AxiosRequestConfig
) => {
  const res = await api.get<QuoteResponse>(
    `shipments/${workspaceID}/forwarder-quotes/${slug}/`,
    config
  );
  return res.data;
};

export const getQuoteList = async (
  workspaceID: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/shipments/${workspaceID}/quotes/`
    : `/shipments/${workspaceID}/user-quotes/`;
  const res = await api.get<IPaginateResponse<QuoteResponse>>(url, config);
  return res.data;
};

export const getQuote = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/shipments/${workspaceID}/quotes/${slug}/`
    : `/shipments/${workspaceID}/user-quotes/${slug}/`;
  const res = await api.get<QuoteResponse>(url, config);
  return res.data;
};

export const getQuoteConversions = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/shipments/${workspaceID}/quotes/${slug}/conversions/`
    : `/shipments/${workspaceID}/user-quotes/${slug}/conversions/`;
  const res = await api.get<EmailResponse[]>(url, config);
  return res.data;
};

export const getQuoteTotalUnreadMessages = async (
  workspaceID: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/shipments/${workspaceID}/quotes/total_unseen/`
    : `/shipments/${workspaceID}/user-quotes/total_unseen/`;
  const res = await api.get<TotalUnseenResponse>(url, config);
  return res.data;
};

export const seenQuoteConversation = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const res = await api.put<EmailResponse>(
    `shipments/${workspaceID}/${
      teamAccess ? "quotes" : "user-quotes"
    }/${slug}/conversions/seen/`,
    config
  );
  return res.data;
};

export const getQuoteAttachments = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/shipments/${workspaceID}/quotes/${slug}/documents/`
    : `/shipments/${workspaceID}/user-quotes/${slug}/documents/`;
  const res = await api.get<IPaginateResponse<AttachmentDetail>>(url, config);
  return res.data;
};

export const updateForwarderQuote = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  data: QuoteCreate,
  config?: AxiosRequestConfig
) => {
  const res = await api.put<QuoteResponse>(
    `shipments/${workspaceID}/forwarder-quotes/${slug}/`,
    data,
    config
  );
  return res.data;
};

export const changeQuoteStatus = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  teamAccess = false,
  data: ChangeQuoteStatus,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `shipments/${workspaceID}/quotes/${slug}/change_status/`
    : `shipments/${workspaceID}/user-quotes/${slug}/change_status/`;

  const res = await api.post<QuoteResponse>(url, data, config);
  return res.data;
};

export const quoteSendMessage = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  data: { html_message: string },
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `shipments/${workspaceID}/quotes/${slug}/send_message/`
    : `shipments/${workspaceID}/user-quotes/${slug}/send_message/`;
  const res = await api.post<EmailResponse>(url, data, config);
  return res.data;
};

export const archiveQuote = async (
  slug: string | number,
  workspaceID: any,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/shipments/${workspaceID}/quotes/${slug}/archive/`
    : `/shipments/${workspaceID}/user-quotes/${slug}/archive/`;
  const res = await api.post(url, config);
  return res.data;
};

export const getQuoteAISuggestions = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/shipments/${workspaceID}/quotes/${slug}/ai/`
    : `/shipments/${workspaceID}/user-quotes/${slug}/ai/`;
  const res = await api.get<QuoteAISuggestionsItem[]>(url, config);
  return res.data;
};

export const acceptQuoteAISuggestions = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  suggestionId: number | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `shipments/${workspaceID}/quotes/${slug}/ai/${suggestionId}/accept/`
    : `shipments/${workspaceID}/user-quotes/${slug}/ai/${suggestionId}/accept/`;

  const res = await api.put<QuoteAISuggestionsItem>(url, config);
  return res.data;
};

export const declineQuoteAISuggestions = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  suggestionId: number | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `shipments/${workspaceID}/quotes/${slug}/ai/${suggestionId}/decline/`
    : `shipments/${workspaceID}/user-quotes/${slug}/ai/${suggestionId}/decline/`;

  const res = await api.put<QuoteAISuggestionsItem>(url, config);
  return res.data;
};

export const editQuoteAISuggestions = async (
  workspaceID: string | undefined,
  slug: string | undefined,
  suggestionId: number | undefined,
  data: { [key: string]: string },
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `shipments/${workspaceID}/quotes/${slug}/ai/${suggestionId}/edit/`
    : `shipments/${workspaceID}/user-quotes/${slug}/ai/${suggestionId}/edit/`;

  const res = await api.put<QuoteAISuggestionsItem>(url, data, config);
  return res.data;
};
