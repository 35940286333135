import React, { useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Edit2,
  Layer,
  LogoutCurve,
  Profile2User,
  UserTick,
} from "iconsax-react";
import { dispatch } from "store";
import { logout } from "store/reducers/auth";
import ProfileEmailAvatarListItem from "./ProfileEmailAvatarListItem";
import { profilePopoverProps } from "./profile.type";
import ProfileDivider from "./ProfileDivider";
import { WorkspacePermissions } from "api/types";
import { useHasPermissions } from "api/workspace";

type profileItemPropType = {
  listItemProps?: ListItemProps<any>;
  text: string;
  icon: (size: number, color: string) => React.ReactNode;
  color?: string;
};

const ProfileItem = ({
  listItemProps,
  text,
  icon,
  color,
}: profileItemPropType) => {
  const theme = useTheme();

  return (
    <ListItem
      {...listItemProps}
      sx={{
        ...listItemProps?.sx,
      }}
    >
      <ListItemIcon color={color ?? "space-cadet"}>
        {icon(16, theme.palette["space-cadet"])}
      </ListItemIcon>
      <ListItemText>
        <Typography variant="s13w400" color={color ?? "space-cadet"}>
          {text}
        </Typography>
      </ListItemText>
    </ListItem>
  );
};

type propType = profilePopoverProps & {
  setOpenWorkspaceSwitch: React.Dispatch<React.SetStateAction<boolean>>;
  setHeight: React.Dispatch<React.SetStateAction<string | number>>;
};

const UserData = ({
  handleClose,
  setOpenWorkspaceSwitch,
  setHeight,
}: propType) => {
  const theme = useTheme();
  const handleLogout = async () => {
    // logout
    dispatch(logout());
  };

  const { workspaceID } = useParams();
  const { hasPermission: hasRolesPermission } = useHasPermissions(
    [WorkspacePermissions.ReadRole],
    workspaceID
  );
  const { hasPermission: hasMembersPermission } = useHasPermissions(
    [WorkspacePermissions.ReadMembers],
    workspaceID
  );

  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setHeight(listRef.current?.scrollHeight || "auto");
  }, [listRef]);

  return (
    <List ref={listRef}>
      <ListItem>
        <ProfileEmailAvatarListItem />
      </ListItem>

      <ProfileDivider />

      <Box m={"1.75rem 0 0.9rem 1.75rem"}>
        <Typography variant="s12w400" color="dim-grey">
          Workspace-Level Settings
        </Typography>
      </Box>

      <ProfileItem
        text="Switch Workspace"
        icon={(size, color) => <Layer size={size} color={color} />}
        listItemProps={{
          button: true,
          onClick: () => setOpenWorkspaceSwitch(true),
        }}
      />

      {hasMembersPermission && (
        <ProfileItem
          listItemProps={{
            component: Link,
            to: "manage-members",
            button: true,
            onClick: handleClose,
          }}
          text="Manage Members"
          icon={(size, color) => <Profile2User size={size} color={color} />}
        />
      )}

      {hasRolesPermission && (
        <ProfileItem
          listItemProps={{
            component: Link,
            to: "manage-roles",
            button: true,
            onClick: handleClose,
          }}
          text="Manage Roles"
          icon={(size, color) => <UserTick size={size} color={color} />}
        />
      )}

      <Box mt={2}>
        <ProfileDivider />
      </Box>

      <Box m={"1.75rem 0 0.9rem 1.75rem"}>
        <Typography variant="s12w400" color="dim-grey">
          User-Level Settings
        </Typography>
      </Box>

      <ProfileItem
        text="Edit Profile"
        icon={(size, color) => <Edit2 size={size} color={color} />}
        listItemProps={{
          component: Link,
          to: "profile",
          button: true,
          onClick: handleClose,
        }}
      />

      <Box mt={2}>
        <ProfileDivider />
      </Box>

      <ProfileItem
        text="Logout"
        color="error"
        icon={(size, color) => (
          <LogoutCurve size={size} color={theme.palette.error.main} />
        )}
        listItemProps={{
          button: true,
          onClick: handleLogout,
        }}
      />
    </List>
  );
};

export default UserData;
