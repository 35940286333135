import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useParams } from "react-router";
import { Box, Button, Grid, InputLabel } from "@mui/material";
import AnimateButton from "components/@extended/AnimateButton";
import { CreateMember, useCreateMember } from "api/workspace";
import FormikTextField from "components/FormFields/FormikTextField";
import MemberRoleSelectField from "./MemberRoleSelectField";
import useAlert from "hooks/useAlert";

type Props = {
  onSuccess?: () => void;
};

function AddMemberForm({ onSuccess }: Props) {
  const { workspaceID } = useParams();
  const { isLoading, mutate } = useCreateMember();
  const { showAlert } = useAlert();

  const handleSubmit = (
    values: CreateMember,
    { setErrors, setSubmitting }: any
  ) => {
    mutate(
      {
        workspaceID: workspaceID ?? "",
        member: {
          ...values,
          role: values.role ? +values.role : null,
        },
      },
      {
        onSuccess: () => {
          onSuccess?.();
        },
        onError(error: any) {
          showAlert({ message: error, severity: "error" });
          setErrors(error.response.data);
        },
      }
    );
  };

  return (
    <Formik<CreateMember>
      initialValues={{
        email: "",
        role: "",
        is_admin: false,
        is_owner: false,
        is_team_member: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email().required("email is required"),
        role: Yup.string().nullable(),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" pt={1}>
                <InputLabel
                  htmlFor="name"
                  sx={{
                    width: "9rem",
                  }}
                >
                  Email
                </InputLabel>
                <FormikTextField name="email" fullWidth />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" pt={1}>
                <InputLabel
                  htmlFor="name"
                  sx={{
                    width: "9rem",
                  }}
                >
                  Role
                </InputLabel>
                <MemberRoleSelectField />
              </Box>
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"} mt={3}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isLoading}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: "10.25rem" }}
                >
                  Add
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default AddMemberForm;
