// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

import { Theme } from "@mui/material";

export default function ListItem(theme: Theme) {
  return {
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingRight: "1.75rem",
          paddingLeft: "1.75rem",
        },
        button: {
          "&.MuiListItem-button": {
            "&:hover": {
              backgroundColor: theme.palette.primary[5],
            },
          },
        },
      },
    },
  };
}
