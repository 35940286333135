import React from "react";
import { Tabs, Tab, styled, SxProps } from "@mui/material";

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  minHeight: "0",
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "center",
    gap: "0.375rem",
  },
}));

export const CustomTab = styled(Tab)(({ theme }) => ({
  minWidth: 0,
  minHeight: "0",
  height: "1.8rem",
  width: "4rem",
  padding: "0",
  textTransform: "none",
  fontWeight: theme.typography.s11w500.fontWeight,
  fontSize: theme.typography.s11w500.fontSize,
  color: theme.palette.text.secondary,
  border: "0.75px solid",
  borderColor: theme.palette.platinum,
  borderRadius: "0.75rem",
  "&:hover": {
    color: theme.palette.text.primary,
    opacity: 1,
  },
  "&.Mui-selected": {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.s11w600.fontWeight,
    borderColor: theme.palette.primary.main,
    borderWidth: "1.25px",
    backgroundColor: theme.palette.primary[10],
  },
}));

interface TabData {
  label: string;
  value: any;
  disabled?: boolean;
}

interface MyCustomTabsProps {
  tabs: TabData[];
  value: any;
  handleChange: (event: React.SyntheticEvent, newValue: any) => void;
  sx?: SxProps;
}

const AppTabs: React.FC<MyCustomTabsProps> = ({
  tabs,
  value,
  handleChange,
  ...sx
}) => {
  return (
    <CustomTabs value={value} onChange={handleChange} {...sx}>
      {tabs.map((tab, index) => (
        <CustomTab
          key={index}
          label={tab.label}
          value={tab.value}
          disableRipple
          disabled={tab.disabled}
        />
      ))}
    </CustomTabs>
  );
};

export default AppTabs;
