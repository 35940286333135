import api from "../axios";
import { IPaginateResponse } from "../types";
import { AxiosRequestConfig } from "axios";
import {
  ChoiceListResponse,
  FeeCategory,
  HSCodeResponse,
  HsCodeSectionResponse,
  InquiryChangeStatusRequest,
  InquiryCreateData,
  InquiryListItemResponse,
  InquiryResponse,
  PortResponse,
} from "./inquiry.type";
import { CarrierType } from "api/workspace";
import { CreateQuoteRequestType, GetRateType } from "pages/inquiries/GetRate";

//#region dropdowns data
export const getPorts = async (config?: AxiosRequestConfig) => {
  const res = await api.get<IPaginateResponse<PortResponse>>(
    "/inquiries/ports/",
    config
  );
  return res.data;
};

export const getHSCodes = async (config?: AxiosRequestConfig) => {
  const res = await api.get<IPaginateResponse<HSCodeResponse>>(
    "/inquiries/hs-codes/",
    config
  );
  return res.data;
};

export const getHsCodeSections = async (config?: AxiosRequestConfig) => {
  const res = await api.get<HsCodeSectionResponse[]>(
    "/inquiries/hs-codes/sections/",
    config
  );
  return res.data;
};

export const getFeeCategories = async (config?: AxiosRequestConfig) => {
  const res = await api.get<FeeCategory[]>(
    "/shipments/fee-categories/",
    config
  );
  return res.data;
};

export const getHSCode = async (
  hsCode: string | undefined,
  config?: AxiosRequestConfig
): Promise<HSCodeResponse> => {
  const res = await api.get(`/inquiries/hs-codes/?${hsCode}/`, config);
  return res.data;
};

export const getImoClass = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    `/inquiries/imo-classes/`,
    config
  );
  return res.data;
};

export const getTransportationTypes = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    `/inquiries/transportation-types/`,
    config
  );
  return res.data;
};

export const getShipTypes = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    `/inquiries/ship-types/`,
    config
  );
  return res.data;
};

export const getWagonTypes = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    `/inquiries/wagon-types/`,
    config
  );
  return res.data;
};

export const getContainerTypes = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    `/inquiries/container-types/`,
    config
  );
  return res.data;
};

export const getTruckTypes = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    `/inquiries/truck-types/`,
    config
  );
  return res.data;
};

export const getAirContainerTypes = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    `/inquiries/air-container-types/`,
    config
  );
  return res.data;
};

export const getIncoterms = async (config?: AxiosRequestConfig) => {
  const res = await api.get<ChoiceListResponse[]>(
    "/inquiries/incoterms/",
    config
  );
  return res.data;
};

//#endregion dropdowns data

//#region Inquiry
export const createInquiry = async (
  workspaceID: string | undefined,
  data: InquiryCreateData,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/`
    : `/inquiries/${workspaceID}/user-inquiries/`;
  const res = await api.post<InquiryResponse>(url, data, config);
  return res.data;
};

export const updateInquiry = async (
  workspaceID: string | undefined,
  inquiryID: string | undefined | number,
  data: InquiryCreateData,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/${inquiryID}/`
    : `/inquiries/${workspaceID}/user-inquiries/${inquiryID}/`;
  const res = await api.put<InquiryResponse>(url, data, config);
  return res.data;
};

export const getInquiryList = async (
  workspaceID: string | undefined,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/`
    : `/inquiries/${workspaceID}/user-inquiries/`;
  const res = await api.get<IPaginateResponse<InquiryListItemResponse>>(
    url,
    config
  );
  return res.data;
};

export const getInquiry = async (
  workspaceID: string | undefined,
  inquiryID: string | undefined | number,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/${inquiryID}/`
    : `/inquiries/${workspaceID}/user-inquiries/${inquiryID}/`;
  const res = await api.get<InquiryResponse>(url, config);
  return res.data;
};

export const getInquiryPreview = async (
  workspaceID: string | undefined,
  inquiryID: string | undefined | number,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/${inquiryID}/rfq_preview/`
    : `/inquiries/${workspaceID}/user-inquiries/${inquiryID}/rfq_preview/`;
  const res = await api.get<string>(url, config);
  return res.data;
};

export const cloneInquiry = async (
  workspaceID: string | undefined,
  inquiryID: string | undefined | number,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/${inquiryID}/clone/`
    : `/inquiries/${workspaceID}/user-inquiries/${inquiryID}/clone/`;
  const res = await api.post<InquiryResponse>(url, config);
  return res.data;
};

export const changeInquiryStatus = async (
  workspaceID: string | undefined,
  data: InquiryChangeStatusRequest,
  slug: string | undefined | number,
  teamAccess = false,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/${slug}/change_status/`
    : `/inquiries/${workspaceID}/user-inquiries/${slug}/change_status/`;
  const res = await api.post<InquiryResponse>(url, data, config);
  return res.data;
};

//#endregion Inquiry

export const getCarriers = async (config?: AxiosRequestConfig) => {
  const res = await api.get<CarrierType[]>("/inquiries/carriers/", config);
  return res.data;
};

export const createQuoteRequest = async (
  workspaceID: string | undefined,
  inquiryID: string | undefined,
  data: CreateQuoteRequestType,
  teamAccess?: boolean,
  config?: AxiosRequestConfig
) => {
  const url = teamAccess
    ? `/inquiries/${workspaceID}/inquiries/${inquiryID}/get_rate/`
    : `/inquiries/${workspaceID}/user-inquiries/${inquiryID}/get_rate/`;
  const res = await api.post(url, data, config);
  return res.data;
};
