import { Button, ButtonProps, Typography, useTheme } from "@mui/material";

type propType = { caption: string } & ButtonProps;

const TableActionButton = (props: propType) => {
  const theme = useTheme();
  return (
    <Button
      onClick={() => props.onClick}
      size="large"
      variant="contained10"
      disableElevation
      color={props.color as any}
      {...props}
      sx={{
        paddingX: 4,
        paddingY: 1.5,
        ...props.sx,
      }}
    >
      <Typography variant="s13w500" noWrap>
        {props.caption}
      </Typography>
    </Button>
  );
};

export default TableActionButton;
