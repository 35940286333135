function BuildingIcon(isActive = false) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M10.4176 6.17501V18.3333H3.40091C2.43424 18.3333 1.64258 17.5583 1.64258 16.6083V4.24167C1.64258 2.05834 3.27591 1.06667 5.26758 2.04167L8.95924 3.86667C9.75924 4.25834 10.4176 5.30001 10.4176 6.17501Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M7.47565 8.125H4.58398C4.24232 8.125 3.95898 7.84167 3.95898 7.5C3.95898 7.15833 4.24232 6.875 4.58398 6.875H7.47565C7.81732 6.875 8.10065 7.15833 8.10065 7.5C8.10065 7.84167 7.82565 8.125 7.47565 8.125Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M7.47565 11.4583H4.58398C4.24232 11.4583 3.95898 11.175 3.95898 10.8333C3.95898 10.4917 4.24232 10.2083 4.58398 10.2083H7.47565C7.81732 10.2083 8.10065 10.4917 8.10065 10.8333C8.10065 11.175 7.82565 11.4583 7.47565 11.4583Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        opacity="0.6"
        d="M18.3327 12.5417V16.25C18.3327 17.4 17.3993 18.3333 16.2493 18.3333H10.416V8.68332L10.8077 8.76665L14.1743 9.51665L14.5743 9.60832L16.2743 9.99165C16.6827 10.075 17.0577 10.2167 17.3827 10.425C17.3827 10.4333 17.391 10.4333 17.391 10.4333C17.4743 10.4917 17.5577 10.5583 17.6327 10.6333C18.016 11.0167 18.266 11.575 18.3243 12.3917C18.3243 12.4417 18.3327 12.4917 18.3327 12.5417Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M10.416 8.68332V13.6833C10.7993 14.1917 11.3993 14.5167 12.0827 14.5167C13.241 14.5167 14.1743 13.5833 14.1743 12.4333V9.52499L10.8077 8.77499L10.416 8.68332Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M18.3258 12.3917C18.2674 11.575 18.0174 11.0167 17.6341 10.6333C17.5591 10.5583 17.4758 10.4917 17.3924 10.4333C17.3924 10.4333 17.3841 10.4333 17.3841 10.425C17.0591 10.2167 16.6841 10.075 16.2758 9.99166L14.5758 9.60833L14.1758 9.51666V12.4333C14.1758 13.575 15.1008 14.5167 16.2591 14.5167C17.3758 14.5167 18.2674 13.6417 18.3341 12.55V12.5417C18.3341 12.4917 18.3258 12.4417 18.3258 12.3917Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
    </svg>
  );
}

export default BuildingIcon;
