function ForwardIcon(isActive = false) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.084 8.49175H14.6757C12.7007 8.49175 11.0923 6.88341 11.0923 4.90841V2.50008C11.0923 2.04175 10.7173 1.66675 10.259 1.66675H6.72565C4.15898 1.66675 2.08398 3.33341 2.08398 6.30841V13.6917C2.08398 16.6667 4.15898 18.3334 6.72565 18.3334H13.2757C15.8423 18.3334 17.9173 16.6667 17.9173 13.6917V9.32508C17.9173 8.86675 17.5423 8.49175 17.084 8.49175Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M13.1658 1.84158C12.8241 1.49991 12.2324 1.73325 12.2324 2.20825V5.11658C12.2324 6.33325 13.2658 7.34158 14.5241 7.34158C15.3158 7.34991 16.4158 7.34991 17.3574 7.34991C17.8324 7.34991 18.0824 6.79158 17.7491 6.45825C16.5491 5.24991 14.3991 3.07491 13.1658 1.84158Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M9.16732 14.7917C9.00898 14.7917 8.85065 14.7334 8.72565 14.6084C8.48398 14.3667 8.48398 13.9667 8.72565 13.725L9.32565 13.125H5.83398C5.49232 13.125 5.20898 12.8417 5.20898 12.5C5.20898 12.1584 5.49232 11.875 5.83398 11.875H9.32565L8.72565 11.275C8.60065 11.15 8.54232 10.9917 8.54232 10.8334C8.54232 10.675 8.60065 10.5167 8.72565 10.3917C8.96732 10.15 9.36732 10.15 9.60898 10.3917L11.2757 12.0584C11.384 12.1667 11.4423 12.3084 11.4507 12.4417C11.4507 12.4834 11.4507 12.5334 11.4507 12.575C11.434 12.6917 11.384 12.8 11.3007 12.9C11.2923 12.9084 11.2757 12.925 11.2673 12.9334L9.60065 14.6C9.48398 14.7334 9.32565 14.7917 9.16732 14.7917Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
    </svg>
  );
}

export default ForwardIcon;
