import ForwardIcon from "assets/image-components/drawer/forward";

const shipments = {
  id: "shipments",
  title: "Shipments",
  type: "group",
  children: [
    {
      id: "inquiries",
      title: "Shipments",
      type: "item",
      url: "inquiries",
      icon: () => ForwardIcon(false),
      activeIcon: () => ForwardIcon(true),
      breadcrumbs: false,
    },
  ],
};

export default shipments;
