import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  getInquiryList,
  cloneInquiry,
  createInquiry,
  getInquiry,
  getHSCodes,
  getPorts,
  updateInquiry,
  getHSCode,
  getFeeCategories,
  getHsCodeSections,
  getInquiryPreview,
  createQuoteRequest,
  changeInquiryStatus,
} from "./inquiry.api";
import { AxiosRequestConfig } from "axios";
import { useHasPermissions } from "api/workspace";
import { WorkspacePermissions } from "api/types";
import { InquiryChangeStatusRequest, InquiryCreateData } from "./inquiry.type";
import { CreateQuoteRequestType } from "pages/inquiries/GetRate";

export const useQueryInquiries = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadInquiry],
    workspaceID,
    true
  );
  return useQuery(
    ["inquiries", workspaceID, config, hasPermission],
    () => getInquiryList(workspaceID, hasPermission, config),
    {
      enabled: !loadingPermission && !!workspaceID,
      keepPreviousData: true,
    }
  );
};
export const useQueryInquiry = (
  workspaceID: string | undefined,
  id: string | number | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadInquiry],
    workspaceID,
    true
  );
  return useQuery(
    ["inquiry", workspaceID, id],
    () => getInquiry(workspaceID, id, hasPermission, config),
    {
      enabled: !loadingPermission && !!workspaceID && !!id,
    }
  );
};
export const useMutationCloneInquiry = (
  workspaceID: string | undefined,
  options: UseMutationOptions<typeof cloneInquiry> = {}
) => {
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadInquiry],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      id: string | undefined | number;
      config?: AxiosRequestConfig;
    }) => cloneInquiry(data.workspaceID, data.id, hasPermission, data.config),

    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["inquiries", variable.workspaceID],
        exact: false,
      });
    },
  });
};

export const useMutationCreateInquiry = (
  workspaceID: string | undefined,
  options: UseMutationOptions<typeof createInquiry> = {}
) => {
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteInquiry],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      data: InquiryCreateData;
      config?: AxiosRequestConfig;
    }) =>
      createInquiry(data.workspaceID, data.data, hasPermission, data.config),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["inquiries", variable.workspaceID],
        exact: false,
      });
    },
  });
};

export const useMutationChangeInquiryStatus = (
  workspaceID: string | undefined,
  options: UseMutationOptions<typeof changeInquiryStatus> = {}
) => {
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteInquiry],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      data: InquiryChangeStatusRequest;
      slug: string | undefined | number;
      config?: AxiosRequestConfig;
    }) =>
      changeInquiryStatus(
        data.workspaceID,
        data.data,
        data.slug,
        hasPermission,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["inquiries", variable.workspaceID],
        exact: false,
      });
    },
  });
};

export const useMutationUpdateInquiry = (
  workspaceID: string | undefined,
  options: UseMutationOptions<typeof updateInquiry> = {}
) => {
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadInquiry],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      id: string | number | undefined;
      data: InquiryCreateData;
      config?: AxiosRequestConfig;
    }) =>
      updateInquiry(
        data.workspaceID,
        data.id,
        data.data,
        hasPermission,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["inquiries", variable.workspaceID],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ["inquiry", workspaceID, data.slug],
      });
    },
  });
};

export const useQueryInquiryPreview = (
  workspaceID: string | undefined,
  id: string | number | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadInquiry],
    workspaceID,
    true
  );
  return useQuery(
    ["inquiry-preview", workspaceID, id],
    () => getInquiryPreview(workspaceID, id, hasPermission, config),
    {
      enabled: !loadingPermission && !!workspaceID && !!id,
    }
  );
};

export const useMutationCreateQuoteRequest = (
  workspaceID: string | undefined
) => {
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteInquiry],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      inquiryID: string;
      data: CreateQuoteRequestType;
      config?: AxiosRequestConfig;
    }) =>
      createQuoteRequest(
        data.workspaceID,
        data.inquiryID,
        data.data,
        hasPermission,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: ["inquiries", variable.workspaceID],
        exact: false,
      });
    },
  });
};

export const useQueryHsCodes = (config?: AxiosRequestConfig) => {
  return useQuery(["hs-codes", config], () => getHSCodes(config), {
    keepPreviousData: true,
  });
};

export const useQueryHsCodeSections = (config?: AxiosRequestConfig) => {
  return useQuery(["hs-sections", config], () => getHsCodeSections(config));
};

export const useQueryFeeCategories = (config?: AxiosRequestConfig) => {
  return useQuery(["fee-categories", config], () => getFeeCategories(config), {
    keepPreviousData: true,
  });
};

export const useQueryHsCode = (
  hs_code: string | undefined,
  config?: AxiosRequestConfig
) => {
  return useQuery(["hs-code", config], () => getHSCode(hs_code, config), {
    enabled: !!hs_code,
  });
};

export const useQueryPorts = (config?: AxiosRequestConfig) => {
  return useQuery(["ports", config?.params], () => getPorts(config), {
    keepPreviousData: true,
  });
};
