import {
  Box,
  Button,
  Link,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import HomePageButton from "components/Buttons/HomePageButton";
import {ReactNode} from "react";

type propType = {
  children: React.ReactNode[] | React.ReactNode;
} 

const ScreenSizeCheck = ({children}: propType) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1024)
  );
  const theme = useTheme();

  return isSmallScreen ? (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: "white",
        position: "absolute",
        zIndex: 2000,
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        marginX="auto"
        gap="1.56rem"
        sx={{
          [theme.breakpoints.down("lg")]: {
            width: "50%",
          },
        
          [theme.breakpoints.down("sm")]: {
            width: "76%",
          },
          [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
            width: "60%",
          },
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            marginX: "auto",
            [`${theme.breakpoints.down("md")} and (orientation: landscape)`]: {
              width: "40%",
            },
            
          }}
        >
          <img
            src="/small-screen.png"
            alt="smallScreen"
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
        <Typography variant="s16w600" textAlign="center">
          Try it on the desktop screen
        </Typography>

        <Typography variant="s13w400" textAlign="center">
          The Logimate panel cannot be displayed on small screens. For a better
          user experience, please open the panel on your desktop or laptop
          screen.
        </Typography>

        <HomePageButton />
      </Stack>
    </Box>
  ) : (
 <>
    {children}
 </>
  );
};

export default ScreenSizeCheck;
