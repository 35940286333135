import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";
import { Backdrop, Box, Button, Stack, SxProps, useTheme } from "@mui/material";
import { drawerWidth } from "config";
import { expandChatList } from "store/reducers/sidebar";
import ChatList from "pages/chats/ChatList";
import ShipmentCategories from "pages/inquiries/ShipmentCategories";

function SecondSidebar() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const { workspaceID } = useParams();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { isChatListExpanded } = useSelector((state: any) => state.sidebar);
  const isShipmentCategoriesActive =
    pathname.endsWith(`workspace/${workspaceID}/inquiries`) ||
    pathname.endsWith(
      `workspace/${workspaceID}/inquiries/creating`
    ) ||
    pathname.endsWith(
      `workspace/${workspaceID}/inquiries/requesting`
    ) ||
    pathname.endsWith(
      `workspace/${workspaceID}/inquiries/tracking`
    ) ||
    pathname.endsWith(
      `workspace/${workspaceID}/inquiries/completed`
    );

  const isChatListActive = pathname.includes(`workspace/${workspaceID}/chats`);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        isChatListExpanded
      ) {
        dispatch(expandChatList(false));
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const SharedChildren = ({ sx }: { sx?: SxProps }) => {
    return (
      <Box sx={sx}>
        {isShipmentCategoriesActive && <ShipmentCategories />}
        {isChatListActive && (
          <Box p={3}>
            {isChatListExpanded ? (
              <Button
                disableRipple
                variant="text"
                onClick={() => dispatch(expandChatList(false))}
              >
                <ArrowLeft2 size={16} color={theme.palette.primary.main} />
                Collapse
              </Button>
            ) : (
              <Button
                disableRipple
                variant="text"
                onClick={() => dispatch(expandChatList(true))}
              >
                Expand
                <ArrowRight2 size={16} color={theme.palette.primary.main} />
              </Button>
            )}
          </Box>
        )}
        {isChatListActive && <ChatList />}
      </Box>
    );
  };

  return (
    <Stack
      width={"13rem"}
      height={"100%"}
      overflow={"hidden"}
      boxShadow={"5px 0px 25px 0px rgba(45, 48, 68, 0.05)"}
      bgcolor={"#F5F5FC"}
    >
      <SharedChildren
        sx={{
          zIndex: isChatListExpanded ? 0 : "auto",
        }}
      />
      <Backdrop
        open={isChatListExpanded}
        sx={{
          position: "absolute",
        }}
      >
        <Stack
          ref={wrapperRef}
          width={
            isChatListExpanded && !isShipmentCategoriesActive
              ? "18rem"
              : "13rem"
          }
          height={"100%"}
          overflow={"hidden"}
          boxShadow={"5px 0px 25px 0px rgba(45, 48, 68, 0.05)"}
          bgcolor={"#F5F5FC"}
          sx={{ transition: "ease width 0.3s" }}
          position={"absolute"}
          top={0}
          left={drawerWidth}
        >
          <SharedChildren />
        </Stack>
      </Backdrop>
    </Stack>
  );
}

export default SecondSidebar;
