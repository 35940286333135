import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router";
import { Member, UpdateMember, useUpdateMember } from "api/workspace";
import useAlert from "hooks/useAlert";
import MemberRoleEditField from "components/Forms/MemberEditRoleField";

type Props = {
  onSuccess?: () => void;
  instance: Member;
  disabled?: boolean;
};

export type UpdateMemberRoleData = Omit<UpdateMember, "is_active">;

function UpdateMemberRole({ onSuccess, instance, disabled }: Props) {
  const { showAlert } = useAlert();
  const { workspaceID } = useParams();
  const { mutate } = useUpdateMember();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<any>({});

  const handleSubmit = (values: UpdateMemberRoleData) => {
    setLoading(true);
    mutate(
      {
        workspaceID: workspaceID ?? "",
        member: {
          ...values,
          is_active: instance.is_active,
          role: values.role ? +values.role : null,
        },
        id: instance.id,
      },
      {
        onSuccess: () => {
          onSuccess?.();
          setLoading(false);
        },
        onError(error: any) {
          showAlert({ message: error, severity: "error" });
          setErrors(error.response.data);
          setLoading(false);
        },
      }
    );
  };

  return (
    <Formik<UpdateMemberRoleData>
      initialValues={{
        role: instance.role,
        is_admin: instance.is_admin,
        is_owner: instance.is_owner,
        is_team_member: instance.is_team_member,
      }}
      validationSchema={Yup.object().shape({
        role: Yup.string().nullable(),
      })}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <MemberRoleEditField
            key={instance.id}
            handleSubmit={handleSubmit}
            values={values}
            loading={loading}
            disabled={disabled || values?.is_owner}
            roleLabel={instance?.role_details?.name}
          />
        </form>
      )}
    </Formik>
  );
}

export default UpdateMemberRole;
