// material-ui
import { Box, Typography } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import menuItems from "menu-items";
import { useState } from "react";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const [activeItem, setActiveItem] = useState();
  const navGroups = menuItems.items.map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup
            key={item.id}
            item={item}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
