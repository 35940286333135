// ==============================|| OVERRIDES - BUTTON ||============================== //

import { ButtonProps } from "@mui/material";
import { Theme } from "@mui/material/styles";

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({
          ownerState,
          theme,
        }: {
          ownerState: ButtonProps;
          theme: Theme;
        }) => {
          const ownColor = ownerState.color as keyof typeof theme.palette;
          const colorObject = (theme?.palette[ownColor] as any) || ownColor;
          return {
            fontWeight: theme.typography.s12w500.fontWeight,
            borderRadius: theme.typography.s12w500.fontSize,
            color: colorObject.main,
            ...(ownerState.variant === "contained" && {
              backgroundColor: colorObject.main,
              border: "none",
              color: colorObject.contrastText ?? colorObject.main,
            }),
            ...(ownerState.variant === "contained10" && {
              backgroundColor: colorObject["10"],
              color: colorObject.main,
              border: "none",
              "&:hover": {
                backgroundColor: colorObject.main,
                ...(colorObject.contrastText && {
                  color: colorObject.contrastText,
                }),
              },
            }),
            ...(ownerState.variant === "colorOutlined" && {
              backgroundColor: "transparent",
              color: colorObject.main,
              borderColor: colorObject.main,
              border: "1px solid",
              "&:hover": {
                backgroundColor: colorObject.main,
                ...(colorObject.contrastText && {
                  color: colorObject.contrastText,
                }),
              },
            }),
            ...(ownerState.variant === "text" && {
              backgroundColor: "transparent",
              color: colorObject.main,
              border: "none",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }),
            ...(colorObject.disabled && {
              "&.Mui-disabled": {
                backgroundColor: colorObject.disabled,
                ...((colorObject.contrastText || colorObject?.disabledText) && {
                  color: colorObject?.disabledText ?? colorObject.contrastText,
                }),
              },
            }),
          };
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderColor: "#E1E1EA",
            color: "black",
            outlineColor: "transparent",
            "&:hover": {
              backgroundColor: "#FAF9FA",
              borderColor: "#E1E1EA",
            },
            "&.Mui-disabled": {
              backgroundColor: "white",
              color: "#aaacb4",
            },
          },
        },
      ],
    },
  };
}
