import { Button, ButtonProps, Link } from "@mui/material";


const HomePageButton = () => {
  return (
    <Button
      href="https://logimate.io"
      variant="contained"
      color="primary"
      component={Link}
      sx={{
        width: "100%",
        py: 1,
        textTransform: "none !important",
      }}
    >
      Go to homepage
    </Button>
  );
};

export default HomePageButton;
