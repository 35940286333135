import { AlertContext } from "contexts/AlertContext";
import { useContext } from "react";

const useAlert = () => {

    const context = useContext(AlertContext);

    if (!context) {
        throw Error("useAlert should be used within AlertProvider");
    }

    return context;

};

export default useAlert;
