import moment from "moment";

export function timeConvert(dateString: string | null) {
  if (!dateString) {
    return;
  }
  const initialDate = new Date(dateString);
  const date = new Date(
    initialDate.getTime() + initialDate.getTimezoneOffset() * 60000
  );

  let hours = date.getHours();
  let minutes = date.getMinutes();

  let amOrPm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? "0" + minutes : minutes.toString();

  let dateToFormat = date;
  const isThisYear = new Date(date).getFullYear() === new Date().getFullYear();
  const isToday =
    dateToFormat.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
  dateToFormat = date;
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const isYesterday =
    dateToFormat.setHours(0, 0, 0, 0) === yesterday.setHours(0, 0, 0, 0);
  if (isToday) {
    return `${hours}:${minutesStr} ${amOrPm}`;
  } else if (isYesterday) {
    return "Yesterday";
  } else if (isThisYear) {
    return moment(date).format("MMM DD");
  } else {
    return moment(date).format("MMM DD, YYYY");
  }
}
