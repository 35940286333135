// ==============================|| OVERRIDES - CHECKBOX ||============================== //

import { Theme } from "@mui/material/styles";

export default function Dialog(theme: Theme) {
  return {
    MuiDialog: {
      styleOverrides: {
        root: {},
        paper: {},
      },
    },
  };
}
