import React, { useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import { useParams } from "react-router";
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import AnimateButton from "components/@extended/AnimateButton";
import {
  Role,
  useCreateRole,
  usePermissions,
  useUpdateRole,
} from "api/workspace";
import {
  CreateRolePermission,
  PERMISSION_MODEL_NAMES,
  PermissionAccessLevel,
  PermissionAccessLevelEnum,
  filterPermissionByModel,
  filterPermissionsInRoleByModelName,
} from "api/workspace/workspace.types";
import RoleAccessLevelFieldInput from "components/Forms/RoleForm/RoleAccessLevelFieldInput";
import FormikTextField from "components/FormFields/FormikTextField";
import useAlert from "hooks/useAlert";

type Props = {
  instance?: Role;
  onSuccess?: (role?: Role) => void;
};

type roleValueType = {
  accessLevel: PermissionAccessLevel | "";
  disabled?: boolean;
};
interface AddRoleData {
  name: string;
  teamInquiry: roleValueType;
  teamQuoteShipment: roleValueType;
  memberManagement: roleValueType;
  roleManagement: roleValueType;
  companies: roleValueType;
  contacts: roleValueType;
  ownInquiries: roleValueType;
  ownQuoteShipment: roleValueType;
}

function RoleForm({ instance, onSuccess }: Props) {
  const theme = useTheme();
  const { workspaceID } = useParams();
  const { data: permissions } = usePermissions();
  const { mutate: createMutate } = useCreateRole();
  const { mutate: updateMutate } = useUpdateRole();
  const { showAlert } = useAlert();

  const membershipPermission = useMemo(
    () =>
      filterPermissionByModel(
        permissions ?? [],
        PERMISSION_MODEL_NAMES.MEMBERSHIP
      ),
    [permissions]
  );
  const quotePermission = useMemo(
    () =>
      filterPermissionByModel(permissions ?? [], PERMISSION_MODEL_NAMES.QUOTE),
    [permissions]
  );
  const shipmentPermission = useMemo(
    () =>
      filterPermissionByModel(
        permissions ?? [],
        PERMISSION_MODEL_NAMES.SHIPMENT
      ),
    [permissions]
  );
  const inquiryPermission = useMemo(
    () =>
      filterPermissionByModel(
        permissions ?? [],
        PERMISSION_MODEL_NAMES.INQUIRY
      ),
    [permissions]
  );
  const rolePermission = useMemo(
    () =>
      filterPermissionByModel(permissions ?? [], PERMISSION_MODEL_NAMES.ROLE),
    [permissions]
  );

  const createFormField = (
    role?: Role,
    permissionName?: PERMISSION_MODEL_NAMES
  ): roleValueType => {
    const accessLevel =
      role && permissionName
        ? filterPermissionsInRoleByModelName(role, permissionName)
            ?.access_level || ""
        : PermissionAccessLevelEnum.ReadWrite;

    return { accessLevel };
  };

  const createDefaultStaticRole = (): roleValueType => ({
    accessLevel: PermissionAccessLevelEnum.ReadWrite,
    disabled: true,
  });

  const getRoleTeamQuoteShipmentAccessLevel = (
    role: Role | undefined
  ): PermissionAccessLevel | "" => {
    if (role) {
      const teamQuotesAccessLevel = filterPermissionsInRoleByModelName(
        role,
        PERMISSION_MODEL_NAMES.QUOTE
      )?.access_level;
      const shipmentAccessLevel = filterPermissionsInRoleByModelName(
        role,
        PERMISSION_MODEL_NAMES.SHIPMENT
      )?.access_level;

      if (
        teamQuotesAccessLevel === PermissionAccessLevelEnum.ReadWrite &&
        shipmentAccessLevel === PermissionAccessLevelEnum.ReadWrite
      ) {
        return PermissionAccessLevelEnum.ReadWrite;
      } else if (
        teamQuotesAccessLevel === PermissionAccessLevelEnum.Read &&
        shipmentAccessLevel === PermissionAccessLevelEnum.Read
      ) {
        return PermissionAccessLevelEnum.Read;
      } else {
        return "";
      }
    } else {
      return PermissionAccessLevelEnum.ReadWrite;
    }
  };

  const [formInitialValues, setFormInitialValues] = useState<AddRoleData>({
    name: "",
    teamInquiry: createFormField(),
    teamQuoteShipment: createFormField(),
    memberManagement: createFormField(),
    roleManagement: createFormField(),
    contacts: createDefaultStaticRole(),
    companies: createDefaultStaticRole(),
    ownInquiries: createDefaultStaticRole(),
    ownQuoteShipment: createDefaultStaticRole(),
  });

  useEffect(() => {
    let teamQuoteShipment: roleValueType = {
      accessLevel: "",
    };
    const accessLevel = getRoleTeamQuoteShipmentAccessLevel(instance);
    teamQuoteShipment = {
      accessLevel,
    };
    let val = {
      name: instance?.name ?? "",
      teamInquiry: createFormField(instance, PERMISSION_MODEL_NAMES.INQUIRY),
      teamQuoteShipment: teamQuoteShipment,
      memberManagement: createFormField(
        instance,
        PERMISSION_MODEL_NAMES.MEMBERSHIP
      ),
      roleManagement: createFormField(instance, PERMISSION_MODEL_NAMES.ROLE),
      contacts: createDefaultStaticRole(),
      companies: createDefaultStaticRole(),
      ownInquiries: createDefaultStaticRole(),
      ownQuoteShipment: createDefaultStaticRole(),
    };
    setFormInitialValues(val);
  }, [instance]);

  const isPermissionSelected = (permission: roleValueType): boolean => {
    return (
      permission.accessLevel === PermissionAccessLevelEnum.Read ||
      permission.accessLevel === PermissionAccessLevelEnum.ReadWrite
    );
  };
  const isAnyPermissionSelected = (values: AddRoleData) => {
    return (
      isPermissionSelected(values.teamInquiry) ||
      isPermissionSelected(values.teamQuoteShipment) ||
      isPermissionSelected(values.roleManagement) ||
      isPermissionSelected(values.memberManagement)
    );
  };

  const handleSubmit = (
    values: AddRoleData,
    { setErrors, setSubmitting }: any
  ) => {
    let permissions: CreateRolePermission[] = [];

    if (isPermissionSelected(values.teamInquiry) && inquiryPermission?.id) {
      let perm = {
        access_level: values.teamInquiry.accessLevel as PermissionAccessLevel,
        permission: inquiryPermission?.id,
      };
      permissions.push(perm);
    }
    if (isPermissionSelected(values.teamQuoteShipment) && quotePermission?.id) {
      let perm = {
        access_level: values.teamQuoteShipment
          .accessLevel as PermissionAccessLevel,
        permission: quotePermission?.id,
      };
      permissions.push(perm);
    }
    if (
      isPermissionSelected(values.teamQuoteShipment) &&
      shipmentPermission?.id
    ) {
      let perm = {
        access_level: values.teamQuoteShipment
          .accessLevel as PermissionAccessLevel,
        permission: shipmentPermission?.id,
      };
      permissions.push(perm);
    }
    if (
      isPermissionSelected(values.memberManagement) &&
      membershipPermission?.id
    ) {
      let perm = {
        access_level: values.memberManagement
          .accessLevel as PermissionAccessLevel,
        permission: membershipPermission?.id,
      };
      permissions.push(perm);
    }
    if (isPermissionSelected(values.roleManagement) && rolePermission?.id) {
      let perm = {
        access_level: values.roleManagement
          .accessLevel as PermissionAccessLevel,
        permission: rolePermission?.id,
      };
      permissions.push(perm);
    }
    let submitData = {
      name: values.name,
      permissions,
    };
    setSubmitting(true);
    if (instance) {
      updateMutate(
        {
          workspaceID: workspaceID ?? "",
          role: submitData,
          id: instance.id,
        },
        {
          onSuccess: (role) => {
            onSuccess?.(role);
            setSubmitting(false);
            showAlert({message: "Role edited successfully", severity: "success"})
          },
          onError(error: any) {
            showAlert({ message: error, severity: "error" });
            setErrors(error.response.data);
            setSubmitting(false);
          },
        }
      );
    } else {
      createMutate(
        {
          workspaceID: workspaceID ?? "",
          role: submitData,
        },
        {
          onSuccess: (role) => {
            onSuccess?.(role);
            setSubmitting(false);
          },
          onError(error: any, variables, context) {
            showAlert({ message: error, severity: "error" });
            setErrors(error.response.data);
            setSubmitting(false);
          },
        }
      );
    }
  };

  return (
    <Formik<AddRoleData>
      initialValues={{
        ...formInitialValues,
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        name: Yup.string().max(150).required("Name is required"),
        permissions: Yup.array()
          .of(Yup.number())
          .min(1, "Select at least one permission"),
      })}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        setErrors,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display={"flex"} alignItems={"center"}>
                <Box width={"7.5rem"}>
                  <Typography variant="s13w500" color={"space-cadet"}>
                    Role name
                  </Typography>
                </Box>
                <FormikTextField name="name" placeholder="Role name" fullWidth />
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box display={"flex"}>
                <Box width={"7.5rem"}>
                  <Typography variant="s13w500" color={"space-cadet"}>
                    Permissions
                  </Typography>
                </Box>
                <Box width={"100%"}>
                  <Stack
                    border={"1px solid"}
                    borderColor={"platinum"}
                    borderRadius={theme.customRadius}
                    spacing={1}
                    px={2}
                    py={3}
                  >
                    <RoleAccessLevelFieldInput
                      fieldName="teamInquiry"
                      fieldLabel="Team Inquiry"
                    />
                    <RoleAccessLevelFieldInput
                      fieldName="teamQuoteShipment"
                      fieldLabel="Team Quotes And Shipment"
                    />
                    <RoleAccessLevelFieldInput
                      fieldName="memberManagement"
                      fieldLabel="Member Management"
                    />
                    <RoleAccessLevelFieldInput
                      fieldName="roleManagement"
                      fieldLabel="Role Management"
                    />
                    <RoleAccessLevelFieldInput
                      fieldName="contacts"
                      fieldLabel="Contacts"
                    />
                    <RoleAccessLevelFieldInput
                      fieldName="companies"
                      fieldLabel="Companies"
                    />
                    <RoleAccessLevelFieldInput
                      fieldName="ownInquiries"
                      fieldLabel="Own Inquiries"
                    />
                    <RoleAccessLevelFieldInput
                      fieldName="ownQuoteShipment"
                      fieldLabel="Own Quotes And Shipments"
                    />
                  </Stack>
                  {!isAnyPermissionSelected(values) && (
                    <Grid item xs={12} mt={1} ml={1}>
                      <Typography variant="body1" color="error">
                        Select at least one permission
                      </Typography>
                    </Grid>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} display={"flex"} justifyContent={"end"}>
              <AnimateButton>
                <Button
                  disableElevation
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ width: "10.25rem" }}
                >
                  Save
                </Button>
              </AnimateButton>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
}

export default RoleForm;
