import * as yup from "yup";

declare module "yup" {
  interface Schema {
    dateInPresentOrFuture(): this;
  }
}

// @ts-ignore
yup.addMethod(yup.date, "dateInPresentOrFuture", function (args) {
  return this.test(
    "date-in-present-future",
    "Can not be in the past",
    function (value: Date) {
      if (!!value) {
        return value.getDate() >= new Date().getDate();
      } else {
        return true;
      }
    }
  );
});
