import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// project import
import {
  activeChatList,
  activeShipmentCategories,
  expandChatList,
} from "store/reducers/sidebar";

const NavItem = ({ item, level, activeItem, setActiveItem }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { workspaceID } = useParams();
  const location = useLocation();
  let itemTarget = "_self";
  if (item.target) {
    itemTarget = "_blank";
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link
        ref={ref}
        {...props}
        to={`/workspace/${workspaceID}/${item.url}`}
        target={itemTarget}
      />
    )),
  };
  if (item?.external) {
    listItemProps = { component: "a", href: item.url, target: itemTarget };
  }

  const expandChatHandler = () => {
    if (item.id === "chats" && item.id === activeItem) {
      dispatch(expandChatList(true));
    }
    return;
  };

  const itemClickHandler = () => {
    setActiveItem(item.id);
    if (item.id === "chats") {
      dispatch(activeShipmentCategories(false));
      dispatch(activeChatList(true));
      dispatch(expandChatList(true));
    } else if (item.id === "inquiries") {
      dispatch(activeShipmentCategories(true));
      dispatch(activeChatList(false));
      dispatch(expandChatList(false));
    } else {
      dispatch(activeChatList(false));
      dispatch(activeShipmentCategories(false));
    }
  };

  const isSelected = location.pathname.includes(
    `workspace/${workspaceID}/${item.url}`
  );

  const Icon = isSelected && item?.activeIcon ? item.activeIcon : item.icon;
  const itemIcon = <Icon style={{ fontSize: "1.25rem" }} />;

  const textColor = "#bfbfbf";
  const iconSelectedColor = "secondary.main";

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={itemClickHandler}
      onMouseEnter={expandChatHandler}
      selected={isSelected}
      sx={{
        display: "flex",
        flexDirection: "column",
        zIndex: 1201,
        py: "0.75rem",
        px: 2,
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            color: isSelected ? iconSelectedColor : textColor,
            borderRadius: "1.5rem",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            background: isSelected ? theme.gradient.primary.light : "unset",
            py: "2px",
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      <ListItemText
        sx={{ my: 0 }}
        primary={
          <Typography
            variant="s12w400"
            sx={{ color: "white", fontWeight: isSelected ? 600 : 400 }}
          >
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
