import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

type propType = {
  open: boolean;
  handleClose: (result: boolean) => void;
  title?: string;
  text: string;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

const DialogAlertConfirm = ({
  open,
  handleClose,
  title,
  text,
  color,
}: propType) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={open}
      onClose={(e) => handleClose(false)}
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={(e) => handleClose(false)} variant="outlined">
          No
        </Button>
        <Button
          onClick={(e) => handleClose(true)}
          variant={"contained"}
          color={color ?? "primary"}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAlertConfirm;
