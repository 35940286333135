import { Box, TextField, TextFieldProps, Typography } from "@mui/material";

export type AppTextFieldProps = {
  name?: string;
  fullWidth?: boolean;
  label?: any;
  placeholder?: string;
  absoluteHelper?: boolean;
} & TextFieldProps;

const AppTextField = ({
  name,
  fullWidth = true,
  label,
  placeholder,
  absoluteHelper,
  ...props
}: AppTextFieldProps) => {
  return (
    <Box width={fullWidth ? "100%" : "auto"}>
      {label && (
        <Box textAlign={"left"} width={"100%"} mb={"0.25rem"} ml={1}>
          <Typography variant="s13w500">{label}</Typography>
        </Box>
      )}
      <TextField
        name={name}
        fullWidth={fullWidth}
        placeholder={placeholder}
        onWheel={() => (document?.activeElement as HTMLElement)?.blur()}
        sx={{
          "& .MuiFormHelperText-root.Mui-error": absoluteHelper
            ? {
                position: "absolute",
                bottom: "-1.5rem",
              }
            : {},
        }}
        {...props}
      />
    </Box>
  );
};

export default AppTextField;
