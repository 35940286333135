// third-party
import { combineReducers } from "redux";

// project import
import sidebar from "./sidebar";
import auth from "./auth";
import workspace from "./workspace";

const reducers = combineReducers({ sidebar, auth, workspace });

export default reducers;
