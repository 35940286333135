import * as React from "react";
import MuiSnackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";
export type SnackbarProps = {
  open: boolean;
  onClose: () => void;
  message: string;
  autoHideDuration?: number;
  severity?: AlertColor | undefined;
  anchorOrigin?: SnackbarOrigin;
};

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Snackbar({
  open,
  onClose,
  message,
  autoHideDuration = 6000,
  severity = "error",
  anchorOrigin,
}: SnackbarProps) {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    onClose();
  };
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%", color: "white" }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  );
}

export default Snackbar;
