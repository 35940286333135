import { Checkbox, CheckboxProps, styled } from "@mui/material";
import UncheckedCheckboxIcon from "assets/image-components/shared/uncheckedCheckbox";
import CheckedCheckboxIcon from "assets/image-components/shared/checkedCheckbox";

const CustomCheckbox = styled(Checkbox)({
  padding: "0",
  marginRight: "0.25rem",
});

export default function AppCheckbox(props: CheckboxProps) {
  return (
    <CustomCheckbox
      {...props}
      icon={<UncheckedCheckboxIcon />}
      checkedIcon={<CheckedCheckboxIcon />}
    />
  );
}
