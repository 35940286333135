import { Box, OutlinedInput, SxProps } from "@mui/material";
import { SearchNormal1 } from "iconsax-react";

function SearchInput({
  placeholder,
  value,
  handleChange,
  iconColor = "#676977",
  iconSize = "1rem",
  fullWidth = false,
  customSx,
}: {
  placeholder: string;
  value: string;
  handleChange: React.Dispatch<React.SetStateAction<any>>;
  iconColor?: string;
  iconSize?: string;
  fullWidth?: boolean;
  customSx?: SxProps;
}) {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{ position: "absolute", top: "0.75rem", left: "1rem", zIndex: 9 }}
      >
        <SearchNormal1 color={iconColor} size={iconSize} />
      </Box>
      <OutlinedInput
        id="name"
        type="text"
        value={value}
        name="name"
        onChange={handleChange}
        placeholder={placeholder}
        sx={{
          pl: "2rem",
          height: "2.5rem",
          minWidth: fullWidth ? "100%" : "15rem",
          width: "fit-content",
          "& .MuiOutlinedInput-input": {
            ml: 1,
            pl: 1,
          },
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
          },
          ...customSx,
        }}
        // error={Boolean(touched.name && errors.name)}
      />

      {/* {touched.name && errors.name && (
          <FormHelperText error id="standard-weight-helper-text-name">
            {errors.name}
          </FormHelperText>
        )} */}
    </Box>
  );
}

export default SearchInput;
