// material-ui
import { Theme } from "@mui/material/styles";

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme: Theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "10.5px 14px 10.5px 12px",
        },
        notchedOutline: {
          borderColor: theme.palette["ghost-grey"],
        },
        root: {
          "&& .Mui-selected": {
            color: "red"
          },
        
          borderRadius: "0.75rem",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.hovered,
          },
          "&.Mui-focused": {
            boxShadow: "none",
            "& .MuiOutlinedInput-notchedOutline": {
              border: `1.25px solid ${theme.palette.primary.main}`,
            },
          },
          "&.Mui-error": {
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.error.main,
            },
            "&.Mui-focused": {
              boxShadow: "none",
              "& .MuiOutlinedInput-notchedOutline": {
                border: `1.25px solid ${theme.palette.error.main}`,
              },
            },
          },
        },
        inputSizeSmall: {
          padding: "8p.5x 8px 8.5px 12px",
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
  };
}
