import { AxiosRequestConfig } from "axios";
import api, { baseUrl } from "../axios";
import {
  UserInfoResponse,
  UserInfoUpdate,
  UserRegister,
  PasswordChangeData,
} from "./auth.type";

export const register = async (data: UserRegister) => {
  try {
    const res = await api.post(`${baseUrl}/auth/register/`, data);
    return res;
  } catch (e) {
    throw e;
  }
};

export const getUserInfo = async (config?: AxiosRequestConfig) => {
  const res = await api.get<UserInfoResponse>("/auth/user/info/", config);
  return res.data;
};

export const updateUserInfo = async (userInfo: UserInfoUpdate) => {
  const res = await api.put<UserInfoResponse>("auth/user/info/", userInfo);
  return res.data;
};

export const updateUserPassword = async (data: PasswordChangeData) => {
  const res = await api.post("/auth/user/change-password/", data);
  return res;
};
