import { Dialog, styled, DialogProps } from "@mui/material";

type AppDialogProps = DialogProps & {
  paperMaxWidth?: string | number;
  paperWidth?: string | number;
};

const AppDialog = styled(Dialog)<AppDialogProps>(
  ({ theme, paperMaxWidth, paperWidth }) =>
    theme.unstable_sx({
      zIndex: 2100,
      "& .MuiPaper-root": {
        maxWidth: paperMaxWidth ?? "34.5rem",
        width: paperWidth ?? "auto",
        borderRadius: (theme) => theme.customRadius,
      },
      "& .MuiBackdrop-root": {
        backgroundColor: "#0009",
      },
    })
);

export default AppDialog;
