// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

declare module "@mui/material/styles" {
  interface TypographyVariants {
    s10w400: React.CSSProperties;
    s10w500: React.CSSProperties;
    s10w600: React.CSSProperties;
    s10w700: React.CSSProperties;
    s10w800: React.CSSProperties;

    s11w400: React.CSSProperties;
    s11w500: React.CSSProperties;
    s11w600: React.CSSProperties;
    s11w700: React.CSSProperties;
    s11w800: React.CSSProperties;

    s12w400: React.CSSProperties;
    s12w500: React.CSSProperties;
    s12w600: React.CSSProperties;
    s12w700: React.CSSProperties;
    s12w800: React.CSSProperties;

    s13w400: React.CSSProperties;
    s13w500: React.CSSProperties;
    s13w600: React.CSSProperties;
    s13w700: React.CSSProperties;
    s13w800: React.CSSProperties;

    s14w400: React.CSSProperties;
    s14w500: React.CSSProperties;
    s14w600: React.CSSProperties;
    s14w700: React.CSSProperties;
    s14w800: React.CSSProperties;

    s16w400: React.CSSProperties;
    s16w500: React.CSSProperties;
    s16w600: React.CSSProperties;
    s16w700: React.CSSProperties;
    s16w800: React.CSSProperties;

    s20w400: React.CSSProperties;
    s20w500: React.CSSProperties;
    s20w600: React.CSSProperties;
    s20w700: React.CSSProperties;
    s20w800: React.CSSProperties;

    s24w400: React.CSSProperties;
    s24w500: React.CSSProperties;
    s24w600: React.CSSProperties;
    s24w700: React.CSSProperties;
    s24w800: React.CSSProperties;

    s28w400: React.CSSProperties;
    s28w500: React.CSSProperties;
    s28w600: React.CSSProperties;
    s28w700: React.CSSProperties;
    s28w800: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    s10w400?: React.CSSProperties;
    s10w500?: React.CSSProperties;
    s10w600?: React.CSSProperties;
    s10w700?: React.CSSProperties;
    s10w800?: React.CSSProperties;

    s11w400?: React.CSSProperties;
    s11w500?: React.CSSProperties;
    s11w600?: React.CSSProperties;
    s11w700?: React.CSSProperties;
    s11w800?: React.CSSProperties;

    s12w400?: React.CSSProperties;
    s12w500?: React.CSSProperties;
    s12w600?: React.CSSProperties;
    s12w700?: React.CSSProperties;
    s12w800?: React.CSSProperties;

    s13w400?: React.CSSProperties;
    s13w500?: React.CSSProperties;
    s13w600?: React.CSSProperties;
    s13w700?: React.CSSProperties;
    s13w800?: React.CSSProperties;

    s14w400?: React.CSSProperties;
    s14w500?: React.CSSProperties;
    s14w600?: React.CSSProperties;
    s14w700?: React.CSSProperties;
    s14w800?: React.CSSProperties;

    s16w400?: React.CSSProperties;
    s16w500?: React.CSSProperties;
    s16w600?: React.CSSProperties;
    s16w700?: React.CSSProperties;
    s16w800?: React.CSSProperties;

    s20w400?: React.CSSProperties;
    s20w500?: React.CSSProperties;
    s20w600?: React.CSSProperties;
    s20w700?: React.CSSProperties;
    s20w800?: React.CSSProperties;

    s24w400?: React.CSSProperties;
    s24w500?: React.CSSProperties;
    s24w600?: React.CSSProperties;
    s24w700?: React.CSSProperties;
    s24w800?: React.CSSProperties;

    s28w400?: React.CSSProperties;
    s28w500?: React.CSSProperties;
    s28w600?: React.CSSProperties;
    s28w700?: React.CSSProperties;
    s28w800?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    s10w400: true;
    s10w500: true;
    s10w600: true;
    s10w700: true;
    s10w800: true;

    s11w400: true;
    s11w500: true;
    s11w600: true;
    s11w700: true;
    s11w800: true;

    s12w400: true;
    s12w500: true;
    s12w600: true;
    s12w700: true;
    s12w800: true;

    s13w400: true;
    s13w500: true;
    s13w600: true;
    s13w700: true;
    s13w800: true;

    s14w400: true;
    s14w500: true;
    s14w600: true;
    s14w700: true;
    s14w800: true;

    s15w400: true;
    s15w500: true;
    s15w600: true;
    s15w700: true;
    s15w800: true;

    s16w400: true;
    s16w500: true;
    s16w600: true;
    s16w700: true;
    s16w800: true;

    s20w400: true;
    s20w500: true;
    s20w600: true;
    s20w700: true;
    s20w800: true;

    s24w400: true;
    s24w500: true;
    s24w600: true;
    s24w700: true;
    s24w800: true;

    s28w400: true;
    s28w500: true;
    s28w600: true;
    s28w700: true;
    s28w800: true;
  }
}

const Typography = (fontFamily: string) => ({
  htmlFontSize: 16,
  fontFamily: fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 600,
    fontSize: "2.375rem",
    lineHeight: 1.21,
  },
  h2: {
    fontWeight: 600,
    fontSize: "1.875rem",
    lineHeight: 1.27,
  },
  h3: {
    fontWeight: 600,
    fontSize: "1.5rem",
    lineHeight: 1.33,
  },
  h4: {
    fontWeight: 600,
    fontSize: "1.25rem",
    lineHeight: 1.4,
  },
  h5: {
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: 1.5,
  },
  h6: {
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.57,
  },
  label: {
    fontWeight: 600,
    fontFamily: "0.8125rem",
  },
  caption: {
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 1.66,
  },
  body1: {
    fontSize: "0.875rem",
    lineHeight: 1.57,
  },
  body2: {
    fontSize: "0.75rem",
    lineHeight: 1.66,
  },
  subtitle1: {
    fontSize: "0.875rem",
    fontWeight: 600,
    lineHeight: 1.57,
  },
  subtitle2: {
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: 1.66,
  },
  overline: {
    lineHeight: 1.66,
  },
  button: {
    textTransform: "capitalize" as const,
  },

  // Custom variants

  // 10px
  s10w400: {
    fontSize: "0.625rem",
    fontWeight: 400,
  },
  s10w500: {
    fontSize: "0.625rem",
    fontWeight: 500,
  },
  s10w600: {
    fontSize: "0.625rem",
    fontWeight: 600,
  },
  s10w700: {
    fontSize: "0.625rem",
    fontWeight: 700,
  },
  s10w800: {
    fontSize: "0.625rem",
    fontWeight: 800,
  },

  // 11px
  s11w400: {
    fontSize: "0.6875rem",
    fontWeight: 400,
  },
  s11w500: {
    fontSize: "0.6875rem",
    fontWeight: 500,
  },
  s11w600: {
    fontSize: "0.6875rem",
    fontWeight: 600,
  },
  s11w700: {
    fontSize: "0.6875rem",
    fontWeight: 700,
  },
  s11w800: {
    fontSize: "0.6875rem",
    fontWeight: 800,
  },

  // 12px
  s12w400: {
    fontSize: "0.75rem",
    fontWeight: 400,
  },
  s12w500: {
    fontSize: "0.75rem",
    fontWeight: 500,
  },
  s12w600: {
    fontSize: "0.75rem",
    fontWeight: 600,
  },
  s12w700: {
    fontSize: "0.75rem",
    fontWeight: 700,
  },
  s12w800: {
    fontSize: "0.75rem",
    fontWeight: 800,
  },

  // 13px
  s13w400: {
    fontSize: "0.8125rem",
    fontWeight: 400,
  },
  s13w500: {
    fontSize: "0.8125rem",
    fontWeight: 500,
  },
  s13w600: {
    fontSize: "0.8125rem",
    fontWeight: 600,
  },
  s13w700: {
    fontSize: "0.8125rem",
    fontWeight: 700,
  },
  s13w800: {
    fontSize: "0.8125rem",
    fontWeight: 800,
  },

  // 14px
  s14w400: {
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  s14w500: {
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  s14w600: {
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  s14w700: {
    fontSize: "0.875rem",
    fontWeight: 700,
  },
  s14w800: {
    fontSize: "0.875rem",
    fontWeight: 800,
  },

  // 15px
  s15w400: {
    fontSize: "0.9375rem",
    fontWeight: 400,
  },
  s15w500: {
    fontSize: "0.9375rem",
    fontWeight: 500,
  },
  s15w600: {
    fontSize: "0.9375rem",
    fontWeight: 600,
  },
  s15w700: {
    fontSize: "0.9375rem",
    fontWeight: 700,
  },
  s15w800: {
    fontSize: "0.9375rem",
    fontWeight: 800,
  },

  // 16px
  s16w400: {
    fontSize: "1rem",
    fontWeight: 400,
  },
  s16w500: {
    fontSize: "1rem",
    fontWeight: 500,
  },
  s16w600: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  s16w700: {
    fontSize: "1rem",
    fontWeight: 700,
  },
  s16w800: {
    fontSize: "1rem",
    fontWeight: 800,
  },



  // 20px
  s20w400: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
  s20w500: {
    fontSize: "1.25rem",
    fontWeight: 500,
  },
  s20w600: {
    fontSize: "1.25rem",
    fontWeight: 600,
  },
  s20w700: {
    fontSize: "1.25rem",
    fontWeight: 700,
  },
  s20w800: {
    fontSize: "1.25rem",
    fontWeight: 800,
  },

  // 24px
  s24w400: {
    fontSize: "1.5rem",
    fontWeight: 400,
  },
  s24w500: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  s24w600: {
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  s24w700: {
    fontSize: "1.5rem",
    fontWeight: 700,
  },
  s24w800: {
    fontSize: "1.5rem",
    fontWeight: 800,
  },

  // 28px
  s28w400: {
    fontSize: "1.75rem",
    fontWeight: 400,
  },
  s28w500: {
    fontSize: "1.75rem",
    fontWeight: 500,
  },
  s28w600: {
    fontSize: "1.75rem",
    fontWeight: 600,
  },
  s28w700: {
    fontSize: "1.75rem",
    fontWeight: 700,
  },
  s28w800: {
    fontSize: "1.75rem",
    fontWeight: 800,
  },
});

export default Typography;
