import { useLocation, useNavigate, useParams } from "react-router";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { Add, ArrowRight2 } from "iconsax-react";

export enum ShipmentCategoriesEnum {
  ALL = "All Shipments",
  CREATING = "Draft Shipments",
  REQUESTING = "Quoting Shipments",
  TRACKING = "In Progress Shipments",
  COMPLETED = "Completed Shipments",
}

const categories = [
  { id: 1, name: ShipmentCategoriesEnum.ALL, path: "" },
  { id: 2, name: ShipmentCategoriesEnum.CREATING, path: "creating" },
  { id: 3, name: ShipmentCategoriesEnum.REQUESTING, path: "requesting" },
  { id: 4, name: ShipmentCategoriesEnum.TRACKING, path: "tracking" },
  { id: 5, name: ShipmentCategoriesEnum.COMPLETED, path: "completed" },
];

function ShipmentCategories() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const { workspaceID } = useParams();

  function isCategoryActive(categoryPath: string) {
    if (categoryPath) {
      if (location.pathname.endsWith(`/inquiries/${categoryPath}`)) {
        return location.pathname.endsWith(`/inquiries/${categoryPath}`);
      }
    } else {
      return location.pathname.endsWith(`/inquiries`);
    }
  }

  return (
    <Stack height={"100%"} p={3}>
      <Button
        variant="contained10"
        sx={{ borderRadius: "1.5rem", p: "12px" }}
        onClick={() => {
          navigate(`/workspace/${workspaceID}/inquiries/create`);
        }}
      >
        <Add />
        New Shipment
      </Button>
      <Typography
        variant="s12w600"
        pl={"0.75rem"}
        mb={"0.75rem"}
        mt={5}
        marginBottom={"12px"}
      >
        Categories
      </Typography>
      {categories.map((cat) => (
        <Box
          key={cat.id}
          onClick={() => {
            navigate(
              `/workspace/${workspaceID}/inquiries${
                cat?.path ? "/" + cat.path : ""
              }`
            );
          }}
          display={"flex"}
          p={"12px"}
          gap={"10px"}
          borderRadius={"24px"}
          sx={{
            cursor: "pointer",
            background: isCategoryActive(cat.path)
              ? theme.gradient.primary.light
              : "unset",
          }}
        >
          <Box
            minWidth={"36px"}
            height={"36px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={isCategoryActive(cat.path) ? "primary.25" : "white"}
            borderRadius={"50%"}
          >
            <ArrowRight2
              size={12}
              color={
                isCategoryActive(cat.path)
                  ? theme.palette.primary.main
                  : theme.palette["dim-grey"]
              }
            />
          </Box>
          <Typography variant="s12w500" lineHeight={"18px"} width={"4.3rem"}>
            {cat.name}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
}

export default ShipmentCategories;
