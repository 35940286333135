import { Button, Link, Stack, Typography, useTheme } from "@mui/material";
import HomePageButton from "components/Buttons/HomePageButton";
import AppDialog from "components/Dialog/AppDialog";
import AppDialogContent from "components/Dialog/AppDialogContent";
import AppDialogTitle from "components/Dialog/AppDialogTitle";
import WorkspacesIcon from "components/Icons/WorkspacesIcon";
import { Box } from "iconsax-react";

const PanelDisabledDialog = ({open}: {open: boolean}) => {
  const theme = useTheme();

  return (
    <AppDialog
      open={open}
      onClose={() => {}}
      paperMaxWidth="30rem"
    >
      <AppDialogTitle
        hideCloseButton
        sx={{ mx: "auto", paddingTop: 7, paddingBottom: 0 }} onClose={ () => {} }>
        <Stack direction="row" gap={1} alignItems="center">
          <WorkspacesIcon
            color={theme.palette.error.main}
            style={{ margin: 0 }}
          />
          <Typography variant="s13w600">No Workspace</Typography>
        </Stack>
      </AppDialogTitle>
      <AppDialogContent sx={{ paddingBottom: 7 }}>
        <Stack alignItems="center">
          <Typography
            variant="s13w400"
            sx={{ marginTop: 5, textAlign: "center", marginBottom: 7 }}
          >
            You are not a member of any workspace, the reason for this could be
            your suspension. You must be a member of at least one workspace to
            use Logimate.
          </Typography>

          <HomePageButton />
        </Stack>
      </AppDialogContent>
    </AppDialog>
  );
};

export default PanelDisabledDialog;
