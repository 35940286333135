function twoUserIcon(isActive = false) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49935 1.66699C5.31602 1.66699 3.54102 3.44199 3.54102 5.62533C3.54102 7.76699 5.21602 9.50033 7.39935 9.57533C7.46602 9.56699 7.53268 9.56699 7.58268 9.57533C7.59935 9.57533 7.60768 9.57533 7.62435 9.57533C7.63268 9.57533 7.63268 9.57533 7.64102 9.57533C9.77435 9.50033 11.4493 7.76699 11.4577 5.62533C11.4577 3.44199 9.68268 1.66699 7.49935 1.66699Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        opacity="0.4"
        d="M11.7328 11.7914C9.40781 10.2414 5.61615 10.2414 3.27448 11.7914C2.21615 12.4997 1.63281 13.4581 1.63281 14.4831C1.63281 15.5081 2.21615 16.4581 3.26615 17.1581C4.43281 17.9414 5.96615 18.3331 7.49948 18.3331C9.03281 18.3331 10.5661 17.9414 11.7328 17.1581C12.7828 16.4497 13.3661 15.4997 13.3661 14.4664C13.3578 13.4414 12.7828 12.4914 11.7328 11.7914Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M16.6578 6.11708C16.7911 7.73374 15.6411 9.15041 14.0495 9.34208C14.0411 9.34208 14.0411 9.34208 14.0328 9.34208H14.0078C13.9578 9.34208 13.9078 9.34207 13.8661 9.35874C13.0578 9.40041 12.3161 9.14207 11.7578 8.66707C12.6161 7.90041 13.1078 6.75041 13.0078 5.50041C12.9495 4.82541 12.7161 4.20874 12.3661 3.68374C12.6828 3.52541 13.0495 3.42541 13.4245 3.39208C15.0578 3.25041 16.5161 4.46708 16.6578 6.11708Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        opacity="0.4"
        d="M18.3249 13.8247C18.2582 14.633 17.7415 15.333 16.8749 15.808C16.0415 16.2663 14.9915 16.483 13.9499 16.458C14.5499 15.9163 14.8999 15.2413 14.9665 14.5247C15.0499 13.4913 14.5582 12.4997 13.5749 11.708C13.0165 11.2663 12.3665 10.9163 11.6582 10.658C13.4999 10.1247 15.8165 10.483 17.2415 11.633C18.0082 12.2497 18.3999 13.0247 18.3249 13.8247Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
    </svg>
  );
}

export default twoUserIcon;
