// material-ui
import { Theme } from "@mui/material/styles";

const CustomGradients = (theme: Theme) => ({
  primary: {
    main: `linear-gradient(90deg, ${theme.palette?.primary?.main} 0%, ${theme.palette?.secondary?.main} 100%)`,
    light: `linear-gradient(90deg, #303FC740 0%, #00F4B840 100%)`,
  },
});

export default CustomGradients;