import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import {
  archiveQuote,
  changeQuoteStatus,
  getCarrierList,
  getForwarderQuoteDetails,
  getQuote,
  getQuoteAttachments,
  getQuoteConversions,
  getQuoteList,
  getQuoteTotalUnreadMessages,
  seenQuoteConversation,
  getQuoteAISuggestions,
  quoteSendMessage,
  updateForwarderQuote,
  updateShipmentSailingInfo as updateSailingInfo,
  acceptQuoteAISuggestions,
  declineQuoteAISuggestions,
  editQuoteAISuggestions,
} from "./shipment.api";
import {
  AISuggestionField,
  ChangeQuoteStatus,
  QuoteCreate,
  SailingInfoCreate,
  TotalUnseenResponse,
} from "./shipment.type";
import { useHasPermissions } from "api/workspace";
import { WorkspacePermissions } from "api/types";

export const useCarrierList = (config?: AxiosRequestConfig) => {
  return useQuery(["carriers", config?.params], () => getCarrierList(config));
};

//#region Quote
export const useQueryQuote = (
  workspaceID: string | undefined,
  slug: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );
  return useQuery(
    ["quote", workspaceID, slug],
    () => getQuote(workspaceID, slug, hasPermission, config),
    {
      enabled: !!workspaceID && !!slug && !loadingPermission,
      keepPreviousData: true,
    }
  );
};
export const useQueryForwarderQuote = (
  workspaceID: string | undefined,
  slug: string | undefined,
  config?: AxiosRequestConfig
) => {
  return useQuery(
    ["forwarder-quote", workspaceID, slug],
    () => getForwarderQuoteDetails(workspaceID, slug, config),
    {
      enabled: !!workspaceID && !!slug,
    }
  );
};
export const useMutationUpdateForwarderQuote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      quoteSlug: string | undefined;
      data: QuoteCreate;
      config?: AxiosRequestConfig;
    }) =>
      updateForwarderQuote(
        data.workspaceID,
        data.quoteSlug,
        data.data,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries([
        "forwarder-quote",
        variable.workspaceID,
        variable.quoteSlug,
      ]);
      queryClient.invalidateQueries([
        "forwarder-quote",
        variable.workspaceID,
        variable.quoteSlug,
      ]);
    },
  });
};
export const useQueryQuoteConversions = (
  workspaceID: string | undefined,
  quoteSlug: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );
  return useQuery(
    ["quote-conversions", workspaceID, quoteSlug, config],
    () => getQuoteConversions(workspaceID, quoteSlug, hasPermission, config),
    {
      enabled: !!workspaceID && !!quoteSlug && !loadingPermission,
      keepPreviousData: true,
    }
  );
};

export const useQueryQuoteTotalUnreadMessages = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  const previousData = queryClient.getQueryData([
    "unread-messages",
    workspaceID,
    config,
  ]) as TotalUnseenResponse;
  return useQuery(
    ["unread-messages", workspaceID, config],
    () => getQuoteTotalUnreadMessages(workspaceID, hasPermission, config),
    {
      enabled: !!workspaceID && !loadingPermission,
      refetchInterval: 5000,
      onSuccess: (data) => {
        // Compare the previous and current data
        // if (previousData.total !== data.total) {
        //   queryClient.invalidateQueries(["quotes", workspaceID]);
        // }
      },
    }
  );
};

export const useMutationQuoteSeenConversation = (
  workspaceID: string | undefined
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      slug: string | undefined;
      config?: AxiosRequestConfig;
    }) =>
      seenQuoteConversation(workspaceID, data.slug, hasPermission, data.config),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["unread-messages", workspaceID]);
      queryClient.invalidateQueries(["quotes", workspaceID]);
    },
  });
};

export const useQueryQuoteAttachments = (
  workspaceID: string | undefined,
  slug: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );

  return useQuery(
    ["quote-documents", workspaceID, slug, config],
    () => getQuoteAttachments(workspaceID, slug, hasPermission, config),
    {
      enabled: !!workspaceID && !loadingPermission,
    }
  );
};

export const useQueryInfiniteQuoteList = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );

  return useInfiniteQuery(
    ["quotes", workspaceID, config],
    ({ pageParam = 0 }) =>
      getQuoteList(workspaceID, hasPermission, {
        ...config,
        params: { ...config?.params, offset: pageParam },
      }),
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.results.length < 15) {
          return null; // No more data
        }
        return allPages.length * 15; // Increment the offset
      },
      enabled: !!workspaceID && !loadingPermission,
      refetchInterval: 5000,
    }
  );
};

export const useQueryQuoteList = (
  workspaceID: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );

  return useQuery(
    ["quotes", workspaceID, config],
    () => getQuoteList(workspaceID, hasPermission, config),
    {
      enabled: !!workspaceID && !loadingPermission,
      keepPreviousData: true,
    }
  );
};

export const useMutationQuoteSendMessage = (
  workspaceID: string | undefined
) => {
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteQuote],
    workspaceID,
    true
  );
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      quoteSlug: string | undefined;
      data: { html_message: string };
      config?: AxiosRequestConfig;
    }) =>
      quoteSendMessage(
        workspaceID,
        data.quoteSlug,
        data.data,
        hasPermission,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries([
        "quote-conversions",
        workspaceID,
        variable.quoteSlug,
      ]);
      queryClient.invalidateQueries(["quotes", workspaceID]);
    },
  });
};
export const useMutationChangeQuoteStatus = (
  workspaceID: string | undefined
) => {
  const queryClient = useQueryClient();
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteQuote],
    workspaceID,
    true
  );
  return useMutation({
    mutationFn: (data: {
      workspaceID: string | undefined;
      quoteSlug: string | undefined;
      data: ChangeQuoteStatus;
      config?: AxiosRequestConfig;
    }) =>
      changeQuoteStatus(
        workspaceID,
        data.quoteSlug,
        hasPermission,
        data.data,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["quotes", workspaceID]);
      queryClient.setQueryData(
        ["quote", workspaceID, variable.quoteSlug],
        data
      );
    },
  });
};
export const useMutationArchiveQuote = (workspaceID: string | undefined) => {
  const queryClient = useQueryClient();
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteQuote],
    workspaceID,
    true
  );
  return useMutation({
    mutationFn: (data: { quoteSlug: string; config?: AxiosRequestConfig }) =>
      archiveQuote(data.quoteSlug, workspaceID, hasPermission, data.config),
    onSuccess: (data, variable, context) => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["quotes", workspaceID]);
    },
  });
};

export const useMutationUpdateSailingInfo = (
  workspaceID: string | undefined,
  slug: string | undefined
) => {
  const queryClient = useQueryClient();
  const { hasPermission, isLoading } = useHasPermissions(
    [WorkspacePermissions.ReadWriteShipment],
    workspaceID,
    true
  );
  return useMutation({
    mutationFn: (data: SailingInfoCreate) =>
      updateSailingInfo(workspaceID, slug, data, hasPermission),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["quotes", workspaceID]);
      queryClient.setQueryData(["quote", workspaceID, slug], data);
    },
  });
};

export const useQueryQuoteAISuggestions = (
  workspaceID: string | undefined,
  slug: string | undefined,
  config?: AxiosRequestConfig
) => {
  const { hasPermission, isLoading: loadingPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID,
    true
  );
  return useQuery(
    ["ai-suggestions", workspaceID, slug],
    () => getQuoteAISuggestions(workspaceID, slug, hasPermission, config),
    {
      enabled: !!workspaceID && !!slug && !loadingPermission,
      keepPreviousData: true,
    }
  );
};

export const useMutationAcceptQuoteAISuggestions = (
  workspaceID: string | undefined
) => {
  const queryClient = useQueryClient();
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteQuote],
    workspaceID,
    true
  );
  return useMutation({
    mutationFn: (data: {
      quoteSlug: string | undefined;
      suggestionId: number | undefined;
      config?: AxiosRequestConfig;
    }) =>
      acceptQuoteAISuggestions(
        workspaceID,
        data.quoteSlug,
        data.suggestionId,
        hasPermission,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      queryClient.invalidateQueries([
        "ai-suggestions",
        workspaceID,
        variable.quoteSlug,
      ]);
      queryClient.invalidateQueries(["quote", workspaceID, variable.quoteSlug]);
    },
  });
};

export const useMutationDeclineQuoteAISuggestions = (
  workspaceID: string | undefined
) => {
  const queryClient = useQueryClient();
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteQuote],
    workspaceID,
    true
  );
  return useMutation({
    mutationFn: (data: {
      quoteSlug: string | undefined;
      suggestionId: number | undefined;
      config?: AxiosRequestConfig;
    }) =>
      declineQuoteAISuggestions(
        workspaceID,
        data.quoteSlug,
        data.suggestionId,
        hasPermission,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      queryClient.invalidateQueries([
        "ai-suggestions",
        workspaceID,
        variable.quoteSlug,
      ]);
    },
  });
};

export const useMutationEditQuoteAISuggestions = (
  workspaceID: string | undefined
) => {
  const queryClient = useQueryClient();
  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadWriteQuote],
    workspaceID,
    true
  );
  return useMutation({
    mutationFn: (data: {
      quoteSlug: string | undefined;
      suggestionId: number | undefined;
      data: { [key: string]: string };
      config?: AxiosRequestConfig;
    }) =>
      editQuoteAISuggestions(
        workspaceID,
        data.quoteSlug,
        data.suggestionId,
        data.data,
        hasPermission,
        data.config
      ),
    onSuccess: (data, variable, context) => {
      queryClient.invalidateQueries([
        "ai-suggestions",
        workspaceID,
        variable.quoteSlug,
      ]);
      queryClient.invalidateQueries(["quote", workspaceID, variable.quoteSlug]);
    },
  });
};

