import { Theme } from "@mui/material";

export default function Popover(theme: Theme) {
    return {
        MuiPopover: {
            styleOverrides: {
                paper: {
                    
                    '&.MuiPopover-paper': {
                        backgroundColor: "white",
                        boxShadow: theme.customShadows.menu,
                        borderRadius: theme.customRadius,
                    }
                }
            }
        }
    }
};
