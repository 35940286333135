import { Theme } from "@mui/material";

export default function PaginationItem(theme: Theme) {

    return {
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    borderRadius: theme.customRadius,
                    fontSize: theme.typography.s13w600.fontSize,
                    fontWeight: theme.typography.s13w600.fontWeight,
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.primary[10],
                        color: theme.palette.primary.main,
                    }
                }
            },
            variants: [
                {
                    props: {selected: true},
                    style: {
                        backgroundColor: theme.palette.primary[25]
                    }
                }
            ]
        }
    }

}
