// material-ui
import { createTheme } from "@mui/material/styles";

// third-party
import { presetPalettes } from "@ant-design/colors";

// project import
import ThemeOption from "./theme";
import { PaletteMode } from "@mui/material";
import { paletteColors } from "./types";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode: PaletteMode) => {
  const colors = presetPalettes;
  const paletteColor: paletteColors = ThemeOption(colors);

  return createTheme({
    palette: {
      mode,
      common: {
        black: "#000",
        white: "#fff",
      },
      primary: paletteColor["p-blue"],
      secondary: paletteColor["aqua"],
      warning: paletteColor.orange,
      error: paletteColor.bittersweet,
      ...paletteColor,
      text: {
        primary: paletteColor["space-cadet"],
        secondary: paletteColor["dim-grey"],
        disabled: "#AAACB4",
      },
      // action: {
      // disabled: paletteColor.grey[300]
      // },
      // divider: paletteColor.grey[200],
      // background: {
      // paper: paletteColor.grey[0],
      // default: paletteColor.grey.A50
      // },
    },
  });
};

export default Palette;
