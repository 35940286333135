import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "components/Icons/CloseIcon";

function RoleDetailsPopover({
  role,
  title,
  description,
  handleClose,
}: {
  role: { name: string; accessLevelDisplayName: string }[] | null;
  title?: string;
  description?: string;
  handleClose?: any;
}) {
  return (
    <Box
      pt={2}
      px={3}
      pb={title ? 3 : 2}
      maxHeight={title || description ? "29rem" : "25rem"}
      maxWidth={"17.5rem"}
      overflow={"auto"}
    >
      {(title || handleClose) && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={"0.25rem"}
        >
          {title && (
            <Typography variant="s13w600" color={"space-cadet"}>
              {title}
            </Typography>
          )}
          {handleClose && (
            <IconButton
              disableRipple
              onClick={handleClose}
              sx={{ width: "1rem" }}
            >
              <CloseIcon
                sx={{
                  width: "1rem",
                  color: (theme) => theme.palette["cool-grey"],
                }}
              />
            </IconButton>
          )}
        </Box>
      )}

      {description && (
        <Typography variant="s12w400" color={"space-cadet"}>
          {description}
        </Typography>
      )}

      <Stack spacing={2} mt={title ? 3 : "0.25rem"} minWidth={"12rem"}>
        {role?.map((item) => (
          <Box display={"flex"} justifyContent={"space-between"}>
            <Box>
              <Typography variant="s12w500" color={"dim-grey"}>
                {item.name}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="s13w500"
                color={"space-cadet"}
                textAlign={"right"}
              >
                {item.accessLevelDisplayName}
              </Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
}

export default RoleDetailsPopover;
