function SuccessTickIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.17578 6.00012L4.72278 8.54712L9.82578 3.45312"
        stroke="#00F4B8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.17578 6.00012L4.72278 8.54712L9.82578 3.45312"
        stroke="black"
        strokeOpacity="0.1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessTickIcon;