import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import WorkspaceNavigator from "routes/WorkspaceNavigator";

// render - login
const AuthLogin = Loadable(lazy(() => import("pages/authentication/Login")));
const AuthRegister = Loadable(
  lazy(() => import("pages/authentication/Register"))
);
const JoinWorkspace = Loadable(
  lazy(() => import("pages/authentication/JoinWorkspace"))
);
const RegisterJoinWorkspace = Loadable(
  lazy(() => import("pages/authentication/RegisterJoinWorkspace"))
);

const ForwarderQuote = Loadable(
  lazy(() => import("pages/forwarder/ForwarderQuote/index"))
);
const MainForwarder = Loadable(
  lazy(() => import("pages/forwarder/MainForwarder"))
);
const ForwarderInquiry = Loadable(
  lazy(() => import("pages/forwarder/ForwarderInquiry"))
);
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "register",
      element: <AuthRegister />,
    },
    {
      index: true,
      element: <WorkspaceNavigator />,
    },
    {
      path: "workspace/join-user",
      element: <JoinWorkspace />,
    },
    {
      path: "403",
      element: <h1>You don't has permission</h1>,
    },
    {
      path: "workspace/join-new-user",
      element: <RegisterJoinWorkspace />,
    },
    {
      path: "forwarder/:workspaceID/:quoteSlug",
      element: <MainForwarder />,
      children: [
        {
          path: "inquiry",
          element: <ForwarderInquiry />,
        },
        {
          path: "quote",
          element: <ForwarderQuote />,
        },
      ],
    },
  ],
};

export default LoginRoutes;
