import AnalyticsIcon from "assets/image-components/drawer/analytics";
import ChatsIcon from "assets/image-components/drawer/chats";

const analyze = {
  id: "analyze",
  title: "Analyze",
  type: "group",
  children: [
    {
      id: "chats",
      title: "Chats",
      type: "item",
      url: "chats",
      icon: () => ChatsIcon(false),
      activeIcon: () => ChatsIcon(true),
      breadcrumbs: false,
    },
    {
      id: "analytics",
      title: "Analytics",
      type: "item",
      url: "analytics",
      icon: () => AnalyticsIcon(false),
      activeIcon: () => AnalyticsIcon(true),
      breadcrumbs: false,
    },
  ],
};

export default analyze;
