import { HasPermission, useHasPermissions } from "api/workspace";
import React, { ReactNode } from "react";
import { useParams } from "react-router";

type Props = {
  requiredPermissions: HasPermission[];
  showError?: boolean;
  children?: ReactNode;
};

const PermissionCheck = ({
  showError = false,
  requiredPermissions,
  children,
}: Props) => {
  const { workspaceID } = useParams();
  const { hasPermission, isLoading } = useHasPermissions(
    requiredPermissions,
    workspaceID
  );
  if (isLoading) {
    return <>loading</>;
  }
  if (hasPermission) return <>{children}</>;

  if (showError) {
    return <>You don't have permission to view this section</>;
  }
  return <></>;
};

export default PermissionCheck;
