import { FormControl, FormHelperText } from "@mui/material";
import { DEFAULT_ROLES, Role, useRoles } from "api/workspace";
import FormikCheckBox from "components/FormFields/FormikCheckBox";
import { useField } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import PointedSelect from "../Select/PointedSelect/PointedSelect";
import { UpdateMemberRoleData } from "components/Forms/UpdateMemberRole";
import RoleDetailsPopover from "pages/workspace/Roles/RoleDetailsPopover";
import {
  getDefaultRolesPermissionRenderData,
  getPermissionRenderData,
} from "pages/workspace/helpers";
import AppPopover from "components/Popover/AppPopover";

function MemberRoleEditField({
  key,
  handleSubmit,
  values,
  disabled,
  loading,
  roleLabel,
}: {
  key: number;
  handleSubmit: any;
  values: UpdateMemberRoleData;
  disabled?: boolean;
  loading?: boolean;
  roleLabel?: string;
}) {
  const { workspaceID } = useParams();
  const { data: roles } = useRoles(workspaceID);

  const [selectRoleAnchorEl, setSelectRoleAnchorEl] =
    useState<HTMLElement | null>(null);
  const selectRolePopoverOpen = Boolean(selectRoleAnchorEl);

  const [roleDetailsAnchorEl, setRoleDetailsAnchorEl] =
    useState<HTMLElement | null>(null);
  const roleDetailsPopoverOpen = Boolean(roleDetailsAnchorEl);
  const [showInfoRoleId, setShowInfoRoleId] = useState<string | null>(null);

  const roleInfoData =
    getDefaultRolesPermissionRenderData(
      DEFAULT_ROLES?.find((i) => i.KEY === showInfoRoleId)?.KEY
    ) ??
    getPermissionRenderData(
      roles?.find((i) => i.id.toString() === showInfoRoleId)
    ) ??
    null;

  const [roleField, roleMeta, roleHelpers] = useField("role");
  const [isOwnerField, isOwnerMeta, isOwnerHelpers] = useField("is_owner");
  const [isAdminField, isAdminMeta, isAdminHelpers] = useField("is_admin");
  const [isTeamMemberField, isTeamMemberMeta, isTeamMemberHelpers] =
    useField("is_team_member");
  const [roleValue, setRoleValue] = useState<string>(roleField.value ?? "");
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  useEffect(() => {
    if (!firstLoad || !isAdminField || !isTeamMemberField || !roleField) {
      return;
    }
    let is_owner = isOwnerField.value;
    let is_admin = isAdminField.value;
    let is_member = isTeamMemberField.value;
    let val = roleField.value;
    if (is_owner) {
      val = "OWNER";
    } else if (is_admin) {
      val = "ADMIN";
    } else if (is_member) {
      val = "TEAM_MEMBER";
    }
    if (!val) {
      val = "PERSONAL";
    }
    setRoleValue(val.toString());
    setFirstLoad(false);
  }, [values, firstLoad]);

  const handleChangeRole = (role: string) => {
    if (!role) {
      return;
    }
    let is_owner = false;
    let is_admin = false;
    let is_team_member = false;
    if (role === "OWNER") {
      is_owner = true;
    } else if (role === "ADMIN") {
      is_admin = true;
    } else if (role === "TEAM_MEMBER") {
      is_team_member = true;
    } else if (role === "PERSONAL") {
      roleHelpers.setValue(null);
    } else {
      roleHelpers.setValue(role);
    }
    isOwnerHelpers.setValue(is_owner);
    isAdminHelpers.setValue(is_admin);
    isTeamMemberHelpers.setValue(is_team_member);
    setRoleValue(role);
    roleHelpers.setValue(role);
    handleSubmit({
      ...values,
      is_owner: is_owner,
      is_admin: is_admin,
      is_team_member: is_team_member,
      role: role,
    });
  };

  return (
    <>
      <FormControl
        error={roleMeta.touched && !!roleMeta.error}
        sx={{ minWidth: "10rem" }}
      >
        {roleDetailsPopoverOpen && showInfoRoleId && (
          <AppPopover
            open={roleDetailsPopoverOpen}
            handleClose={() => {
              setRoleDetailsAnchorEl(null);
              setShowInfoRoleId(null);
            }}
            setAnchorEl={setRoleDetailsAnchorEl}
            anchorEl={selectRoleAnchorEl}
            popoverProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              sx: {
                mt: "-0.5rem",
                ml: "-0.5rem",
              },
            }}
          >
            <RoleDetailsPopover
              role={roleInfoData}
              title={
                DEFAULT_ROLES?.find((i) => i.KEY === showInfoRoleId)?.name ??
                roles?.find((i) => i.id.toString() === showInfoRoleId)?.name
              }
              description={
                DEFAULT_ROLES?.find((i) => i.KEY === showInfoRoleId)
                  ?.description
              }
              handleClose={() => {
                setRoleDetailsAnchorEl(null);
                setShowInfoRoleId(null);
              }}
            />
          </AppPopover>
        )}
        <PointedSelect
          popoverPosition="left"
          disabled={disabled}
          loading={loading}
          key={key}
          anchorEl={selectRoleAnchorEl}
          setAnchorEl={setSelectRoleAnchorEl}
          open={selectRolePopoverOpen}
          items={[
            ...DEFAULT_ROLES.filter((role) => role.isSelectable).map(
              (role) => ({
                key: role.KEY,
                label: role.name,
              })
            ),
            ...(roles?.map((role: Role) => ({
              key: role.id.toString(),
              label: role.name,
              isSelectable: true,
            })) ?? []),
          ]}
          selectedItem={{
            key: roleValue,
          }}
          activeInfoItem={showInfoRoleId}
          selectedLabel={
            DEFAULT_ROLES?.find((r) => r.KEY.toString() === roleValue)?.name ??
            roles?.find((r) => r.id.toString() === roleValue)?.name ??
            roleLabel ??
            null
          }
          hasInfo
          onClickInfo={(anchor: HTMLElement | null, id: string) => {
            setRoleDetailsAnchorEl(anchor);
            setShowInfoRoleId(id);
          }}
          handleClick={(e: React.MouseEvent<HTMLElement>) => {
            setSelectRoleAnchorEl(e.currentTarget);
          }}
          handleClose={() => {
            setSelectRoleAnchorEl(null);
            setShowInfoRoleId(null);
          }}
          handleSelect={(role: { key: string | number; label: string }) => {
            handleChangeRole(role.key.toString());
            setSelectRoleAnchorEl(null);
          }}
        />
        {roleMeta.touched && roleMeta.error && (
          <FormHelperText>{roleMeta.error}</FormHelperText>
        )}
      </FormControl>
      <FormikCheckBox name="is_admin" sx={{ display: "none" }} />
      <FormikCheckBox name="is_owner" sx={{ display: "none" }} />
      <FormikCheckBox name="is_team_member" sx={{ display: "none" }} />
    </>
  );
}

export default MemberRoleEditField;
