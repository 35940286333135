import axios from "axios";
import { store } from "store/index";
import { refreshToken, logout } from "store/reducers/auth";
import { router } from "App";

export const baseUrl =
  process.env.REACT_APP_BASE_URL ?? "http://127.0.0.1:8000/api";
const axiosClient = axios.create({
  baseURL: baseUrl,
  validateStatus: function (status) {
    return status >= 200 && status < 400; // default
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const state = store.getState();
    if (state.auth.isLogin) {
      // @ts-ignore
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${state.auth.access}`,
      };
    }
    return config;
  },
  function (error) {
    // Do something with request error
    throw error;
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const res = error.response;
    const state = store.getState();

    if (res) {
      if (res.status === 401 && state.auth.isLogin) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const res = await axios.post(baseUrl + "/auth/refresh/", {
              refresh: state.auth.refresh,
            });
            originalRequest.headers[
              "Authorization"
            ] = `Bearer ${res.data.access}`;
            store.dispatch(refreshToken(res.data));
            return axiosClient(originalRequest);
          } catch (e) {
            store.dispatch(logout());
            throw error;
          }
        } else {
          store.dispatch(logout());
        }
      }
    }
    throw error;
  }
);

export default axiosClient;
