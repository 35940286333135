// ==============================|| OVERRIDES - INPUT LABEL ||============================== //

import { Theme } from "@mui/material/styles";

export default function InputLabel(theme: Theme) {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
          fontSize: "14px",
          fontWeight: theme.typography.fontWeightMedium,
        },
        outlined: {
          top: "-6px",
          lineHeight: "1.5",
          "&.MuiInputLabel-sizeSmall": {
            lineHeight: "1.5",
          },
          "&.MuiInputLabel-shrink": {
            background: theme.palette.background.paper,
            padding: "0 8px",
            marginLeft: -6,
            lineHeight: "1.4375em",
          },
        },
      },
    },
  };
}
