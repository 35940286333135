import {
  DialogProps,
  DialogTitle,
  DialogTitleProps,
  IconButton,
  Typography,
} from "@mui/material";
import { AppDialogSharedProps } from "./dialog.type";
import CloseIcon from "components/Icons/CloseIcon";

type propType = {
  title?: string;
  hideCloseButton?: boolean;
} & DialogTitleProps &
  AppDialogSharedProps;

const AppDialogTitle = ({
  title,
  hideCloseButton: removeCloseButton,
  onClose,
  ...props
}: propType) => {
  return (
    <DialogTitle
      {...props}
      sx={{
        p: "2.25rem 2.75rem 2rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "sticky",
        top: 0,
        background: "white",
        zIndex: 100,
        ...props.sx,
      }}
    >
      {title ? (
        <Typography variant="h5">{title}</Typography>
      ) : (
        <>{props.children}</>
      )}
      {!removeCloseButton && (
        <IconButton
          disableRipple
          onClick={(e: any) => onClose(e, "closeButtonClick")}
          edge="start"
          sx={{ marginLeft: "auto" }}
        >
          <CloseIcon sx={{ color: (theme) => theme.palette["cool-grey"] }} />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default AppDialogTitle;
