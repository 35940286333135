import React from "react";
import { useMatches } from "react-router-dom";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function CustomBreadcrumbs() {
  let matches = useMatches();
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    .map((match) => match.handle.crumb(match.params, match.pathname));

  return (
    <Box
      flexWrap={"nowrap"}
      flexGrow={1}
      flexShrink={0}
      display={crumbs.length > 0 ? "unset" : "none"}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          mb: 2,
        }}
      >
        {crumbs.map((crumb, index) => (
          <Box key={index}>{crumb}</Box>
        ))}
      </Breadcrumbs>
    </Box>
  );
}
export default CustomBreadcrumbs;
