import BuildingIcon from "assets/image-components/drawer/building";
import UserIcon from "assets/image-components/drawer/user";
import twoUserIcon from "assets/image-components/drawer/twoUser";

const email = {
  id: "setup",
  title: "Contacts",
  type: "group",
  children: [
    {
      id: "Companies",
      title: "Companies",
      type: "item",
      url: "companies",
      icon: () => BuildingIcon(false),
      activeIcon: () => BuildingIcon(true),
      breadcrumbs: false,
    },
    {
      id: "Contacts",
      title: "Contacts",
      type: "item",
      url: "contacts",
      icon: () => UserIcon(false),
      activeIcon: () => UserIcon(true),
      breadcrumbs: false,
    },
    {
      id: "Groups",
      title: "Groups",
      type: "item",
      url: "groups",
      icon: () => twoUserIcon(false),
      activeIcon: () => twoUserIcon(true),
      breadcrumbs: false,
    },
    // {
    //   id: "Documents",
    //   title: "Documents",
    //   type: "item",
    //   url: "documents",
    //   icon: () => FolderIcon(false),
    //   activeIcon: () => FolderIcon(true),
    //   breadcrumbs: false,
    // },
  ],
};

export default email;
