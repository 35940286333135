import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
export const baseUrl =
  process.env.REACT_APP_BASE_URL ?? "http://127.0.0.1:8000/api";

// initial state
const initialState = {
  isLogin: false,
  access: "",
  refresh: "",
  loading: false,
};

const auth = createSlice({
  name: "auth ",
  initialState,
  reducers: {
    logout(state) {
      state.isLogin = false;
      state.access = "";
      state.refresh = "";
    },
    refreshToken(state, action) {
      state.access = action.payload.access;
    },
    forceLogin(state, action) {
      state.isLogin = true;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(LoginUserThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(LoginUserThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.isLogin = true;
      state.access = action.payload.access;
      state.refresh = action.payload.refresh;
    });
    builder.addCase(LoginUserThunk.rejected, (state) => {
      state.loading = false;
      state.isLogin = false;
      state.access = "";
      state.refresh = "";
    });
  },
});

export default auth.reducer;

export const { logout, refreshToken, forceLogin } = auth.actions;

export const LoginUserThunk = createAsyncThunk(
  "authentication/login",
  async (inputData: { email: string; password: string }) => {
    try {
      const res = await axios.post(
        `${baseUrl}/auth/login/`,
        { email: inputData.email, password: inputData.password },
        {
          headers: {},
        }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  }
);
