function PointIcon() {
  return (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle id="Ellipse 70" cx="3" cy="3" r="3" fill="#303FC7" />
    </svg>
  );
}

export default PointIcon;
