function CheckedIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="6" fill="#303FC7" />
      <path
        d="M8.81719 12.9837C8.65052 12.9837 8.49219 12.917 8.37552 12.8003L6.01719 10.442C5.77552 10.2003 5.77552 9.80033 6.01719 9.55866C6.25885 9.31699 6.65885 9.31699 6.90052 9.55866L8.81719 11.4753L13.1005 7.19199C13.3422 6.95033 13.7422 6.95033 13.9839 7.19199C14.2255 7.43366 14.2255 7.83366 13.9839 8.07533L9.25885 12.8003C9.14219 12.917 8.98385 12.9837 8.81719 12.9837Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
      />
    </svg>
  );
}

export default CheckedIcon;
