import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project import
import Logo from "./Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ sx, place }) => (
  <ButtonBase disableRipple component={Link} to={"/"} sx={sx}>
    <Logo place={place} />
  </ButtonBase>
);

LogoSection.propTypes = {
  sx: PropTypes.object,
  place: PropTypes.string,
};

export default LogoSection;
