import { Box, MenuItem, Select, SelectProps, useTheme } from "@mui/material";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import { useState } from "react";

const SelectIconComponent = (open: boolean) => (
  <Box
    sx={{
      margin: 0,
      marginLeft: -2,
      marginRight: 2,
      lineHeight: 0,
      padding: 0,
    }}
  >
    {open ? <ArrowUp2 size={10} /> : <ArrowDown2 size={10} />}
  </Box>
);

const AppSelect = <T,>(props: SelectProps<T>) => {
  const theme = useTheme();
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <Select<T>
      IconComponent={() => SelectIconComponent(popoverOpen)}
      {...props}
      MenuProps={{
        PaperProps: {
          sx: {
            bgcolor: "white",
            boxShadow: theme.customShadows.menu,
            marginY: 1,
          },
        },
        ...props.MenuProps,
      }}
      sx={{
        "& .MuiInputBase-inputAdornedStart": {
          pl: 0,
        },
        ...props.sx,
      }}
      inputProps={{
        sx: { opacity: !props.value ? 0.36 : 1 },
      }}
      onOpen={() => setPopoverOpen(true)}
      onClose={() => setPopoverOpen(false)}
      value={(!props.value ? "--" : props.value) as any}
    >
      <MenuItem
        value={"--"}
        sx={{ display: props.value || popoverOpen ? "none" : "block" }}
      >
        {props.placeholder || "Select"}
      </MenuItem>

      {props.children}
    </Select>
  );
};

export default AppSelect;
