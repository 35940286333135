import { AlertColor, SnackbarOrigin } from "@mui/material";
import { ServerError } from "api/types";
import { AxiosError } from "axios";
import Snackbar, { SnackbarProps } from "components/Snackbar/Snackbar";
import { Server } from "http";
import { isArray } from "lodash";
import { any } from "prop-types";
import { createContext, useCallback, useState } from "react";

type alertConfig = Pick<SnackbarProps, "severity"> & {
  message: string | AxiosError;
};

export const AlertContext = createContext<{
  showAlert: (alertConfig: alertConfig) => void;
}>({
  showAlert: (alertConfig: alertConfig) => {},
});

export const AlertProvider = (props: any) => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | undefined>();

  const getMessageFromAxiosError = useCallback(
    (message: AxiosError<any>) => {
      let data = message.response?.data;
      
      if (data.detail && typeof data.detail === "string") {
        setAlertMessage(data.detail);
      } else if (data.detail && data.detail instanceof Array) {
        setAlertMessage(data.detail?.[0]);
      } else if (
        data.non_field_errors &&
        typeof data.non_field_errors === "string"
      ) {
        setAlertMessage(data.non_field_errors);
      } else if (isArray(data.non_field_errors)) {
        setAlertMessage(data.non_field_errors?.[0]);
      } else if (message?.response?.status === 400) {
        setAlertMessage("Check your inputs");
      } else {
        setAlertMessage("Can not process request");
      }
    },
    [setAlertMessage]
  );

  const showAlert = useCallback(
    ({ message, severity }: alertConfig) => {
      if (typeof message === "string") {
        setAlertMessage(message);
      } else if (message instanceof AxiosError) {
        getMessageFromAxiosError(message);
      }

      setOpen(true);
      setAlertSeverity(severity);
    },
    [getMessageFromAxiosError]
  );

  return (
    <AlertContext.Provider
      value={{
        showAlert,
      }}
    >
      {props?.children}
      <Snackbar
        open={open}
        message={alertMessage}
        severity={alertSeverity}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      />
    </AlertContext.Provider>
  );
};
