import { useNavigate, useParams } from "react-router";
import AppTabs from "components/Tabs/AppTabs";
import { useHasPermissions } from "api/workspace";
import { WorkspacePermissions } from "api/types";
import Roles from "./Roles/Roles";

function ManageRoles() {
  const navigate = useNavigate();

  const { workspaceID } = useParams();
  const { hasPermission: hasRolesPermission } = useHasPermissions(
    [WorkspacePermissions.ReadRole],
    workspaceID
  );
  const { hasPermission: hasMembersPermission } = useHasPermissions(
    [WorkspacePermissions.ReadMembers],
    workspaceID
  );

  const tabs = [
    ...(hasMembersPermission
      ? [{ label: "Members", value: 0, disabled: !hasMembersPermission }]
      : []),
    { label: "Roles", value: 1, disabled: !hasRolesPermission },
  ];

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/workspace/${workspaceID}/manage-members`);
  };

  const renderTabs = () => (
    <AppTabs
      tabs={tabs}
      value={1}
      handleChange={handleChangeTab}
      sx={{
        // tab
        "& .MuiTab-root": {
          p: "1.1rem 2.5rem",
        },
      }}
    />
  );

  if (hasRolesPermission) {
    return <Roles renderTabs={renderTabs} />;
  }
  return <>You don't have permission to view this section</>;
}

export default ManageRoles;
