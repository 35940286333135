import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import ChatPreview from "components/PageComponents/Chats/ChatPreview";
import SearchInput from "components/SearchInput";
import { useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { debounce } from "lodash";
import {
  useMutationQuoteSeenConversation,
  useQueryInfiniteQuoteList,
} from "api/shipment/shipments.hooks";
import { QuoteResponse } from "api/shipment/shipment.type";
import { useDispatch, useSelector } from "react-redux";
import { getQuote } from "api/shipment";
import { WorkspacePermissions } from "api/types";
import { useHasPermissions } from "api/workspace";
import { expandChatList, setActiveChat } from "store/reducers/sidebar";

function ChatList() {
  const navigate = useNavigate();
  const itemsScrollRef = useRef<HTMLDivElement | null>(null);
  const { workspaceID } = useParams();

  const { activeChat, isChatListExpanded } = useSelector(
    (state: any) => state.sidebar
  );
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [appliedSearchQuery, setAppliedSearchQuery] = useState<string>("");

  const { mutate: seenConversation } =
    useMutationQuoteSeenConversation(workspaceID);

  const { hasPermission } = useHasPermissions(
    [WorkspacePermissions.ReadQuote],
    workspaceID
  );

  const { data, fetchNextPage, hasNextPage, isFetching } =
    useQueryInfiniteQuoteList(workspaceID, {
      params: {
        search: appliedSearchQuery,
      },
    });

  const chats = data?.pages.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.results);
  }, [] as QuoteResponse[]);

  const handleScroll = () => {
    if (!hasNextPage || isFetching) {
      return;
    }
    const box = itemsScrollRef.current;
    if (box && box.clientHeight + box.scrollTop + 1.5 >= box.scrollHeight) {
      fetchNextPage();
    }
  };

  const applySearchQuery = useMemo(
    () => debounce((search: string) => setAppliedSearchQuery(search), 500),
    []
  );

  const handleClickChat = (slug: string) => {
    dispatch(setActiveChat(slug));
    dispatch(expandChatList(false));
    getQuote(workspaceID, slug, hasPermission)
      .then((res) => {
        if (res?.total_unseen_messages && res.total_unseen_messages > 0) {
          seenConversation({
            slug: slug,
          });
        }
      })
      .catch((e) => {});
    navigate(`/workspace/${workspaceID}/chats/${slug}`);
  };

  return (
    <Stack height={"100%"} pl={"0.75rem"}>
      <Box px={1} pr={3} py={"1.25rem"}>
        <SearchInput
          placeholder={
            isChatListExpanded ? "Search Forwarder or Slug" : "Search"
          }
          value={searchQuery}
          handleChange={(e) => {
            setSearchQuery(e.target.value);
            applySearchQuery(e.target.value);
          }}
          fullWidth
          customSx={{
            fontSize: "12px",
            fontWeight: 400,
            borderRadius: "24px",
            backgroundColor: "white",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
      </Box>
      <Typography variant="s12w600" pl={"0.75rem"} mb={"0.75rem"}>
        Chats
      </Typography>
      <Box>
        {(chats && chats.length > 0) || isFetching ? (
          <Stack
            overflow={"auto"}
            height={"calc(100vh - 12rem)"}
            ref={itemsScrollRef}
            onScroll={handleScroll}
          >
            {chats?.map((chat: QuoteResponse, index) => (
              <ChatPreview
                id={chat.slug}
                isExpanded={isChatListExpanded}
                avatar=""
                title={
                  chat.forwarder_details?.full_name ??
                  chat.group_details?.company_details.name ??
                  ""
                }
                time={
                  chat.last_message?.sent_at ? chat.last_message.sent_at : null
                }
                originCountryCode={
                  chat.inquiry.origin_details?.country?.code ?? null
                }
                destinationCountryCode={
                  chat.inquiry.destination_details?.country?.code ?? null
                }
                rfqDate={chat.rfq_date}
                unseenNumber={chat.total_unseen_messages}
                senderName={
                  chat.last_message?.is_sender_user
                    ? "You"
                    : chat.last_message?.sender_full_name ??
                      chat.last_message?.sender_email ??
                      ""
                }
                subtitle={chat.slug}
                handleClick={handleClickChat}
                isActive={activeChat === chat.slug}
              />
            ))}
            {isFetching && (
              <Box textAlign={"center"} mt={3} mb={2}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Stack>
        ) : (
          <Typography color={"space-cadet"} p={2} textAlign={"center"}>
            No chat found.
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

export default ChatList;
