import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { UserInfoResponse } from "api/auth";
import { WorkspaceResponse, useQueryWorkspaces } from "api/workspace";
import ArrowLeft from "components/Icons/ArrowLeft";
import ProfileDivider from "./ProfileDivider";
import { profilePopoverProps } from "./profile.type";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { isActiveConfirmedMemeberShip } from "api/workspace/utils";

type propType = profilePopoverProps & {
  user: UserInfoResponse | undefined;
  handleBack: () => void;
  setHeight: React.Dispatch<React.SetStateAction<string | number>>;
};

type workspaceProps = Pick<propType, "handleClose"> & {
  workspace: WorkspaceResponse | undefined;
};

const WorkspaceItem = ({ workspace, handleClose }: workspaceProps) => {
  const isActive = isActiveConfirmedMemeberShip(workspace?.membership_details);
  const opacity = isActive ? 1 : 0.38;

  return (
    <ListItem
      onClick={handleClose}
      button
      disabled={!isActive}
      component={Link}
      to={`/workspace/${workspace?.id}`}
      sx={{ marginY: 1, "&.Mui-disabled": { opacity: 1 } }}
    >
      <ListItemIcon sx={{ opacity }}>
        <Avatar
          sx={{
            width: 40,
            height: 40,
            bgcolor: (theme) => theme.palette.primary[10],
            color: (theme) => theme.palette.primary.main,
          }}
        >
          <Typography variant="s13w600" color={isActive ? "primary" : "black"}>
            {workspace?.name.substring(0, 1).toUpperCase()}
          </Typography>
        </Avatar>
      </ListItemIcon>

      <ListItemText sx={{ marginX: 2 }}>
        <Stack>
          <Typography variant="s13w600" sx={{ opacity }}>
            {workspace?.name}
          </Typography>

          {isActive ? (
            <Typography variant="s13w400" color="dim-grey" noWrap>
              {workspace?.website?.replace(/(https:\/\/|http:\/\/)/, "")}
            </Typography>
          ) : (
            <Typography variant="s13w400" color="error" noWrap>
              You have been suspended
            </Typography>
          )}
        </Stack>
      </ListItemText>
    </ListItem>
  );
};

const WorkspaceSwitch = ({
  user,
  handleClose,
  handleBack,
  setHeight,
}: propType) => {
  const { data: workspaces, isFetching } = useQueryWorkspaces();

  const lref = useRef<any>(null);
  useEffect(() => {
    if (isFetching) return;
    setHeight(lref.current?.offsetHeight || "auto");
  }, [lref, isFetching]);

  return (
    <List ref={lref}>
      <ListItem
        button
        onClick={(e) => {
          e.preventDefault();
          handleBack();
        }}
        sx={{ marginBottom: 1 }}
      >
        <ListItemIcon>
          <ArrowLeft />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="s13w500">Switch Workspace</Typography>
        </ListItemText>
      </ListItem>
      <ProfileDivider />
      <List sx={{ maxHeight: "20rem", overflowY: "auto" }}>
        {workspaces?.map((e) => (
          <WorkspaceItem key={e.id} handleClose={handleClose} workspace={e} />
        ))}
      </List>
    </List>
  );
};

export default WorkspaceSwitch;
