import React from "react";
import { useField } from "formik";
import AppTextField, { AppTextFieldProps } from "./AppTextField";

type propType = { readOnly?: boolean; topLabel?: string; name: string } & Omit<
  AppTextFieldProps,
  "name"
>;

const FormikTextField = ({
  name,
  label,
  topLabel,
  readOnly,
  placeholder,
  absoluteHelper,
  fullWidth,
  ...props
}: propType) => {
  const [field, meta, helpers] = useField(name);

  return (
    <AppTextField
      {...field}
      name={name}
      value={field.value ?? ""}
      error={meta.touched && meta.error !== undefined}
      helperText={meta.touched ? meta.error : ""}
      inputProps={{
        readOnly: readOnly,
        disableUnderline: readOnly,
      }}
      label={topLabel ?? label}
      placeholder={placeholder}
      fullWidth={fullWidth}
      {...props}
    />
  );
};

export default FormikTextField;
