import {Typography} from "@mui/material";

type propType = {
    children: string;
};

const TableHeaderLabel = ({ children }: propType) => {
    return (
        <Typography variant="s12w500" sx={{paddingY: 0}}>
            {children}
        </Typography>
    )
}

export default TableHeaderLabel;
