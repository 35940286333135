function AnalyticsIcon(isActive = true) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M17.5 1.66699V11.7503C17.5 13.3337 16.6667 14.167 15.0833 14.167H4.91666C3.33333 14.167 2.5 13.3337 2.5 11.7503V1.66699H17.5Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M18.3327 2.29199H1.66602C1.32435 2.29199 1.04102 2.00866 1.04102 1.66699C1.04102 1.32533 1.32435 1.04199 1.66602 1.04199H18.3327C18.6743 1.04199 18.9577 1.32533 18.9577 1.66699C18.9577 2.00866 18.6743 2.29199 18.3327 2.29199Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M13.8909 18.617C13.7826 18.8337 13.5576 18.9587 13.3326 18.9587C13.2409 18.9587 13.1409 18.9337 13.0576 18.892L9.99924 17.367L6.9409 18.892C6.85757 18.9337 6.75758 18.9587 6.66591 18.9587C6.44091 18.9587 6.2159 18.8337 6.10757 18.617C5.94923 18.3003 6.07426 17.9253 6.39092 17.7753L9.37424 16.2837V14.167H10.6242V16.2837L13.6076 17.7753C13.9242 17.9253 14.0493 18.3003 13.8909 18.617Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M6.2501 9.79209C6.0751 9.79209 5.89178 9.7171 5.76678 9.5671C5.54178 9.30044 5.58343 8.90877 5.85009 8.68377L8.47511 6.4921C8.71678 6.2921 9.02509 6.20877 9.31676 6.25877C9.61676 6.30877 9.87511 6.49208 10.0334 6.75875L10.9084 8.21708L13.3501 6.18377C13.6168 5.95877 14.0084 6.00042 14.2334 6.26708C14.4584 6.53375 14.4168 6.92542 14.1501 7.15042L11.5251 9.34209C11.2834 9.54209 10.9751 9.62542 10.6834 9.57542C10.3834 9.52542 10.1251 9.34211 9.96676 9.07544L9.09177 7.61711L6.65011 9.65042C6.53344 9.74208 6.39177 9.79209 6.2501 9.79209Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
    </svg>
  );
}

export default AnalyticsIcon;
