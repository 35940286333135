import PropTypes from "prop-types";
import React, { useMemo } from "react";

// material-ui
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// project import
import Palette from "./palette";
import Typography from "./typography";
import CustomShadows from "./shadows";
import componentsOverride from "./overrides";
import CustomGradients from "./gradients";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    contained10: true;
    colorOutlined: true;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    customRadius?: string;
    gradient: {
      primary: {
        main: string;
        light: string;
      };
    };

    customShadows: {
      button: string;
      text: string;
      menu: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    customRadius?: string;
    gradient?: {
      primary: {
        main: string;
        light: string;
      };
    };
    customShadows?: {
      button?: string;
      text?: string;
      menu?: string;
    };
  }
}

export default function ThemeCustomization({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) {
  const theme = Palette("light");

  const themeTypography = Typography(`'Inter', sans-serif`);
  const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);
  const themeCustomGradients = useMemo(() => CustomGradients(theme), [theme]);

  const themeOptions = useMemo(
    () => ({
      customRadius: "0.75rem",
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },
      mixins: {
        toolbar: {
          minHeight: 60,
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
      palette: theme.palette,
      customShadows: themeCustomShadows,
      typography: themeTypography,
      gradient: themeCustomGradients,
    }),
    [theme, themeTypography, themeCustomShadows, themeCustomGradients]
  );

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

ThemeCustomization.propTypes = {
  children: PropTypes.node,
};
