// material-ui
import { Theme, alpha } from '@mui/material/styles';

// ==============================|| DEFAULT THEME - CUSTOM SHADOWS  ||============================== //

const CustomShadows = (theme: Theme) => ({
    button: `0 2px #0000000b`,
    text: `0 -1px 0 rgb(0 0 0 / 12%)`,
    menu: "0 5px 25px 0 rgba(45, 48, 68, 0.05)"
    // z1: `0px 2px 8px ${alpha(theme?.palette?.grey[900], 0.15)}`
});

export default CustomShadows;
