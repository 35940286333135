import { useEffect } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Toolbar } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import SecondSidebar from "components/SecondSidebar";
import ScrollTop from "components/ScrollTop";
import Drawer from "./Drawer";
import Header from "./Header";
import { heapIdentifyUser } from "utils/heap-utils";
import { useQueryUserInfo } from "api/auth";
import { drawerWidth } from "config";

const MainLayout = () => {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const location = useLocation();
  const pathname = location.pathname;
  const { workspaceID } = useParams();
  const queryClient = useQueryClient();
  const { data: userData } = useQueryUserInfo();

  const isSidebarOpen =
    pathname.endsWith(`workspace/${workspaceID}/inquiries`) ||
    pathname.endsWith(`workspace/${workspaceID}/inquiries/creating`) ||
    pathname.endsWith(`workspace/${workspaceID}/inquiries/requesting`) ||
    pathname.endsWith(`workspace/${workspaceID}/inquiries/tracking`) ||
    pathname.endsWith(`workspace/${workspaceID}/inquiries/completed`) ||
    pathname.includes(`workspace/${workspaceID}/chats`);

  useEffect(() => {
    if (isLogin && userData) {
      heapIdentifyUser(userData);
    }
  }, [isLogin, userData]);

  if (!isLogin) {
    queryClient.clear();
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return (
    <>
      <ScrollTop>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "right",
            minHeight: "100vh",
          }}
        >
          <Header />
          <Drawer />
          {isSidebarOpen && (
            <Box zIndex={2000} height={"100vh"}>
              <SecondSidebar />
            </Box>
          )}
          <Box
            component="main"
            position={"relative"}
            sx={{
              width: {
                xs: "100%",
                md: `calc(100vw - ${drawerWidth}px)`,
              },
              p: 3,
              pb: 1,
              px: 2,
              pl: "1.5rem",
            }}
          >
            <Toolbar />
            <Breadcrumbs />
            <Outlet />
          </Box>
        </Box>
      </ScrollTop>
    </>
  );
};

export default MainLayout;
