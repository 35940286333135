import * as React from "react";
import logoType from "assets/images/logimate-logo.svg";
import logoBlack from "assets/images/logimate-logo-black-type.svg";
import logo from "assets/images/logimate-favicon.svg";
function Logo({ place = "" }) {
  if (place === "auth") {
    return <img src={logoBlack} alt="" width={300} />;
  } else if (place === "drawer") {
    return <img src={logo} alt="" width={32} />;
  } else {
    return <img src={logoType} alt="" width={128} />;
  }
}

export default Logo;
