import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import AppPopover from "components/Popover/AppPopover";
import ArrowDown from "components/Icons/ArrowDown";
import ArrowUp from "components/Icons/ArrowUp";
import { useQueryUserInfo } from "api/auth";
import WorkspaceSwitch from "./WorkspaceSwitch";
import ProfileAvatar from "./ProfileAvatar";
import UserData from "./UserData";

const Profile = () => {
  const [anchorEl, setAnchorEl] = useState<null | any>(null);
  const [openWorkspaceSwitch, setOpenWorkspaceSwitch] =
    useState<boolean>(false);
  const { data: user } = useQueryUserInfo();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => setOpenWorkspaceSwitch(false), 500);
  };

  const [height, setHeight] = useState<string | number>("auto");

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={!open ? <ArrowDown size="10" /> : <ArrowUp size="10" />}
        sx={{ zIndex: 2000, backgroundColor: (theme) => theme.palette.white }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <ProfileAvatar size="sm" user={user} />
          <Typography
            noWrap={true}
            variant="s13w500"
            component="span"
            align="left"
            color="black"
            sx={{ minWidth: "7rem", maxWidth: "11rem" }}
          >
            {user?.first_name || user?.last_name
              ? `${user?.first_name} ${user?.last_name}`
              : "User"}
          </Typography>
          <Typography variant="s13w500"></Typography>
        </Stack>
      </Button>
      <AppPopover
        open={open}
        handleClose={handleClose}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        popoverProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          sx: { "& .MuiPaper-root": { minWidth: "20.75rem" }, marginTop: 1 },
        }}
      >
        <Box>
          <motion.div
            animate={{ height: height }}
            style={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <AnimatePresence initial={false}>
              {!openWorkspaceSwitch ? (
                <motion.div
                  key="user Data"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                  }}
                  initial={{ x: -350 }}
                  animate={{ x: 0 }}
                  exit={{ x: -350 }}
                >
                  <UserData
                    key="userData"
                    setHeight={setHeight}
                    setOpenWorkspaceSwitch={setOpenWorkspaceSwitch}
                    handleClose={handleClose}
                  />
                </motion.div>
              ) : (
                <motion.div
                  key="workspaceSwitch"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    overflow: "hidden",
                  }}
                  initial={{ x: 350 }}
                  animate={{ x: 0 }}
                  exit={{ x: 350 }}
                >
                  <WorkspaceSwitch
                    handleClose={handleClose}
                    handleBack={() => setOpenWorkspaceSwitch(false)}
                    user={user}
                    setHeight={setHeight}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </Box>
      </AppPopover>
    </Box>
  );
};

export default Profile;
