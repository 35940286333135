import { Backdrop, Popover, PopoverProps } from "@mui/material";
import { PopoverProvider } from "contexts/PopoverContext";

function AppPopover({
  open,
  setAnchorEl,
  anchorEl,
  popoverProps,
  children,
  renderAction,
  handleClose,
  backdropColor,
}: {
  open: boolean;
  setAnchorEl: (value: HTMLElement | null) => void;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  popoverProps?: Omit<PopoverProps, "onClose" | "open" | "anchorEl">;
  renderAction?: React.ReactNode;
  children?: React.ReactNode;
  backdropColor?: string;
}) {
  return (
    <Backdrop
      sx={{
        backgroundColor: (theme) =>
          `${backdropColor} !important` ?? theme.palette.black[10],
        zIndex: 1000,
      }}
      open={open}
      onClick={() => setAnchorEl(null)}
    >
      <Popover
        onClick={(e) => e.stopPropagation()}
        {...popoverProps}
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
      >
        <PopoverProvider handleClose={handleClose}>
          {children}
          {renderAction}
        </PopoverProvider>
      </Popover>
    </Backdrop>
  );
}

export default AppPopover;
