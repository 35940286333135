import { Box, Typography } from "@mui/material";

function UnreadNumberBadge({ number }: { number: number }) {
  return (
    <Box
      sx={{
        backgroundColor: "error.main",
        borderRadius: "50%",
        minWidth: "1rem",
        height: "1rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: "1px"
      }}
    >
      <Typography variant="s10w600" color={"#000"}>
        {number}
      </Typography>
    </Box>
  );
}

export default UnreadNumberBadge;
