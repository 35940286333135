// third-party
import { merge } from "lodash";
import { Theme } from "@mui/material/styles";

// project import
import Badge from "./Badge";
import Button from "./Button";
import CardContent from "./CardContent";
import Checkbox from "./Checkbox";
import Chip from "./Chip";
import IconButton from "./IconButton";
import InputLabel from "./InputLabel";
import LinearProgress from "./LinearProgress";
import Link from "./Link";
import ListItemIcon from "./ListItemIcon";
import OutlinedInput from "./OutlinedInput";
import Tab from "./Tab";
import TableCell from "./TableCell";
import Tabs from "./Tabs";
import Typography from "./Typography";
import Menu from "./Menu";
import MenuItem from "./MenuItem";
import PaginationItem from "./PaginationItem";
import Popover from "./Popover";
import ListItem from "./ListItem";
import FormControlLabel from "./FormControlLabel";
import Dialog from "./Dialog";
import ListItemButton from "./ListItemButton";
import TextField from "./TextField";

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme: Theme) {
  return merge(
    Button(theme),
    Badge(theme),
    CardContent(),
    Checkbox(theme),
    Chip(theme),
    Dialog(theme),
    IconButton(theme),
    InputLabel(theme),
    LinearProgress(),
    Link(),
    ListItem(theme),
    ListItemButton(theme),
    ListItemIcon(),
    Menu(theme),
    MenuItem(theme),
    OutlinedInput(theme),
    PaginationItem(theme),
    Popover(theme),
    Tab(theme),
    TableCell(theme),
    Tabs(),
    Typography(),
    TextField(theme),
    FormControlLabel()
  );
}
