import React, { ReactNode, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { Stack, SxProps, TableSortLabel, Typography } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { merge } from "lodash";
import { IPaginateResponse } from "api/types";
import SearchInput from "components/SearchInput";
import AppPagination from "./AppPagination";
import TableHeaderLabel from "./TableHeaderLabel";

export type TableFilters = {
  ordering: string;
  limit: number;
  offset: number;
  search: string;
  [x: string]: any;
};
export const defaultFilters = {
  ordering: "",
  limit: 10,
  offset: 0,
  search: "",
};
type TableProps<T> = {
  equalHeaders?: boolean;
  caption: string;
  allHeadCellProps?: TableCellProps;
  columns: {
    name: keyof T | string;
    render?: (row: T, index?: number) => ReactNode;
    label: string;
    sort?: boolean;
    bodyCellProps?: TableCellProps;
    headCellProps?: TableCellProps;
  }[];
  cta?: {
    label: string;
    onClick: () => void;
  };
  filters: TableFilters;
  changeFilters: React.Dispatch<React.SetStateAction<TableFilters>>;
  search?: boolean;
  searchBoxPlaceholder?: string;
  smallHeader?: boolean;
  renderAction?: (row: T) => React.ReactNode;
  sx?: SxProps;
  actions?: {
    icon?: React.ElementType;
    onClick: (row: T) => void;
    caption: string;
    sx?: SxProps;
    color?:
      | "disabled"
      | "action"
      | "inherit"
      | "primary"
      | "secondary"
      | "error"
      | "info"
      | "success"
      | "warning";
  }[];
  data: IPaginateResponse<T> | undefined;
};

function HookPaginatedTable<T>({
  caption,
  allHeadCellProps,
  columns,
  actions,
  smallHeader,
  cta,
  data,
  changeFilters,
  filters,
  searchBoxPlaceholder,
  search,
  renderAction,
  sx,
}: TableProps<T>) {
  const [searchFieldValue, setSearchFieldValue] = useState(filters.search);

  const handleChangeRowsPerPage = (newPageSize: number) => {
    changeFilters((old: TableFilters) => ({
      ...old,
      offset: 0,
      limit: newPageSize,
    }));
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    changeFilters((old: TableFilters) => ({
      ...old,
      offset: newPage * old.limit,
    }));
  };
  const handleSort = (name: string) => {
    let value = "";
    if (filters.ordering === name) {
      value = "-" + name;
    }
    if (filters.ordering === "") {
      value = name;
    }
    changeFilters((old: TableFilters) => ({
      ...old,
      ordering: value,
    }));
  };

  const handleSearchChange = (value: string) => {
    setSearchFieldValue(value);
    changeFilters((old) => ({
      ...old,
      ...(search && { search: value.replace("#", "") }),
    }));
  };

  return (
    <Box>
      <Stack
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{ mb: 3 }}
      >
        {search && (
          <SearchInput
            placeholder={searchBoxPlaceholder || "Search name"}
            value={searchFieldValue}
            handleChange={(e) => handleSearchChange(e.target.value)}
          />
        )}
        {cta ? (
          <Button
            color="primary"
            variant="contained"
            onClick={cta.onClick}
            sx={{ paddingX: 4, marginLeft: "auto", height: "2.5rem" }}
          >
            <Typography variant="s13w600">{cta.label}</Typography>
          </Button>
        ) : null}
      </Stack>
      <TableContainer
        sx={{
          borderRadius: "1.5rem",
          bgcolor: "primary.5",
          p: "0 0.5rem 0.5rem 0.5rem",
          ...sx,
        }}
      >
        <Table
          aria-label="caption table"
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0px",
            height: data?.results?.length ? "auto" : "100%",
          }}
        >
          <TableHead>
            <TableRow>
              {columns.map((col, index) => {
                const isActive =
                  filters.ordering === col.name ||
                  filters.ordering === `-${col.name.toString()}`;
                const direction =
                  filters.ordering === col.name ? "asc" : "desc";
                return (
                  <TableCell
                    {...col.headCellProps}
                    {...allHeadCellProps}
                    key={col.name.toString() + index}
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontSize: "0.75rem",
                      border: "none",
                      p: "1.25rem",
                      whiteSpace: "nowrap",
                      paddingY: 2,
                      ...(allHeadCellProps?.sx && col?.headCellProps?.sx
                        ? merge(allHeadCellProps.sx, col.headCellProps.sx)
                        : allHeadCellProps?.sx
                        ? allHeadCellProps.sx
                        : col.headCellProps?.sx),
                    }}
                  >
                    {col.sort ? (
                      <TableSortLabel
                        active={isActive}
                        direction={direction}
                        onClick={() => handleSort(col.name.toString())}
                      >
                        <TableHeaderLabel>{col.label}</TableHeaderLabel>
                        {isActive ? (
                          <Box component="span" sx={visuallyHidden}>
                            {direction === "asc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      <TableHeaderLabel>{col.label}</TableHeaderLabel>
                    )}
                  </TableCell>
                );
              })}
              {renderAction ? (
                <TableCell
                  key={"table-actions"}
                  sx={{
                    color: "dim-grey",
                    fontSize: "0.75rem",
                    border: "none",
                    p: "1.25rem",
                    paddingY: 2,
                  }}
                >
                  <TableHeaderLabel>Actions</TableHeaderLabel>
                </TableCell>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {!data ? (
              [0, 1, 2, 3, 4].map((item) => (
                <TableRow key={item}>
                  {columns.map((item) => (
                    <TableCell key={item.label} sx={{ textAlign: "center" }}>
                      <Skeleton variant="text" width={"100%"} height={30} />
                    </TableCell>
                  ))}
                  {renderAction ? (
                    <TableCell key={"actions"} sx={{ textAlign: "center" }}>
                      <Skeleton variant="text" width={40} height={30} />
                    </TableCell>
                  ) : null}
                </TableRow>
              ))
            ) : data.results?.length ? (
              data.results.map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  sx={{ bgcolor: "white", borderRadius: "1.25rem" }}
                >
                  {columns.map(
                    (col, colIndex) =>
                      col.render && (
                        <TableCell
                          {...col.bodyCellProps}
                          key={colIndex + col.name.toString() + rowIndex}
                          sx={{
                            ...col?.bodyCellProps?.sx,
                            borderTopLeftRadius:
                              rowIndex === 0 && colIndex === 0
                                ? "1.25rem"
                                : "0",
                            borderBottomLeftRadius:
                              rowIndex === data.results?.length - 1 &&
                              colIndex === 0
                                ? "1.25rem"
                                : "0",
                            p: "1.25rem",
                            borderTop:
                              rowIndex === 0 ? "none" : "solid 0.75px #EAEAEC",
                            borderBottom: "none",
                            fontWeight: colIndex === 1 ? "600" : "400",
                          }}
                        >
                          {col.render(row, rowIndex)}
                        </TableCell>
                      )
                  )}
                  {renderAction && (
                    <TableCell
                      key={"actions" + rowIndex}
                      sx={{
                        paddingLeft: 0,
                        width: "1%",
                        borderTopRightRadius: rowIndex === 0 ? "1.25rem" : "0",
                        borderBottomRightRadius:
                          rowIndex === data.results?.length - 1
                            ? "1.25rem"
                            : "0",
                        p: "1.25rem",
                        paddingY: 2,
                        borderTop:
                          rowIndex === 0 ? "none" : "solid 0.75px #EAEAEC",
                        borderBottom: "none",
                      }}
                    >
                      {renderAction(row)}
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns?.length + (renderAction ? 1 : 0)}
                  sx={{
                    bgcolor: "white",
                    borderRadius: "0.75rem",
                    textAlign: "center",
                    border: "0.75px solid #EAEAEC",
                    p: "1.25rem",
                  }}
                >
                  {"There is no entry to show."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {data && data.count > filters.limit && (
        <AppPagination
          rowsPerPageOptions={[10, 25, 100]}
          rowsPerPage={filters.limit}
          count={data?.count ?? 0}
          page={filters.offset / filters.limit}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
}

export default HookPaginatedTable;
