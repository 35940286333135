import moment from "moment";
import { UserInfoResponse } from "api/auth";
import { Currency } from "api/shipment/shipment.type";

export interface AddressType {
  latitude: number;
  longitude: number;
  type: PortType;
  name: string;
  number: null;
  postal_code: null;
  street: null;
  confidence: number;
  region: string;
  region_code: string;
  county: string;
  locality: string;
  country: string;
  country_code: string;
  continent: string;
  label: string;
}

type PortType =
  | null
  | "venue"
  | "address"
  | "street"
  | "neighbourhood"
  | "borough"
  | "localadmin"
  | "locality"
  | "county"
  | "macrocounty"
  | "region"
  | "macroregion"
  | "country"
  | "coarse"
  | "postalcode";

interface ICity {
  code: string;
  name: string;
  country: string;
}

interface ICountry {
  code: string;
  name: string;
}

export interface IPort {
  id: number;
  country: ICountry | null;
  name: string;
  name_wo_diacritics?: string;
  location?: string;
  city: ICity | null;
  status?: string;
  date?: string;
  latitude?: number | null;
  longitude?: number | null;
  function_raw?: string;
  see_port: boolean;
  rail_terminal: boolean;
  road_terminal: boolean;
  air_port: boolean;
  postal_exchange: boolean;
  multi_port: boolean;
  fixed_port: boolean;
  inland_port: boolean;
  border_crossing: boolean;
}

export interface ChoiceListResponse {
  key: string;
  value: string;
  description: string;
}

export interface HSCodeResponse {
  section: string;
  hs_code: string;
  description: string;
  parent: null | string;
  level: number | null;
}

export interface HsCodeSectionResponse {
  section_code: string;
  section_name: string;
  start_code: string;
  end_code: string;
}

export interface FeeCategory {
  id: number;
  title: string;
  parent: number | null;
  isSelectable: boolean;
}

export interface CityResponse {
  code: string;
  name: string;
  country: string;
}

export interface CountryResponse {
  code: string;
  name: string;
}

export interface PortResponse {
  id: number;
  country: CountryResponse | null;
  name: string;
  name_wo_diacritics?: string;
  location?: string;
  city: CityResponse | null;
  status?: string;
  date?: string;
  latitude?: number | null;
  longitude?: number | null;
  function_raw?: string;
  see_port: boolean;
  rail_terminal: boolean;
  road_terminal: boolean;
  air_port: boolean;
  postal_exchange: boolean;
  multi_port: boolean;
  fixed_port: boolean;
  inland_port: boolean;
  border_crossing: boolean;
}

export enum CargoType {
  Hazardous = "Hazardous",
  Perishable = "Perishable",
  Oversize = "Oversize",
  Liquid = "Liquid",
}

export enum ShippingCategory {
  sea = "sea",
  air = "air",
  land = "land",
  rail = "rail",
}

export interface InquiryUnitResponse {
  length: number;
  width: number;
  gross_weight: number;
  height: number;
  quantity: number;
}

export enum TemperatureUnit {
  FAHRENHEIT = "F",
  CELSIUS = "C",
}

export interface TransportationLCLData {
  units: InquiryUnitResponse[];
  weight: number | null;
  volume: number | null;
  by_unit?: boolean;
}

export interface InquirySeaTransportation extends TransportationLCLData {
  transportation_type: string;
  ship_type: string | null;
  container_type: number | null;
  quantity_of_container: number | null;
  gross_weight: number | null;
  loading_rate: number | null;
  discharging_rate: number | null;
}

export interface InquiryLandTransportation extends TransportationLCLData {
  transportation_type: string;
  byUnit?: boolean;
  truck_type: string;
  container_type: number | null;
  quantity_of_container: number | null;
  quantity_of_trucks: number | null;
  gross_weight: number | null;
  loading_rate: number | null;
  discharging_rate: number | null;
}

export interface RailTransportation {
  transportation_type: string;
  wagon_type: number;
  quantity_of_wagons: number | null;
}

export interface AirTransportation extends TransportationLCLData {
  transportation_type: string;
  container_type: number | null;
  quantity_of_container: number | null;
}

export enum TRANSPORTATION_TYPES {
  SEA_LCL = "REQ_1",
  SEA_FCL = "REQ_2",
  SEA_IN_BULK = "REQ_3",
  LAND_FCL = "REQ_32",
  LAND_LTL = "REQ_36",
  LAND_FTL = "REQ_4",
  RAIL_FWL = "REQ_6",
  AIR_ULD_CONTAINER = "REQ_37",
  AIR_STANDARD_CARGO = "REQ_7",
}

export const initialInquirySeaTransportationData: InquirySeaTransportation = {
  transportation_type: "",
  units: [],
  weight: null,
  volume: null,
  ship_type: "",
  container_type: null,
  gross_weight: null,
  loading_rate: 0,
  discharging_rate: 0,
  quantity_of_container: null,
};

export interface MinQuotePrice {
  currency: Currency;
  cost: number;
}

export enum InquiryStatus {
  CREATING = "C",
  REQUESTING = "RFQ",
  TRACKING = "TR",
  COMPLETED = "AR",
}
export const InquiryStatusText = {
  C: "Created Inquiry",
  RFQ: "Requesting Quote",
  TR: "Tracking Shipment",
  AR: "Shipment Completed",
};

export interface InquiryResponse {
  is_hazardous: boolean;
  is_perishable: boolean;
  is_oversize: boolean;
  is_liquid: boolean;
  status: InquiryStatus | null;
  updated_at: string | null;
  imo_class: string | null;
  un_number: number | null;
  shipment_type: string;
  temperature_regime: number | null;
  air_container_type: string;
  cargo_height: number | null;
  cargo_length: number | null;
  sea_transportation: InquirySeaTransportation[];
  land_transportation: InquiryLandTransportation[];
  rail_transportation: RailTransportation[];
  air_transportation: AirTransportation[];
  cargo_width: number | null;
  certification: boolean;
  container_type: string;
  created_at: string;
  customs_clearance: boolean;
  description: string;
  destination_address: string;
  destination_details: IPort | null;
  destination_is_door: boolean;
  destination: number | null;
  discharging_rate: number | null;
  gross_weight: number | null;
  id: number;
  slug: string;
  inspection_service: boolean;
  insurance: boolean;
  invoice_amount: number | null;
  is_quoted: boolean;
  loading_rate: number | null;
  origin_address: string;
  origin_details: IPort | null;
  origin_is_door: boolean;
  origin: number | null;
  product_details: HSCodeResponse | null;
  product: string;
  quantity_of_container: number | null;
  quote_received: number;
  ready_date: string;
  ship_type: string;
  temperature_unit: TemperatureUnit;
  total_quote: number;
  transportation_by: string;
  truck_container_type: string;
  incoterm: string;
  units: InquiryUnitResponse[];
  user_details: UserInfoResponse;
  volume: number | null;
  wagon_type: string;
  weight: number | null;
  workspace: string;
  transportation_by_details: {
    key: string;
    description: string;
    value: string;
  };
  sent_quotes: number;
  min_quote_price?: MinQuotePrice | null;
}

export const initialInquiryData: InquiryCreateData = {
  is_hazardous: false,
  is_oversize: false,
  is_perishable: false,
  is_liquid: false,
  status: null,
  updated_at: null,
  truck_container_type: "",
  sea_transportation: [
    {
      transportation_type: TRANSPORTATION_TYPES.SEA_LCL,
      ship_type: "",
      container_type: null,
      quantity_of_container: null,
      gross_weight: null,
      loading_rate: null,
      discharging_rate: null,
      units: [],
      volume: 0,
      weight: null,
    },
  ],
  land_transportation: [
    {
      transportation_type: TRANSPORTATION_TYPES.LAND_FCL,
      container_type: null,
      quantity_of_container: null,
      gross_weight: null,
      loading_rate: null,
      discharging_rate: null,
      truck_type: "",
      quantity_of_trucks: null,
      units: [],
      volume: 0,
      weight: null,
    },
  ],
  rail_transportation: [
    {
      transportation_type: TRANSPORTATION_TYPES.RAIL_FWL,
      wagon_type: 0,
      quantity_of_wagons: null,
    },
  ],
  air_transportation: [
    {
      transportation_type: TRANSPORTATION_TYPES.AIR_ULD_CONTAINER,
      container_type: null,
      quantity_of_container: null,
      units: [],
      volume: 0,
      weight: null,
    },
  ],
  temperature_regime: 0,
  temperature_unit: TemperatureUnit.CELSIUS,
  cargo_length: 0,
  cargo_width: 0,
  cargo_height: 0,
  quantity_of_container: 0,
  gross_weight: 0,
  loading_rate: 0,
  discharging_rate: 0,
  weight: null,
  air_container_type: "",
  volume: 0,
  origin_is_door: false,
  destination_is_door: false,
  origin_address: "",
  destination_address: "",
  ready_date: moment().format("YYYY-MM-DD"),
  description: "",
  insurance: false,
  inspection_service: false,
  certification: false,
  customs_clearance: false,
  invoice_amount: 0,
  product: "",
  imo_class: "",
  un_number: null,
  shipment_type: ShippingCategory.sea,
  container_type: "",
  incoterm: "",
  wagon_type: "",
  ship_type: "",
  origin: null,
  destination: null,
  transportation_by: "",
  units: [],
  sent_quotes: 0,
  min_quote_price: null,
};

export type InquiryCreateData = Omit<
  InquiryResponse,
  | "created_at"
  | "destination_details"
  | "id"
  | "slug"
  | "is_quoted"
  | "origin_details"
  | "product_details"
  | "total_quote"
  | "transportation_by_details"
  | "user_details"
  | "user"
  | "workspace"
  | "quote_received"
>;
export type InquiryListItemResponse = Required<
  Pick<
    InquiryResponse,
    | "id"
    | "status"
    | "updated_at"
    | "slug"
    | "sea_transportation"
    | "land_transportation"
    | "air_transportation"
    | "rail_transportation"
    | "origin_details"
    | "transportation_by_details"
    | "ready_date"
    | "shipment_type"
    | "created_at"
    | "destination_details"
    | "user_details"
    | "total_quote"
    | "quote_received"
  >
>;

export type InquiryChangeStatusRequest = Pick<InquiryResponse, "status">;

export interface TransportationText {
  full: string;
  abbreviation: string;
}

export const TRANSPORTATION_TYPE_TEXT: Record<
  TRANSPORTATION_TYPES,
  TransportationText
> = {
  REQ_1: { full: "LCL", abbreviation: "LCL" },
  REQ_2: { full: "FCL", abbreviation: "FCL" },
  REQ_3: { full: "In Bulk", abbreviation: "In Bulk" },
  REQ_32: { full: "FCL", abbreviation: "FCL" },
  REQ_36: { full: "LTL", abbreviation: "LTL" },
  REQ_4: { full: "FTL", abbreviation: "FTL" },
  REQ_6: { full: "FWL", abbreviation: "FWL" },
  REQ_37: { full: "ULD Container", abbreviation: "ULD" },
  REQ_7: { full: "Standrard Cargo", abbreviation: "Standrard" },
};

export interface SeaTransportation {
  transportation_type: string;
  units: InquiryUnitResponse[];
  byUnit?: boolean;
  weight: number | null;
  volume: number | null;
  container_type: number | null;
  quantity_of_container: number | null;
  ship_type: number | null;
  gross_weight: number | null;
  loading_rate: number | null;
  discharging_rate: number | null;
}

export const TRANSPORTATION_TYPE_CATEGORIES = {
  SeaTransportation: [
    { key: TRANSPORTATION_TYPES.SEA_LCL, value: "LCL", description: "LCL" },
    { key: TRANSPORTATION_TYPES.SEA_FCL, value: "FCL", description: "FCL" },
    {
      key: TRANSPORTATION_TYPES.SEA_IN_BULK,
      value: "In bulk",
      description: "in bulk",
    },
  ],
  LandTransportation: [
    { key: TRANSPORTATION_TYPES.LAND_FCL, value: "FCL", description: "FCL" },
    { key: TRANSPORTATION_TYPES.LAND_LTL, value: "LTL", description: "LTL" },
    { key: TRANSPORTATION_TYPES.LAND_FTL, value: "FTL", description: "FTL" },
  ],
  RailTransportation: [
    { key: TRANSPORTATION_TYPES.RAIL_FWL, value: "FWL", description: "FWL" },
  ],
  AirTransportation: [
    {
      key: TRANSPORTATION_TYPES.AIR_ULD_CONTAINER,
      value: "ULD container",
      description: "Air uld container",
    },
    {
      key: TRANSPORTATION_TYPES.AIR_STANDARD_CARGO,
      value: "Standard cargo",
      description: "Standard cargo",
    },
  ],
};
