import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { PermissionAccessLevelEnum } from "api/workspace";
import FormikSelectField from "components/FormFields/FormikSelectField";
import { useField } from "formik";

type propType = {
  fieldName: string;
  fieldLabel: string;
};

const RoleAccessLevelFieldInput = ({ fieldName, fieldLabel }: propType) => {
  const accessLevelField = `${fieldName}.accessLevel`;
  const [disabledField] = useField(`${fieldName}.disabled`);

  const selectItems = [
    { key: "", label: "None" },
    { key: PermissionAccessLevelEnum.Read, label: "Read" },
    { key: PermissionAccessLevelEnum.ReadWrite, label: "Read+Write" },
  ];

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        <Typography variant="s13w500" color={"space-cadet"} mr={2} >
          {fieldLabel}
        </Typography>
        <Stack
          direction="row"
          spacing={8}
          sx={{ minWidth: "9rem", maxWidth: "9rem" }}
        >
          <FormikSelectField
            disabled={disabledField.value}
            placeholder={"None"}
            name={accessLevelField}
            sx={{
              "& .Mui-disabled": {
                cursor: "not-allowed",
              },
            }}
          >
            {selectItems.map((item) => (
              <MenuItem key={item.key} value={item.key}>
                {item.label}
              </MenuItem>
            ))}
          </FormikSelectField>
        </Stack>
      </Box>
    </>
  );
};

export default RoleAccessLevelFieldInput;
