function UserIcon(isActive = false) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M15 15.7167H14.3667C13.7 15.7167 13.0667 15.975 12.6 16.4417L11.175 17.85C10.525 18.4917 9.46668 18.4917 8.81668 17.85L7.39166 16.4417C6.925 15.975 6.28333 15.7167 5.625 15.7167H5C3.61667 15.7167 2.5 14.6084 2.5 13.2417V4.14999C2.5 2.78333 3.61667 1.67502 5 1.67502H15C16.3833 1.67502 17.5 2.78333 17.5 4.14999V13.2417C17.5 14.6 16.3833 15.7167 15 15.7167Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M10.0003 8.67497C11.0726 8.67497 11.942 7.80569 11.942 6.73334C11.942 5.66098 11.0726 4.79166 10.0003 4.79166C8.92792 4.79166 8.05859 5.66098 8.05859 6.73334C8.05859 7.80569 8.92792 8.67497 10.0003 8.67497Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
      <path
        d="M12.2336 12.55C12.9086 12.55 13.3003 11.8 12.9253 11.2416C12.3586 10.4 11.2586 9.83331 10.0003 9.83331C8.74197 9.83331 7.64196 10.4 7.0753 11.2416C6.7003 11.8 7.09198 12.55 7.76698 12.55H12.2336Z"
        fill={isActive ? "#00F4B8" : "white"}
      />
    </svg>
  );
}

export default UserIcon;
