import { Outlet } from "react-router-dom";
import { Container } from "@mui/system";
// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
  <Container>
    <Outlet />
  </Container>
);

export default MinimalLayout;
