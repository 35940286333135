// project import
import React from "react";
import routes from "routes";
import ThemeCustomization from "themes";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //
import "./app.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar";
import { AlertProvider } from "contexts/AlertContext";
import "utils/yup"

import ScreenSizeCheck from "components/ScreenSizeCheck/ScreenSizeCheck";
export const router = createBrowserRouter(routes);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 15 * 1000,
    },
  },
});
const App = () => (
  <ThemeCustomization>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <AlertProvider>
          <ScreenSizeCheck>
            <RouterProvider router={router} />
          </ScreenSizeCheck>
        </AlertProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  </ThemeCustomization>
);

export default App;
