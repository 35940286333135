import { Box, Typography } from "@mui/material";
import React from "react";
import { useField } from "formik";
import AppCheckbox from "components/Checkbox/AppCheckbox";

type propType = {
    name: string;
    [key: string]: any
}

const FormikCheckBox = ({ name, ...props }: propType) => {
  const [field, meta, helpers] = useField(name);

  return (
    <Box mr={"0.325rem"}>
      <AppCheckbox {...field} checked={field.value} {...props} />
      {meta.error && meta.touched && (
        <Typography variant="body2" color="danger">
          {meta.error}
        </Typography>
      )}
    </Box>
  );
};

export default FormikCheckBox;
