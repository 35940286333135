import { UserDetails } from "api/workspace";
import { Avatar, Typography } from "@mui/material";
import { useMemo } from "react";

type propType = {
    user?: UserDetails,
    size: "sm" | "md" | "lg",
};

const ProfileAvatar = ({ user, size }: propType) => {

    const sizes = useMemo<{imageSize: number, variant: any}>(() => {
        let s = 0;
        let variant = "";
        switch (size) {
            case "sm":
                s = 24;
                variant = "s11w400";
                break;
            case "md":
                s = 40;
                variant = "s12w400";
                break;
                
            case "lg":
                s = 60;
                variant= "s28w400"
                break;
        }

        return {imageSize: s, variant}
    }, [size])

    const sx = { width: sizes.imageSize, height: sizes.imageSize };
    const avatarText = () => {
        let text = ((user?.first_name?.substring(0, 1).toUpperCase() ?? "") + (user?.last_name?.substring(0, 1)?.toUpperCase() ?? ""));
        return text ? text : "U";
    }



    return (
        <Avatar sx={{...sx, bgcolor: theme => theme.palette.primary.main}}>
            <Typography variant={sizes.variant}>
                {avatarText()}
            </Typography>
        </Avatar>
    )
}

export default ProfileAvatar;
